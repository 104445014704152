import React, { FC, useEffect } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { emptyService } from "../../../../data/mkt/service";
import { createService, setCreated } from "../../../../stores/mkt/Service.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { ServiceCreateEdit } from "../../../../components/mkt/Service/ServiceCreateEdit/ServiceCreateEdit";
import { serviceValidationSchema } from "../../../../utils/mkt/service";
import { useNavigate } from "react-router-dom";

export const ServiceCreatePage: FC = () => {
  const dispatch = useTypedDispatch();
  const { created } = useTypedSelector((state: RootState) => state.services);
  const navigate = useNavigate();

  useEffect(() => {
    if (created) {
      dispatch(setCreated(false));
      navigate("/services");
    }
  }, [created]);

  const createAction = async () => {
    formik.values.linkText = formik.values.url == "/blog" ? "Go to Blog" : "Go to Case Studies";
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return;
    }
    const service = {
      ...formik.values,
      title: formik.values.title.trim(),
      subTitle: formik.values.subTitle.trim(),
      description: formik.values.description.trim(),
    };
    dispatch(createService({ service: service }));
  };

  const formik = useFormik({
    initialValues: { ...emptyService, order: 100 },
    onSubmit: (userInputData) => {},
    validationSchema: serviceValidationSchema,
  });

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Create Service",
        previousPage: "/services",
        action: createAction,
        type: "Create",
        tabs: null,
        switchTabAction() {},
      }}
    >
      <ServiceCreateEdit formik={formik} />
    </PageCreate>
  );
};
