import React, { FC, useEffect } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { createTestimonial, setCreated } from "../../../../stores/mkt/Testimonial.store";
import { emptyTestimonial } from "../../../../data/mkt/testimonial";
import { testimonialValidationSchema } from "../../../../utils/mkt/testimonial";
import { TestimonialCreateEdit } from "../../../../components/mkt/Testimonial/TestimonialCreateEdit/TestimonialCreateEdit";

export const TestimonialCreatePage: FC = () => {
  const dispatch = useTypedDispatch();
  const { created } = useTypedSelector((state: RootState) => state.testimonials);
  const navigate = useNavigate();

  useEffect(() => {
    if (created) {
      dispatch(setCreated(false));
      navigate("/testimonials");
    }
  }, [created]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return;
    }
    const testimonial = {
      ...formik.values,
      text: formik.values.text.trim(),
      name: formik.values.name.trim(),
      position: formik.values.position.trim(),
    };
    dispatch(createTestimonial({ testimonial: testimonial }));
  };

  const formik = useFormik({
    initialValues: { ...emptyTestimonial, order: 100 },
    onSubmit: (userInputData) => {},
    validationSchema: testimonialValidationSchema,
  });

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Create Testimonial",
        previousPage: "/testimonials",
        action: createAction,
        type: "Create",
        tabs: null,
        switchTabAction() {},
      }}
    >
      <TestimonialCreateEdit formik={formik} />
    </PageCreate>
  );
};
