import React, { FC, useEffect, useState } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { editService, getService, setEdited } from "../../../../stores/mkt/Service.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { ServiceCreateEdit } from "../../../../components/mkt/Service/ServiceCreateEdit/ServiceCreateEdit";
import { serviceValidationSchema } from "../../../../utils/mkt/service";
import { useNavigate, useParams } from "react-router-dom";

export const ServiceEditPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { service, loading, error, created, edited, deleted } = useTypedSelector(
    (state: RootState) => state.services
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchService = async () => {
      await dispatch(getService({ id: id!! })).then(() => {
        setLoaded(true);
      });
    };
    fetchService();
  }, []);

  useEffect(() => {
    if (edited) {
      dispatch(setEdited(false));
      navigate(`/services`);
    }
  }, [edited]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return;
    }
    const service = {
      ...formik.values,
      title: formik.values.title.trim(),
      subTitle: formik.values.subTitle.trim(),
      description: formik.values.description.trim(),
    };
    dispatch(editService({ service: service }));
  };

  const formik = useFormik({
    initialValues: service,
    onSubmit: (userInputData) => {},
    validationSchema: serviceValidationSchema,
    enableReinitialize: true,
  });

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Edit Service",
        previousPage: `/services`,
        action: createAction,
        type: "Edit",
        tabs: null,
        switchTabAction() {},
      }}
    >
      {loaded ? <ServiceCreateEdit formik={formik} /> : null}
    </PageCreate>
  );
};
