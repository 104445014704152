import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_REQUEST_SETTINGS } from "../../../../constants/hr/apiRequestSettings.const";
import HREmployeeService from "../../../../services/hr/HREmployee.service";
import { HREmployee } from "../../../../types/hr/HREmployee";
import { PageSettings } from "../../../../types/hr/PageSettings";
import { isHttpCodeValid } from "../../../../utils/common/http";
import { dateToDDMMYYYY } from "../../../../utils/date";
import { LabelWithUpperTitle } from "../../../labels/LabelWithUpperTitle/LabelWithUpperTitle";
import { Loading } from "../../../Loading/loading";
import useBookSearch from "../../../useGetEmployeesHook";
import { EmployeAvatar } from "./EmployeAvatar/EmployeAvatar";

import "./EmployeDetails.css";

export const EmployeDetails: FC = () => {
  const [pageSettings, setPageSettings] = useState<PageSettings>({
    page: 0,
    size: API_REQUEST_SETTINGS.GET_EMPLOYEE_PAGE_SIZE,
  });
  const navigate = useNavigate();

  const { loading, employees, hasMore } = useBookSearch(pageSettings);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastEmployeeElementRef = useCallback(
    (element: any) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageSettings((prevSettings) => ({ ...prevSettings, page: prevSettings.page + 1 }));
        }
      });
      if (element) observer.current.observe(element);
    },
    [loading, hasMore]
  );

  const EmployeeCard = (employee: HREmployee) => (
    <div
      className="EmployeDetails-container"
      onClick={() => navigate(`/employees/details/${employee.email}`)}
    >
      <EmployeAvatar employee={employee} />
      <div className="EmployeDetails-box">
        <LabelWithUpperTitle
          title={"Birthday"}
          label={employee && dateToDDMMYYYY(new Date(employee.dob))}
          titleTextColor={"#FDFDFD"}
          labelTextColor={"#fff"}
          editEnable={false}
        />
        {employee.projects?.length > 0 && (
          <LabelWithUpperTitle
            title={"Project"}
            label={employee.projects[0].name}
            role={"(" + employee.projects[0].role + ")"}
            titleTextColor={"#FDFDFD"}
            labelTextColor={"#fff"}
            editEnable={false}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="EmplyeeDetails-employeesList">
      {employees.map((employee, index) =>
        employees.length === index + 1 ? (
          <div key={employee.id} ref={lastEmployeeElementRef}>
            <EmployeeCard {...employee} />
          </div>
        ) : (
          <EmployeeCard key={employee.id} {...employee} />
        )
      )}
      {loading && <Loading />}
    </div>
  );
};
