import React, { FC, useEffect, useState } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { caseStudyValidationSchema } from "../../../../utils/mkt/caseStudy";
import { editCaseStudy, getCaseStudy, setEdited } from "../../../../stores/mkt/CaseStudy.store";
import { CaseStudyCreateEdit } from "../../../../components/mkt/CaseStudy/CaseStudyCreateEdit/CaseStudyCreateEdit";

export const CaseStudyEditPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { caseStudy, loading, error, edited } = useTypedSelector(
    (state: RootState) => state.caseStudies
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    dispatch(getCaseStudy({ id: id!! }));
  }, []);

  useEffect(() => {}, [activeTab]);

  useEffect(() => {
    if (edited) {
      dispatch(setEdited(false));
      navigate(`/case_studies/${id}`);
    }
  }, [edited]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return;
    }
    const caseStudy = {
      ...formik.values,
      title: formik.values.title.trim(),
      subtitle: formik.values.subtitle.trim(),
      readTime: formik.values.readTime.trim(),
      challenge: {
        ...formik.values.challenge,
        title: formik.values.challenge.title.trim(),
        description: formik.values.challenge.description.trim(),
      },
      solution: {
        ...formik.values.solution,
        text: formik.values.solution.text.trim(),
        stack: formik.values.solution.stack.trim(),
      },
      results: formik.values.results.trim(),
    };
    dispatch(editCaseStudy({ caseStudy: caseStudy }));
  };

  const swithTabAction = (index: number) => {
    setActiveTab(index);
  };

  const formik = useFormik({
    initialValues: caseStudy,
    onSubmit: (userInputData) => {},
    validationSchema: caseStudyValidationSchema,
  });

  return (
    <div className="CaseStudyEditPage-pageEditDiv">
      <PageCreate
        pageContent={{
          pageTitle: `Edit Case Study`,
          previousPage: "/case_studies",
          action: createAction,
          type: "Edit",
          tabs: ["Summary", "The Challenge", "Kodly's Solution", "The Results"], //["Summary", "The Challenge", "Kodly's Solution", "The Results", "SEO"],
          switchTabAction: swithTabAction,
        }}
      >
        <CaseStudyCreateEdit formik={formik} activeTab={activeTab} />
      </PageCreate>
    </div>
  );
};
