import * as yup from "yup";

export const serviceValidationSchema = yup.object({
  title: yup
    .string()
    .strict()
    .required("Title is required")
    .matches(/^((?!\s\s+).)*$/, "Title has multiple white spaces")
    .max(100, "Title must be at most 100 characters"),
  subTitle: yup
    .string()
    .strict()
    .matches(/^((?!\s\s+).)*$/, "SubTitle has multiple white spaces")
    .nullable()
    .max(100, "SubTitle must be at most 100 characters"),
  description: yup.string().strict().required("Description is required"),
  url: yup
    .string()
    .strict()
    .oneOf(["/blog", "/case-studies"], "Destination must be 'Blog' or 'Case Study'")
    .required("Destination is required"),
  icon: yup.string().strict().trim().required("Icon is required"),
  image: yup.string().strict().trim().required("Image is required"),
});
