export const shapeToSingleArray = (list: any[][]) => {
  const aux = [];
  for (let i = 0; i < list[0].length; i++) {
    for (let j = 0; j < list.length; j++) {
      if (list[j][i]) {
        aux.push(list[j][i]);
      }
    }
  }
  return aux;
};

export const shapeToDnDArray = (list: any[], numberOfLists: number) => {
  let aux: any[][] = [];
  for (let index = 0; index < list.length; index++) {
    if (!aux[index % numberOfLists]) {
      aux[index % numberOfLists] = [];
    }
    aux[index % numberOfLists].push(list[index]);
  }
  return aux;
};

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const mergeArraysOnIndex = (
  primaryArray: any[],
  secondaryArray: any[],
  mergeIndex: number
) => {
  primaryArray.splice(mergeIndex, secondaryArray.length, ...secondaryArray);
  return primaryArray;
};
