import React, { FC, useState } from "react";
import { Box, Typography } from "@mui/material";
import "./VacationsCreate.css";
import { VacationsCreateForm } from "./VacationsCreateForm/VacationsCreateForm";
import { Button, ButtonTypes } from "../../../buttons/button/Button";
import { RootState, useTypedSelector } from "../../../../stores/store";
import {
  computeVacationDaysGroupedByCategory,
  SCHEDULED_DAYS_CATEGORIES,
} from "../../../../constants/internal/vacations.const";

interface VacationsCreateProps {
  formik: any;
  onCreateVacation: () => void;
  onCancel: () => void;
  overlappingVacationErrorShow: boolean;
}

export const VacationsCreate: FC<VacationsCreateProps> = ({
  formik,
  onCreateVacation,
  onCancel,
  overlappingVacationErrorShow,
}) => {
  const { user } = useTypedSelector((state: RootState) => state.user);
  const { vacationsBalance } = useTypedSelector((state: RootState) => state.vacation);
  const currentYear = new Date().getFullYear().toString();
  const maxVacationsErrorDefaultState = -1;
  const [maxVacationsError, setMaxVacationsError] = useState(maxVacationsErrorDefaultState);

  const onVacationsDaysSelectedChange = (selectedDays: number) => {
    if (vacationsBalance.remaining >= selectedDays) {
      setMaxVacationsError(maxVacationsErrorDefaultState);
    } else {
      setMaxVacationsError(vacationsBalance.remaining);
    }
  };

  const onCreateVacationPress = () => {
    if (maxVacationsError == -1) {
      onCreateVacation();
    }
  };

  return (
    <Box className="VacationsCreate-container">
      <Box className="VacationsCreate-submitContainer">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: "50px" }}
        >
          <Typography sx={{ fontWeight: 700 }}>Schedule Vacation</Typography>
          <div className="buttonsContainer">
            <Button text="Cancel" onClick={onCancel} type={ButtonTypes.Cancel} />
            <Button text="Confirm" onClick={onCreateVacationPress} type={ButtonTypes.Primary} />
          </div>
        </Box>
      </Box>
      <Box id="container" className="VacationsCreate-formContainer">
        <Box>
          <VacationsCreateForm
            formik={formik}
            pageTitle={"New Vacation"}
            onVacationsDaysSelectedChange={onVacationsDaysSelectedChange}
            maxVacationsAvailable={maxVacationsError}
            overlappingVacationErrorShow={overlappingVacationErrorShow}
          />
        </Box>
      </Box>
    </Box>
  );
};
