import * as yup from "yup";

export const vacancyValidationSchema = yup.object({
  role: yup
    .string()
    .strict()
    .max(50, "Role must be at most 100 characters")
    .required("Role is required"),
  type: yup
    .string()
    .required("Type is required")
    .oneOf(["Full-time", "Part-time"], "Type must be Full-time or Part-time"),
  location: yup.string().required("Location is required"),
  officeMode: yup
    .string()
    .required("Office Mode is required")
    .oneOf(["On Site", "Hybrid", "Remote"], "Office Mode must be On Site, Hybrid or Remote"),
  benefits: yup.string().strict().required("Benefits are required"),
  skills: yup.string().strict().required("Skills are required"),
  responsibilities: yup.string().strict().nullable(),
});
