import React, { FC } from "react";
import { Box } from "@mui/material";

import "./ProjectDetailsComponent.css";
import { ProjectDetails } from "../ProjectDetails";
import { RootState, useTypedSelector } from "../../../../../stores/store";
import { ProjectDetailsTeamMembers } from "../TeamMembers/ProjectDetailsTeamMembers";
import { CreateProject } from "../../ProjectCreate/CreateProject/CreateProject";
import { FormikValues } from "formik";
import { TeamMembers } from "../../ProjectCreate/TeamMembers/TeamMembers";

interface ProjectDetailsComponentProps {
  activeTab: number;
  formik: FormikValues;
  inEditMode: boolean;
}

export const ProjectDetailsComponent: FC<ProjectDetailsComponentProps> = ({
  activeTab,
  formik,
  inEditMode,
}) => {
  const { project, edited, error } = useTypedSelector((state: RootState) => state.projects);

  const renderTab = (index: number) => {
    switch (index) {
      case 0:
        return inEditMode ? (
          <CreateProject inEditMode={inEditMode} formik={formik} />
        ) : (
          <ProjectDetails project={project} edited={edited} error={error} />
        );
      case 1:
        return inEditMode ? (
          <TeamMembers inEditMode={inEditMode} formik={formik} />
        ) : (
          <ProjectDetailsTeamMembers currency={formik.values.currency ?? ""} project={project} />
        );
    }
  };
  return (
    <div>
      <Box className="project-details-container">{renderTab(activeTab)}</Box>
    </div>
  );
};
