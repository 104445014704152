import React, { FC, useEffect, useState } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { emptyVacancy } from "../../../../data/mkt/vacancy";
import { createVacancy, setCreated, getLocations } from "../../../../stores/mkt/Career.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { VacancyCreateEdit } from "../../../../components/mkt/Vacancy/VacancyCreateEdit/VacancyCreateEdit";
import { vacancyValidationSchema } from "../../../../utils/mkt/vacancy";
import { useNavigate } from "react-router-dom";
import "./VacancyCreatePage.css";

export const VacancyCreatePage: FC = () => {
  const dispatch = useTypedDispatch();
  const { created, locations } = useTypedSelector((state: RootState) => state.careers);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    if (created) {
      dispatch(setCreated(false));
      navigate("/careers");
    }
  }, [created]);

  useEffect(() => {
    const fetchLocations = async () => {
      await dispatch(getLocations());
    };
    fetchLocations();
  }, []);

  const formik = useFormik({
    initialValues: { ...emptyVacancy, order: 100 },
    onSubmit: (userInputData) => {},
    validationSchema: vacancyValidationSchema,
  });

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return;
    }
    const vacancy = {
      ...formik.values,
      role: formik.values.role.trim(),
      responsibilities: formik.values.responsibilities.trim(),
      skills: formik.values.skills.trim(),
      benefits: formik.values.benefits.trim(),
    };
    dispatch(createVacancy({ vacancy: vacancy }));
  };

  const swithTabAction = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="VacancyCreatePage-pageEditDiv">
      <PageCreate
        pageContent={{
          pageTitle: "Create Vacancy",
          previousPage: "/careers",
          action: createAction,
          type: "Create",
          tabs: ["Basic Information"], //["Basic Information", "SEO"]
          switchTabAction: swithTabAction,
        }}
      >
        <VacancyCreateEdit formik={formik} locations={locations} activeTab={activeTab} />
      </PageCreate>
    </div>
  );
};
