import React, { FC } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Input } from "../../../../Inputs/Input";
import { CollapseExpand } from "../../../../CollapseExpand/CollapseExpand";
import { formikTrimStrings } from "../../../../../utils/string.utils";
import "./CVTechnology.css";

interface CVTechnologyProps {
  formik: any;
  title: string;
  length: number;
  index: number;
  hrStyle?: any;
  handleDelete: (index: number) => void;
  handleInputChange: (event: any) => {};
}

export const CVTechnology: FC<CVTechnologyProps> = ({
  formik,
  title,
  length,
  index,
  hrStyle,
  handleDelete,
  handleInputChange,
}) => {
  return (
    <CollapseExpand
      title={title}
      index={index}
      length={length}
      backgroundColor={hrStyle?.collapseBottomBorder}
      titleColor={hrStyle?.collapseTitleColor}
    >
      <Box display="flex" className="CVTechnology-box">
        <div className="CVTechnology-inputs">
          <Input
            error={formik.errors?.techStack && formik.errors.techStack[index]?.technologyArea}
            value={formik.values.techStack[index]?.technologyArea}
            handleChange={handleInputChange}
            handleBlur={(event) => formikTrimStrings(formik, event)}
            label="Technology Area"
            name={`techStack[${index}].technologyArea`}
            type="text"
            width="20%"
            height="2rem"
            backgroundColor={hrStyle?.inputBackground}
            titleColor={hrStyle?.inputLabelColor}
          />
          <Input
            error={formik.errors?.techStack && formik.errors.techStack[index]?.technologies}
            value={formik.values.techStack[index]?.technologies}
            handleChange={handleInputChange}
            handleBlur={(e) => formikTrimStrings(formik, e)}
            label="Technologies"
            name={`techStack[${index}].technologies`}
            type="text"
            multiline
            maxRows={4}
            width="50%"
            height="6em"
            backgroundColor={hrStyle?.inputBackground}
            titleColor={hrStyle?.inputLabelColor}
          />
        </div>
        <div className="CVTechnology-deleteButton CV-deleteButton">
          <Tooltip title="Delete Technology" onClick={() => handleDelete(index)}>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    </CollapseExpand>
  );
};
