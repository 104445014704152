import React, { FC } from "react";
import { Box, Button } from "@mui/material";
import "./CVEducationCertifications.css";
import { CVEducationCertification } from "./CVEducationCertification/CVEducationCertification";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormikValues } from "formik";
import { EducationCertification } from "../../../../types/internal/cv/EducationCertification";
import { emptyEducationCertification } from "../../../../data/internal/cv/educationCertifications";

interface CVEducationCertifications {
  formik: FormikValues;
  handleInputChange: (event: any) => {};
  hrStyle?: any;
}

export const CVEducationCertifications: FC<CVEducationCertifications> = ({
  formik,
  handleInputChange,
  hrStyle,
}) => {
  let formikCertifications = [...formik.values.certifications];
  const addEducationCertifications = () => {
    formikCertifications = [...formik.values.certifications, emptyEducationCertification];
    formik.setValues({
      ...formik.values,
      certifications: formikCertifications,
    });
  };

  const handleDelete = (index: number) => {
    let aux = [...formik.values.certifications];
    aux.splice(index, 1);
    formik.setFieldValue("certifications", aux);
    formikCertifications = aux;
  };

  const showHRStyle = () => {
    return hrStyle != undefined && hrStyle != null;
  };

  return (
    <Box id="container" className="CVEducationCertifications-formContainer">
      <Box display={"flex"} justifyContent="center">
        <Button
          sx={{
            backgroundColor: !showHRStyle() ? "white" : hrStyle.buttonBackgroundColor,
            color: !showHRStyle() ? "black" : hrStyle.buttonFontColor,
            border: !showHRStyle()
              ? "1px solid black"
              : "1px solid " + String(hrStyle.buttonBorder),
            margin: "0em 0em 2em 0em",
            "&:hover": {
              backgroundColor: hrStyle?.buttonHoverColor,
              color: hrStyle?.inputLabelColor,
            },
          }}
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            addEducationCertifications();
          }}
        >
          Add a new Education/Cert
        </Button>
      </Box>
      <Box className="CVEducationCertifications-dropdowns">
        {formikCertifications.map(
          (educationCertification: EducationCertification, index: number) => (
            <CVEducationCertification
              formik={formik}
              title={
                formik.values.certifications[index]?.degree +
                " - " +
                formik.values.certifications[index]?.institutionName
              }
              key={index}
              index={index}
              length={formikCertifications.length}
              handleDelete={handleDelete}
              handleInputChange={handleInputChange}
              hrStyle={hrStyle}
            />
          )
        )}
      </Box>
    </Box>
  );
};
