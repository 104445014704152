import * as yup from "yup";
import { Category } from "../../types/mkt/Category";

const categorySchema = yup
  .object({ id: yup.string(), name: yup.string().required("Category is required") })
  .test("unique", "Category must be unique.", function validateUnique(currentCategory) {
    const otherCategory = this.parent.filter((category: Category) => category !== currentCategory);
    const isDuplicate = otherCategory.some(
      (otherCategory: Category) => otherCategory.id === currentCategory.id
    );
    return isDuplicate ? this.createError({ path: `${this.path}.name` }) : true;
  });

export const blogPostValidationSchema = yup.object({
  title: yup
    .string()
    .strict()
    .required("Title is required")
    .max(100, "Title must be at most 100 characters"),
  subtitle: yup
    .string()
    .strict()
    .required("Subtitle is required")
    .max(200, "Subitle must be at most 200 characters"),
  date: yup.string().required("Date is required"),
  readTime: yup
    .string()
    .strict()
    .required("Read Time is required")
    .matches(/^\d+$/, "Read time must be a number")
    .max(4, "Read Time must be at most 4 digits"),
  thumbnail: yup.string().required("Thumbnail is required"),
  categories: yup.array().of(categorySchema).min(1, "At least one category is required"),
  author: yup.object().shape({
    name: yup
      .string()
      .strict()
      .required("Name is required")
      .max(30, "Author name must be at most 30 characters"),
    role: yup
      .string()
      .strict()
      .required("Role is required")
      .max(30, "Role must be at most 30 characters"),
    photo: yup.string().strict().required("Author Photo is required"),
  }),
});
