import { Box, FormControl, FormHelperText, MenuItem, Select, Typography } from "@mui/material";
import React, { FC } from "react";

interface InputSelectorProps {
  disabled?: boolean;
  label?: string;
  error: string | undefined;
  value: string;
  target: string;
  multiple: boolean;
  emptyValue: string | string[];
  emptyLabel: string;
  values: string[];
  notMappedValues?: string[];
  padding: string;
  maxWidth: string;
  height: string;
  handleChange: (e: any) => void;
  handleBlur?: (e: any) => void;
  backgroundColor?: string;
  titleColor?: string;
  borderRadius?: string;
}

export const InputSelector: FC<InputSelectorProps> = ({
  disabled,
  label,
  error,
  value,
  target,
  multiple,
  emptyValue,
  emptyLabel,
  values,
  notMappedValues,
  padding,
  maxWidth,
  height,
  handleChange,
  handleBlur,
  backgroundColor,
  titleColor,
  borderRadius,
}) => {
  return (
    <Box sx={{ padding: padding, width: maxWidth, height: height }}>
      <Typography
        variant="body1"
        sx={{
          color: error ? "#d32f2f" : titleColor ? titleColor : "inherit",
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          fontSize: "14px",
        }}
      >
        {label}
      </Typography>
      <FormControl fullWidth variant="standard" error={Boolean(error)}>
        <Select
          displayEmpty
          variant="standard"
          inputProps={{ disableunderline: "true" }}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          size="medium"
          multiple={multiple}
          name={target}
          disabled={disabled}
          defaultValue={null}
          sx={{
            textAlign: "center",
            backgroundColor: backgroundColor ? backgroundColor : "#C9ECE4",
            padding: "0.5em",
            borderRadius: borderRadius ? borderRadius : "24px",
            borderWidth: "0",
            width: "100%",
            "&::before": {
              borderBottom: 0,
            },
            "&::after": {
              borderBottom: 0,
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: 0,
            },
          }}
        >
          <MenuItem value={emptyValue} disabled>
            {emptyLabel}
          </MenuItem>
          {values.map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                value={item}
                sx={{
                  justifyContent: "center",
                  display: notMappedValues?.includes(item) ? "none" : "flex",
                }}
              >
                {item}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  );
};
