import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { createTheme, ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import IconsEot from "./assets/fonts/icomoon.eot";
import IconsTtf from "./assets/fonts/icomoon.ttf";
import IconsWoff from "./assets/fonts/icomoon.woff";
import IconsSvg from "./assets/fonts/icomoon.svg";
import store from "./stores/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { setupInterceptors } from "./services/HttpClient";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

declare global {
  interface Window {
    google: any;
  }
}
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const persistor = persistStore(store);

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, Arial",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "icons";
          src: url(${IconsEot})
              format("embedded-opentype"),
            url(${IconsTtf}) format("truetype"),
            url(${IconsWoff}) format("woff"),
            url(${IconsSvg}) format("svg");
          font-weight: normal;
          font-style: normal;
          font-display: block;
        }
      `,
    },
    MuiButton: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            backgroundColor: "#00B189",
            borderRadius: "12px",
            color: "white",
            marginRight: "1em",
            textTransform: "capitalize",
            ":hover": {
              opacity: "0.65",
              backgroundColor: "#00B189",
              color: "white",
            },
          },
        },
        {
          props: { color: "error" },
          style: {
            backgroundColor: "white",
            borderRadius: "12px",
            color: "#f44336",
            marginRight: "1em",
            textTransform: "capitalize",
            ":hover": {
              opacity: "0.65",
              backgroundColor: "white",
              color: "#f44336",
            },
          },
        },
      ],
    },
  },
});
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

setupInterceptors(store);
