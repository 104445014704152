import { Add, PlusOneOutlined } from "@mui/icons-material";
import React, { FC, useEffect, useState } from "react";
import "./AddButton.css";

export enum AddButtonTypes {
  IO,
  HR,
  Finance,
}

interface ButtonProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type: AddButtonTypes;
}

const getButtonColorClass = (type: AddButtonTypes) => {
  let colorClass = "";
  switch (type) {
    case AddButtonTypes.IO:
      colorClass = "add-buttonIO";
      break;
    case AddButtonTypes.HR:
      colorClass = "add-buttonHR";
      break;
    case AddButtonTypes.Finance:
      colorClass = "add-buttonFinace";
      break;
    default:
      colorClass = "add-buttonIO";
  }
  return colorClass;
};

export const AddButton: FC<ButtonProps> = ({ text, onClick, type }) => {
  const [colorClass, setColorClass] = useState("");

  useEffect(() => {
    setColorClass(getButtonColorClass(type));
  }, []);

  return (
    <button className={`add-button ${colorClass}`} onClick={onClick}>
      {text}
      <Add
        sx={{
          width: "24px",
          height: "24px",
          border: "2px solid white",
          borderRadius: "100px",
        }}
      />
    </button>
  );
};
