import React, { FC, useEffect, useState } from "react";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { getUser } from "../../../stores/internal/User.store";
import { HRPage } from "../../../components/hr/Global/HRPage/HRPage";
import { StartScreen } from "../../../components/hr/StartScreen/StartScreen";

export const HomePage: FC = () => {
  const dispatch = useTypedDispatch();
  const { user, loading, error } = useTypedSelector((state: RootState) => state.user);
  const { token, email } = useTypedSelector((state: RootState) => state.auth);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      await dispatch(getUser({ email: email!! })).then(() => {
        setLoaded(true);
      });
    };
    // call the function
    fetchUser();
  }, []);

  return (
    <HRPage pageContent={null} sidebar={true}>
      <StartScreen />
    </HRPage>
  );
};
