import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Project } from "../../../../types/hr/Project";
import { ProjectStatus } from "../../../../constants/hr/ProjectStatus";
import { MiniEmployeeAvatar } from "./avatar/MiniEmployeeAvatar";
import { MiniEmployeeAvatarNumber } from "./avatar/MiniEmployeeAvatarNumber";

import "./ProjectCard.css";
import { ProjectClosedSVG } from "./icons/ProjectClosedSVG";
import { ProjectExploratorySVG } from "./icons/ProjectExploratorySVG";
import { ProjectOpenSVG } from "./icons/ProjectOpenSVG";

interface ProjectCardProps {
  project: Project;
}

export const ProjectCard: FC<ProjectCardProps> = ({ project }) => {
  const navigate = useNavigate();

  const startDate = new Date(Date.parse(project.startDate)).toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  const statusIcon =
    project.status == ProjectStatus.OPEN ? (
      <ProjectOpenSVG />
    ) : project.status == ProjectStatus.CLOSED ? (
      <ProjectClosedSVG />
    ) : (
      <ProjectExploratorySVG />
    );

  // put only 4 team member avatars
  const activeTeamMembers = project.teamMembers.filter((member) => member.active);
  const teamMembersAvatarOffset = activeTeamMembers.length - 3;

  const membersWithImage =
    teamMembersAvatarOffset > 1 ? activeTeamMembers.slice(0, 3) : activeTeamMembers;

  return (
    <div
      className="project-container"
      onClick={() => navigate(`/projects/${project.wbs}`, { replace: true })}
    >
      <div className="symbol-state">{statusIcon}</div>
      <div className="logo-section">
        <img className="logo-image" src={project.pictureURL}></img>
      </div>
      <div className="project-name-section">
        {project.name} ({project.wbs})
      </div>
      <div className="start-section">
        <span className="start-text">Start</span>
        <h5>{startDate}</h5>
      </div>
      <div className="team-section">
        <span className="start-text">Team</span>
        <div className="avatar-section">
          {membersWithImage.map((member, index) => (
            <MiniEmployeeAvatar
              pictureURL={member.pictureURL}
              key={`project-member-avatar-${index}`}
              avatarShift={index * -5}
            />
          ))}
          {teamMembersAvatarOffset > 1 ? (
            <MiniEmployeeAvatarNumber members={teamMembersAvatarOffset} shift={-15} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
