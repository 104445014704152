import { ADD } from "mobx/dist/internal";
import React, { FC, useEffect, useState } from "react";
import { Bin } from "../../../../../assets/svg/Bin";
import { EditIcon } from "../../../../../assets/svg/EditIcon";
import { emptyEmployee } from "../../../../../data/hr/emptyEmployee";
import HREmployeeService from "../../../../../services/hr/HREmployee.service";
import { Training } from "../../../../../types/internal/Training";
import { isHttpCodeValid } from "../../../../../utils/common/http";
import { AddButton, AddButtonTypes } from "../../../../buttons/addButton/AddButton";
import "./TrainingList.css";

interface TrainingListProps {
  training: Training[];
  employeeEmail?: string;
  onDeleteTraining: (index: number) => void;
  onClickEdit: (index: number) => void;
  onClickAdd: () => void;
  onClickItem: (index: number) => void;
}

export const TrainingList: FC<TrainingListProps> = ({
  training,
  employeeEmail,
  onDeleteTraining,
  onClickEdit,
  onClickAdd,
  onClickItem,
}) => {
  const [employee, setEmployee] = useState(emptyEmployee);

  useEffect(() => {
    getHREmployee();
  }, []);

  const getHREmployee = async () => {
    if (!employeeEmail) return;
    const res = await HREmployeeService.getEmployee(employeeEmail);
    if (isHttpCodeValid(res.code)) {
      setEmployee(res.user);
      return;
    }
  };

  const renderHeader = () => (
    <div className="TrainingList-header-container">
      <div className="EmployeeInfo-name">{employee.name}</div>
      <AddButton text="New Training" onClick={onClickAdd} type={AddButtonTypes.HR} />
    </div>
  );

  const TrainingHeader = () => (
    <div className="item-row">
      <p className="item-column">Name</p>
      <p className="item-column">Hours</p>
      <p className="item-column">Year</p>
      <p className="item-column">Attachment</p>
      <div className="icons-column" />
    </div>
  );

  const TrainingRow = (value: string) => <p className="item-column">{value}</p>;

  const TrainingItem = (cert: Training, index: number) => (
    <div className="item-row item-row-cursor" onClick={() => onClickItem(index)}>
      <div className="item-container">
        <p className="item-column">{cert.description}</p>
        <p className="item-column">{cert.hours.toString()}</p>
        <p className="item-column">{cert.year}</p>
        <p className="item-column certificate-color">{cert.attachment || ""}</p>
        <div className="icons-column" onClick={(e) => e.stopPropagation()}>
          <div className="icon-button" onClick={() => onClickEdit(index)}>
            <EditIcon />
          </div>
          <div className="icon-button" onClick={() => onDeleteTraining(index)}>
            <Bin />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Training-container">
      {renderHeader()}
      {TrainingHeader()}
      {training.map((cert, index) => TrainingItem(cert, index))}
    </div>
  );
};
