import { borderColor, height, width } from "@mui/system";
import { hover } from "@testing-library/user-event/dist/hover";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./EmployeeContacts.css";

interface EmployeeContactsProps {
  title: string;
  telephone: number;
  email: string;
}

export const EmployeeContacts: FC<EmployeeContactsProps> = ({ telephone, email, title }) => {
  const [onHover, setOnHover] = useState<boolean>(false);
  const [onPress, setOnPress] = useState<boolean>(false);

  return (
    <div className="EmployeeContacts-container">
      <br />
      <br />
      <div className="EmployeeContacts-data-title">{title} </div>
      <br />
      <div className="EmployeeContacts-content">
        <div className="EmployeeContacts-data">
          <div className="EmployeeContacts-data-firstRow">{"Telephone"} </div>
          <div className="EmployeeContacts-data-secondRow">{telephone ? telephone : "N/A"}</div>
        </div>
        <div className="EmployeeContacts-data">
          <div className="EmployeeContacts-data-firstRow">{"Email Address"} </div>
          <div className="EmployeeContacts-data-secondRow">{email ? email : "N/A"}</div>
        </div>
      </div>
    </div>
  );
};
