import React, { FC, useEffect, useRef, useState } from "react";
import "./ProjectDetails.css";
import { ProjectStatus } from "../../../../constants/hr/ProjectStatus";
import { Box } from "@mui/material";
import { MiniEmployeeAvatar } from "../ProjectCard/avatar/MiniEmployeeAvatar";
import { Project } from "../../../../types/hr/Project";
import { getDayThDateString, getExtensiveDateString } from "../../../../utils/date";
import { useTypedDispatch } from "../../../../stores/store";
import { setEdited, setError } from "../../../../stores/hr/Project.store";

interface ProjectDetailsProps {
  project: Project;
  edited: boolean;
  error: any;
}

export const ProjectDetails: FC<ProjectDetailsProps> = ({ project, edited, error }) => {
  const dispatch = useTypedDispatch();
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    defineMessageTimeDuration("edited", edited);
  }, [edited]);

  useEffect(() => {
    defineMessageTimeDuration("error", error);
  }, [error]);

  const defineMessageTimeDuration = (header: string, value: boolean) => {
    if (value) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(
        () => dispatch(header === "edited" ? setEdited(false) : setError(false)),
        2000
      );
    }
  };

  const startDate = getExtensiveDateString(project.startDate);

  const billingCycle = getDayThDateString(project.billingCycle);

  const status = project.status.substring(0, 1) + project.status.substring(1).toLowerCase();
  const statusColor =
    project.status === ProjectStatus.OPEN
      ? "#4CA02E"
      : project.status === ProjectStatus.CLOSED
      ? "#828382"
      : "#E557F1";

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="HR-message-container">
          {edited && <div className="HR-message-success"> Success </div>}
          {error && <div className="HR-message-fail"> Failed </div>}
        </div>
      </div>
      <Box className="project-details-main-section">
        <div className="project-details-image">
          <img src={project.pictureURL} className="project-details-image-tag" />
        </div>
        <div className="project-details-section">
          <div className="project-details-section-row">
            <div className="project-details-attribute">
              <div className="project-details-attribute-label">Project Name</div>
              <div className="project-details-attribute-value">{project.name}</div>
            </div>
            <div className="project-details-attribute">
              <div className="project-details-attribute-label">WBS Code</div>
              <div className="project-details-attribute-value">{project.wbs}</div>
            </div>
            <div className="project-details-attribute">
              <div className="project-details-attribute-label">Status</div>
              <div
                style={{
                  color: statusColor,
                }}
                className="project-details-attribute-value"
              >
                {status}
              </div>
            </div>
          </div>
          <div className="project-details-section-row">
            <div className="project-details-attribute">
              <div className="project-details-attribute-label">Start Date</div>
              <div className="project-details-attribute-value">{startDate}</div>
            </div>
            <div className="project-details-attribute">
              <div className="project-details-attribute-label">Currency</div>
              <div className="project-details-attribute-value">{project.currency}</div>
            </div>
            <div className="project-details-attribute">
              <div className="project-details-attribute-label">Billing Cycle</div>
              <div className="project-details-attribute-value">{billingCycle}</div>
            </div>
          </div>
          <div className="project-details-section-row">
            <div className="project-details-attribute">
              <div className="project-details-attribute-label">Description</div>
              <div className="project-details-attribute-value">{project.description}</div>
            </div>
          </div>
          <div className="project-details-section-row">
            <div className="project-details-attribute">
              <div className="project-details-attribute-label">Approvers</div>
              <div className="project-details-approver-avatars">
                {project.approvers?.map((approver, index) => (
                  <MiniEmployeeAvatar
                    key={`project-details-approver-avatar-${index}`}
                    pictureURL={approver.pictureURL}
                    avatarShift={0}
                    height="80px"
                    width="80px"
                    marginLeft={index === 0 ? "0" : "1em"}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
