import React, { FC, useEffect, useState } from "react";
import { VacationsBalance } from "../../../../types/internal/vacation/VacationsBalance";
import VacationService from "../../../../services/internal/Vacation.service";
import HREmployeeService from "../../../../services/hr/HREmployee.service";
import { isHttpCodeValid } from "../../../../utils/common/http";
import "./vacations.css";
import { HREmployee } from "../../../../types/hr/HREmployee";
import {
  dateToDDMMYYYY,
  dateToYYYMMDD,
  getFirstDayOfYear,
  getLastDayOfNextYear,
} from "../../../../utils/date";
import { API_REQUEST_SETTINGS } from "../../../../constants/internal/apiRequestSettings.const";
import { Vacation } from "../../../../types/internal/vacation/Vacation";
import { ListPageController } from "../../../common/buttons/ListPageController/ListPageController";
import { SubmissionStatus } from "../../../../data/common/SubmissionStatus";
import { stringTitleCase } from "../../../../utils/string.utils";

interface HRVactionsProps {
  employeeEmail?: string;
}

export const HRVacations: FC<HRVactionsProps> = ({ employeeEmail }) => {
  const [employee, setEmployee] = useState<HREmployee>();
  const [vacationSummary, setVacationSummary] = useState<VacationsBalance>();
  const [page, setPage] = useState(0);
  const [vacations, setVacations] = useState<Vacation[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getHREmployee();
  }, [employeeEmail]);

  useEffect(() => {
    getVacationsSummary();
    getVacations();
  }, [employee]);

  const getHREmployee = async () => {
    if (!employeeEmail) return;
    const res = await HREmployeeService.getEmployee(employeeEmail);
    if (isHttpCodeValid(res.code)) {
      setEmployee(res.user);
      return;
    }
  };

  const getVacationsSummary = async () => {
    if (!employee) return;
    const res = await VacationService.getVacationsBalance(employee.email);
    if (isHttpCodeValid(res.code)) {
      setVacationSummary(res.vacationsBalance);
      return;
    }
    setVacationSummary(undefined);
  };

  useEffect(() => {
    getVacations();
  }, [page]);

  const getVacations = async () => {
    if (!employee) return;
    const res = await VacationService.getVacationFrom(
      employee.email,
      dateToYYYMMDD(getFirstDayOfYear()),
      dateToYYYMMDD(getLastDayOfNextYear()),
      page,
      API_REQUEST_SETTINGS.GET_VACATIONS_TABLE_SIZE
    );
    if (isHttpCodeValid(res.code)) {
      onGetVacationsSuccess(res.vacationsPaginated);
    }
  };

  const onGetVacationsSuccess = (vacsPage: any) => {
    setTotalPages(vacsPage.totalPages);
    setVacations(vacsPage.vacations);
  };

  const onNextVacactionsPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const onPrevVacactionsPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const getTextToDisplayDates = (vacation: Vacation) => {
    return (
      dateToDDMMYYYY(new Date(vacation.startDate)) +
      " to " +
      dateToDDMMYYYY(new Date(vacation.endDate))
    );
  };

  //////////////
  // render //

  const VacationSummaryItem = ({ title, value }: { title: string; value: number }) => (
    <div className="Vac-Summary-Item-Container">
      <div className="Vac-Summary-Item-title">{value}</div>
      <div className="Vac-Summary-Item-value">{stringTitleCase(`${title} days`)}</div>
    </div>
  );

  const VacationsSummary = () => (
    <div className="Vac-Summary-Container">
      {vacationSummary &&
        Object.keys(vacationSummary).map((key: string, index: number) => (
          <VacationSummaryItem
            key={index}
            title={key}
            value={vacationSummary[key as keyof VacationsBalance]}
          />
        ))}
    </div>
  );

  const VacationsListHeader = () => (
    <div className="vacations-second-content">
      <div className="secondary-text-longer">Period</div>
      <div className="secondary-text">Days</div>
      <div className="secondary-text-shorter">Status</div>
    </div>
  );

  const VacationItem = ({ vacation, index }: { vacation: Vacation; index: number }) => (
    <div className={`vacation-container ${index % 2 === 0 ? "vacation-odd-container" : ""}`}>
      <div className="period-vacations-content">
        <div className="period-vacations">{getTextToDisplayDates(vacation)}</div>
        <div className="days-vacations">{vacation.total}</div>
        {vacation.status === SubmissionStatus.APPROVED ? (
          <div className="approved-vacations">Approved</div>
        ) : vacation.status === SubmissionStatus.APPROVED ? (
          <div className="not-approved-vacations">Not Approved</div>
        ) : (
          <div className="submitted-vacations">Submitted</div>
        )}
      </div>
    </div>
  );

  return (
    <div className="vacationDetails-Parent">
      <div className="vacation-info">
        <div className="EmployeeInfo-name">{employee?.name}</div>
        <VacationsSummary />
        <ListPageController
          totalPages={totalPages}
          currentPage={page}
          onNextPage={onNextVacactionsPage}
          onPrevPage={onPrevVacactionsPage}
          extraStyle={{ marginTop: "6em" }}
        />
        {vacations.length > 0 && <VacationsListHeader />}
        <div className="HR-Vac-List-Container">
          {vacations.map((vacation: Vacation, index: number) => (
            <VacationItem key={index} vacation={vacation} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
