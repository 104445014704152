import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingProps {
  extraStyle?: Object;
}

export const Loading: React.FC<LoadingProps> = ({ extraStyle }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", ...extraStyle }}>
      <CircularProgress />
    </div>
  );
};
