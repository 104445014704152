import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyTraining } from "../../data/hr/emptyTraining";
import TrainingService from "../../services/hr/Training.service";
import { TrainingState } from "../../types/hr/TrainingState";
import { Training } from "../../types/internal/Training";

const trainingSlice = createSlice({
  name: "training",
  initialState: {
    loading: false,
    error: null,
    training: emptyTraining,
    trainings: [],
    created: false,
    edited: false,
    deleted: false,
    deleteIndex: -1,
    editIndex: -1,
  } as TrainingState,
  reducers: {
    setTrainings(state, action) {
      state.trainings = action.payload;
    },
    setDeleteIndex(state, action) {
      state.deleteIndex = action.payload;
    },
    setEditIndex(state, action) {
      state.editIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrainingByEmployee.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.trainings = [];
      }
    });
    builder.addCase(getTrainingByEmployee.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.trainings = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getTrainingByEmployee.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createTraining.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.training = emptyTraining;
      }
    });
    builder.addCase(createTraining.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.training = action.payload;
        state.trainings = state.trainings.concat(state.training);
        state.loading = false;
      }
    });
    builder.addCase(createTraining.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(deleteTraining.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.training = emptyTraining;
      }
    });
    builder.addCase(deleteTraining.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.trainings = [
          ...state.trainings.filter(
            (newTraining) => newTraining.id !== state.trainings[state.deleteIndex].id
          ),
        ];
      }
    });
    builder.addCase(deleteTraining.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(updateTraining.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.training = emptyTraining;
      }
    });
    builder.addCase(updateTraining.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.training = action.payload;
        state.loading = false;
        state.edited = true;
        state.trainings[state.editIndex] = state.training;
      }
    });
    builder.addCase(updateTraining.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getTrainingByEmployee = createAsyncThunk(
  "hr/getTrainingByEmployee",
  async (training: { email: string }) => {
    const response = await TrainingService.getTrainingByEmployee(training.email);
    return response.trainings;
  }
);

export const createTraining = createAsyncThunk(
  "hr/createTraining",
  async (training: { email: string; newTraining: Training }) => {
    const response = await TrainingService.createTraining(training.email, training.newTraining);
    return response.training;
  }
);

export const deleteTraining = createAsyncThunk(
  "hr/deleteTraining",
  async (training: { id: string; email: string }) => {
    await TrainingService.deleteTraining(training.id, training.email);
  }
);

export const updateTraining = createAsyncThunk(
  "hr/updateTraining",
  async (training: { id: string; email: string; newTraining: Training }) => {
    const response = await TrainingService.updateTraining(
      training.id,
      training.email,
      training.newTraining
    );
    return response.training;
  }
);

export default trainingSlice.reducer;
export const { setDeleteIndex, setEditIndex } = trainingSlice.actions;
