export enum WorkType {
  FULLTIME = "FULLTIME",
  PARTIME = "PARTIME",
}

export const workTypeOptions = ["Full Time", "Part Time"];

export const workTypeKeys = (type: string): WorkType => {
  switch (type) {
    case "Full Time":
      return WorkType.FULLTIME;
    case "Part Time":
      return WorkType.PARTIME;
    default:
      return WorkType.FULLTIME;
  }
};

export const workTypeStrings = (wortType: WorkType): string => {
  switch (wortType) {
    case WorkType.FULLTIME:
      return "Full Time";
    case WorkType.PARTIME:
      return "Part Time";
    default:
      return "";
  }
};
