import { Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import React, { FC } from "react";

interface InputProps {
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  value: string;
  handleChange: (e: any) => void;
  name: string;
  type: string;
  multiline?: boolean;
  maxRows?: number;
  label?: string;
  width: string;
  height?: string;
  maxLength?: number;
  handleBlur?: (e: any) => void;
  backgroundColor?: string;
  titleColor?: string;
  borderRadius?: string;
  errorColor?: string;
  noPadding?: boolean;
  minRows?: number;
  hr?: boolean;
  cert?: boolean;
}

export const Input: FC<InputProps> = ({
  error,
  value,
  handleChange,
  handleBlur,
  name,
  type,
  multiline,
  maxRows,
  label,
  width,
  height,
  maxLength,
  placeholder,
  titleColor,
  backgroundColor,
  borderRadius,
  errorColor,
  disabled,
  noPadding,
  minRows,
  hr,
  cert,
}) => {
  const inputBox = () => (
    <Box
      sx={{
        padding: noPadding ? "0" : "1em",
        width: width,
        height: "auto",
      }}
    >
      {label && (
        <Typography
          variant="body1"
          sx={{
            color: error
              ? errorColor
                ? "white"
                : "#d32f2f"
              : titleColor != undefined
              ? titleColor
              : "inherit",
          }}
        >
          {label}
        </Typography>
      )}
      <FormControl fullWidth variant="standard" error={Boolean(error)}>
        <TextField
          placeholder={placeholder}
          name={name}
          type={type}
          variant="standard"
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          disabled={disabled}
          sx={{
            backgroundColor: backgroundColor ? backgroundColor : "#C9ECE4",
            padding: "0.5em",
            paddingLeft: "1em",
            borderRadius: borderRadius ? borderRadius : "24px",
            borderWidth: "0",
            width: "100%",
            height: "100%",
          }}
          InputProps={{
            style: { color: "black" },
            disableUnderline: true,
          }}
          multiline={multiline}
          minRows={minRows || maxRows}
          maxRows={maxRows}
          inputProps={{
            maxLength: maxLength,
            sx: {
              "&::placeholder": {
                color: "black",
                opacity: "0.7",
                transition: "opacity 0.5s",
              },
              "&:focus::placeholder": {
                opacity: "0",
                transition: "opacity 0.5s",
              },
            },
          }}
        />
        <FormHelperText
          style={{
            color: errorColor ? errorColor : "#d32f2f",
          }}
        >
          {error}
        </FormHelperText>
      </FormControl>
    </Box>
  );

  const hrInputBox = () => (
    <Box
      sx={{
        width: width,
        height: "100%",
      }}
    >
      <Typography
        variant="body1"
        sx={{ color: error ? (errorColor ? "white" : "#d32f2f") : "white" }}
      >
        {label}
      </Typography>
      <FormControl fullWidth variant="standard" error={Boolean(error)}>
        <TextField
          placeholder={placeholder}
          name={name}
          type={type}
          variant="standard"
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          disabled={disabled}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            padding: cert ? "1em" : "2em",
            borderWidth: "0",
            width: "100%",
            height: height,
            justifyContent: "center",
          }}
          InputProps={{
            style: { color: "black" },
            disableUnderline: true,
          }}
          multiline={multiline}
          rows={maxRows}
          inputProps={{
            maxLength: maxLength,
            sx: {
              "&::placeholder": {
                color: "black",
                opacity: "0.7",
                transition: "opacity 0.5s",
              },
              "&:focus::placeholder": {
                opacity: "0",
                transition: "opacity 0.5s",
              },
            },
          }}
        />
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Box>
  );

  const displayInputBox = () => {
    return hr === true ? hrInputBox() : inputBox();
  };

  return displayInputBox();
};
