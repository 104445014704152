import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { User } from "../../../../types/internal/User";
import { Button, ButtonTypes } from "../../../buttons/button/Button";
import { ImageInputSquare } from "../../../ImageInputs/ImageInputSquare/ImageInputSquare";
import { Input } from "../../../Inputs/Input";
import "./UserDetails.css";
import { USER_DETAILS_FORM_CONFIG as FORM_CONFIG } from "./UserDetailsFormConfig";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import UserService from "../../../../services/internal/User.service";
import { Loading } from "../../../Loading/loading";
import { isHttpCodeValid } from "../../../../utils/common/http";
import "../../../../constants/regex.const";
import {
  getAssignedVacationDaysForCurrentYear,
  computeVacationDaysGroupedByCategory,
  APPROVED_DAYS_CATEGORIES,
  SCHEDULED_DAYS_CATEGORIES,
} from "../../../../constants/internal/vacations.const";
import {
  getVacationProfile,
  getVacationsBalance,
} from "../../../../stores/internal/Vacation.store";
import { API_REQUEST_SETTINGS } from "../../../../constants/internal/apiRequestSettings.const";
import { dateToYYYMMDD, getFirstDayOfYear, getLastDayOfYear } from "../../../../utils/date";
import { userValidation } from "../../../../utils/internal/user";
import { REGEX } from "../../../../constants/regex.const";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { CVPDF } from "../../../cv/CVPDF";
import { CV } from "../../../../types/internal/cv/CV";
import CVService from "../../../../services/internal/CV.service";

interface UserDetailsInputProps {
  inEditMode: boolean;
  value: string;
  onChange: (event: any) => void;
  name: string;
  label: string;
  type: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  error?: string;
}

const UserDetailsField: FC<UserDetailsInputProps> = ({
  inEditMode,
  value,
  onChange,
  name,
  label,
  type,
  multiline,
  maxRows,
  minRows,
  error,
}) => {
  return !inEditMode ? (
    <h4>{value}</h4>
  ) : (
    <Input
      name={name}
      error={error || ""}
      label={label}
      type={type}
      width="100%"
      noPadding
      value={value || ""}
      handleChange={onChange}
      disabled={!inEditMode}
      multiline={multiline}
      maxRows={maxRows}
      minRows={minRows}
    />
  );
};

interface UserDetailsProps {
  user: User;
}

export const UserDetails: FC<UserDetailsProps> = ({ user }) => {
  const dispatch = useTypedDispatch();
  const rexp = new RegExp(REGEX.IMAGE_URL);
  const [mUser, setMUser] = useState({ ...user });
  const [currentUserVersion, setCurrentUserVersion] = useState({ ...user });
  const [inEditMode, setInEditMode] = useState(false);
  const { token, email } = useTypedSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { vacationsBalance } = useTypedSelector((state: RootState) => state.vacation);

  useEffect(() => {
    triggerRequests();
  }, []);

  const triggerRequests = async () => {
    await fetchAllVacationsForCurrentYear();
  };

  const FORMIK_INIT_VALUES = {
    image: mUser.pictureURL,
    secondaryEmail: mUser.secondaryEmail,
    phoneNumber: mUser.phoneNumber,
    biography: mUser.biography != null ? mUser.biography : "",
  };

  const formik = useFormik({
    initialValues: {
      ...FORMIK_INIT_VALUES,
    },
    onSubmit: () => {},
    validationSchema: userValidation,
  });

  const onClickEdit = (event: any) => {
    event.preventDefault();
    setInEditMode((prev) => {
      return !prev;
    });
  };

  const onChangeInput = (event: any) => {
    setMUser((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value.trim(),
      };
    });
  };

  const handleFormikChange = (event: any) => {
    onChangeInput(event);
    formik.handleChange(event);
  };

  const onClickConfirm = async (event: any) => {
    event.preventDefault();
    formik.setFieldValue("biography", formik.values.biography.trim());
    if (!formik.isValid || !token) return;
    setLoading(true);
    const res = await UserService.patchUser({
      ...mUser,
      pictureURL: formik.values.image,
      biography: formik.values.biography.trim(),
    });

    if (isHttpCodeValid(res.code)) {
      setError("");
      setInEditMode(false);
      setMUser(res.user);
      setCurrentUserVersion(res.user);
    } else {
      setError("Failed to update, try again later.");
    }
    setLoading(false);
  };

  const onClickCancel = (event: any) => {
    event.preventDefault();
    setInEditMode(false);
    resetUserValues();
  };

  const resetUserValues = () => {
    setMUser(currentUserVersion);
    formik.setValues({ ...currentUserVersion, image: currentUserVersion.pictureURL });
  };

  const fetchAllVacationsForCurrentYear = async () => {
    await dispatch(
      getVacationsBalance({
        employeeEmail: email!!,
      })
    );
  };

  const renderBackgroundImage = () => {
    return !inEditMode ? (
      renderBackGroundImageInSeeProfile()
    ) : (
      <ImageInputSquare
        title="Profile Image"
        formik={formik}
        buttonTitle={"Upload your profile image"}
        error={formik.errors.image}
        name="image"
        image={formik.values.image}
        folder="team"
        target="image"
        width="100%"
        height="100%"
        acceptType={null}
      />
    );
  };

  const renderBackGroundImageInSeeProfile = () => {
    return mUser.pictureURL != null ? (
      <img
        className="UserDetails-img"
        src={
          mUser.pictureURL.match(rexp)
            ? mUser.pictureURL.replace(rexp, "=s1500-c")
            : mUser.pictureURL
        }
        alt=""
      />
    ) : (
      <></>
    );
  };

  const renderInfoSection = () => {
    return (
      <div className="infoSection">
        {(mUser.secondaryEmail || mUser.phoneNumber || inEditMode) && (
          <div>
            <h3>Contact(s)</h3>
            {(mUser.secondaryEmail || inEditMode) && (
              <UserDetailsField
                inEditMode={inEditMode}
                value={formik.values.secondaryEmail}
                onChange={handleFormikChange}
                name={FORM_CONFIG.email.name}
                label={FORM_CONFIG.email.label}
                type={FORM_CONFIG.email.type}
                error={formik.errors.secondaryEmail}
              />
            )}
            {(mUser.phoneNumber || inEditMode) && (
              <UserDetailsField
                inEditMode={inEditMode}
                value={formik.values.phoneNumber ? formik.values.phoneNumber.toString() : ""}
                onChange={handleFormikChange}
                name={FORM_CONFIG.phone.name}
                label={FORM_CONFIG.phone.label}
                type={FORM_CONFIG.phone.type}
                error={formik.errors.phoneNumber}
              />
            )}
          </div>
        )}
        {renderUserBio()}
        {renderVacationsSection()}
      </div>
    );
  };

  const renderEditButtons = () => {
    return loading ? (
      <Loading />
    ) : !inEditMode ? (
      <Button text="Edit" onClick={onClickEdit} type={ButtonTypes.Primary} />
    ) : (
      <div className="buttonsContainer">
        <Button text="Cancel" onClick={onClickCancel} type={ButtonTypes.Cancel} />
        <Button text="Confirm" onClick={onClickConfirm} type={ButtonTypes.Primary} />
        <p className="error">{error}</p>
      </div>
    );
  };

  const renderUserBio = () => {
    return (
      <div className="bioContainer">
        {(mUser.biography || inEditMode) && (
          <div className="bioContainer">
            <h3>Biography</h3>
            <UserDetailsField
              inEditMode={inEditMode}
              value={formik.values.biography}
              onChange={handleFormikChange}
              name={FORM_CONFIG.biography.name}
              label={FORM_CONFIG.biography.label}
              type={FORM_CONFIG.biography.type}
              multiline={true}
              maxRows={6}
              error={formik.errors.biography}
            />
          </div>
        )}
      </div>
    );
  };

  const renderVacationsSection = () => (
    <div className="vacationsContainer">
      <div className="UserDetails-column">
        <h3>Available Vacation Days</h3>
        <h4>{vacationsBalance?.available ? vacationsBalance.available : 0}</h4>
      </div>
      <div className="UserDetails-column">
        <h3>Requested Vacations Days</h3>
        <h4>{vacationsBalance?.requested ? vacationsBalance?.requested : 0}</h4>
      </div>
    </div>
  );

  const renderForm = () => (
    <form className="form">
      <div className="UserDetails-container">
        <div className="headerStyle">
          <div className="UserDetails-titleContent">
            <h1 className="UserDetails-title">
              Welcome <span>{mUser.name}</span>
            </h1>
            {renderEditButtons()}
          </div>
        </div>
        <div className="contentContainer">
          <div className="imageContainer">{renderBackgroundImage()}</div>
          {renderInfoSection()}
        </div>
      </div>
    </form>
  );

  return renderForm();
};
