import { Auth } from "../../types/mkt/Auth";
import { emptyEmployee } from "../../data/hr/emptyEmployee";
import { HREmployee } from "../../types/hr/HREmployee";
import {
  apiClient,
  buildConfig,
  getAuthConfig,
  getConfigWithPaginationSettings,
} from "../HttpClient";
import { idText } from "typescript";
import { PageSettings } from "../../types/hr/PageSettings";

const EMPLOYEE_URL = "hr/employee";
const EMPLOYEE_BY_EMAIL = (email: string) => EMPLOYEE_URL + "/" + email;
const EMPLOYEE_BY_ID = (id: string) => EMPLOYEE_URL + "/" + id;

export default class HREmployeeService {
  static getAllEmployees = async (controller: AbortController, pageSettings: PageSettings) => {
    try {
      const response = await apiClient.get(EMPLOYEE_URL, {
        ...getConfigWithPaginationSettings(pageSettings),
        signal: controller.signal,
      });
      const users: HREmployee[] = response.data.content;
      return {
        users,
        code: response.status,
      };
    } catch (err: any) {
      return {
        user: [emptyEmployee],
        code: err.response ? err.response.status : 500,
      };
    }
  };

  static getEmployee = async (email: string) => {
    try {
      const response = await apiClient.get(EMPLOYEE_BY_EMAIL(email), getAuthConfig());
      const user: HREmployee = response.data;
      return {
        user,
        code: response.status,
      };
    } catch (err: any) {
      return {
        user: emptyEmployee,
        code: err.response ? err.response.status : 500,
      };
    }
  };

  static activateEmployee = async (id: string) => {
    try {
      const response = await apiClient.patch(
        EMPLOYEE_BY_ID(id) + "/activate",
        null,
        getAuthConfig()
      );
      const user: HREmployee = response.data;
      return {
        user,
        code: response.status,
      };
    } catch (err: any) {
      return {
        user: emptyEmployee,
        code: err.response ? err.response.status : 500,
      };
    }
  };

  static inactivateEmployee = async (id: string) => {
    try {
      const response = await apiClient.patch(
        EMPLOYEE_BY_ID(id) + "/inactivate",
        null,
        getAuthConfig()
      );
      const user: HREmployee = response.data;
      return {
        user,
        code: response.status,
      };
    } catch (err: any) {
      return {
        user: emptyEmployee,
        code: err.response ? err.response.status : 500,
      };
    }
  };
  static amendEmployee = async (id: string, employee: HREmployee, token: string) => {
    try {
      const response = await apiClient.patch(EMPLOYEE_BY_ID(id), employee, getAuthConfig());
      const user: HREmployee = response.data;
      return {
        user,
        code: response.status,
      };
    } catch (err: any) {
      return {
        user: emptyEmployee,
        code: err.response ? err.response.status : 500,
      };
    }
  };
}
