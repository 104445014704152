import React, { FC, useEffect } from "react";
import { Box, FormHelperText, Typography } from "@mui/material";
import "./CreateProject.css";
import { FormikValues } from "formik";
import { formikTrimStrings } from "../../../../../utils/string.utils";
import { useTypedSelector, RootState, useTypedDispatch } from "../../../../../stores/store";
import { Input } from "../../../../Inputs/Input";
import { InputDate } from "../../../../Inputs/InputDate";
import { ImageInputSquare } from "../../../../ImageInputs/ImageInputSquare/ImageInputSquare";
import { MiniEmployeeAvatarClose } from "../../ProjectCard/avatar/MiniEmployeeAvatarClose";
import { InputAutocomplete } from "../../../Global/input-autocomplete/input-autocomplete";
import { Approver } from "../../../../../types/hr/Approver";
import {
  addChosenApprover,
  getApprovers,
  updateApproversTouched,
} from "../../../../../stores/hr/Project.store";
import { InputSelector } from "../../../../Inputs/InputSelector";
import { ProjectStatus } from "../../../../../constants/hr/ProjectStatus";
import { emptyApprover } from "../../../../../data/hr/approver";

interface PersonalInfoProps {
  formik: FormikValues;
  inEditMode: boolean;
}

export const CreateProject: FC<PersonalInfoProps> = ({ formik, inEditMode }) => {
  const dispatch = useTypedDispatch();
  const { project, loadingApprovers, approvers, approversTouched } = useTypedSelector(
    (state: RootState) => state.projects
  );

  const approverOnChange = (_: any, value: Approver | null) => {
    if (value) {
      dispatch(addChosenApprover(value));
      dispatch(updateApproversTouched(true));
    }
  };

  const approverIsEqual = (option: Approver, value: Approver) => option.id === value.id;
  const approverGetOption = (option: Approver) => option.name;

  useEffect(() => {
    fetchApprovers();
  }, []);

  const fetchApprovers = async () => {
    await dispatch(getApprovers());
  };

  const emptyApproverVal = emptyApprover;

  const statusOptions = [ProjectStatus.OPEN, ProjectStatus.EXPLORATORY, ProjectStatus.CLOSED];

  return (
    <Box className="create-project-form-container">
      <div className="create-project-image-section">
        <ImageInputSquare
          title="Image"
          formik={formik}
          buttonTitle="Upload Logo"
          name="pictureURL"
          image={formik.values.pictureURL}
          error={formik.errors.pictureURL}
          target="image"
          folder="project"
          height="60vh"
          width="20vw"
          acceptType={null}
          backgroundColor="#1F2632"
          backgroundColorHover="#203353"
          border="2px solid #676CDB"
          borderRadius="15px"
          colorContent="white"
          closeIconBackgroundColor="#3AAFC9"
        />
      </div>
      <div className="create-project-form-container-right">
        <div className="create-project-row">
          <div className="create-project-input-field">
            <Input
              error={!formik.touched.name ? "" : formik.errors?.name}
              value={formik.values.name}
              handleChange={formik.handleChange}
              handleBlur={(e) => formikTrimStrings(formik, e)}
              label="Project Name"
              name="name"
              type="text"
              width="80%"
              height="2rem"
              backgroundColor="white"
              borderRadius="16px"
              errorColor="#E94040"
            />
          </div>
          <div className="create-project-input-field">
            {inEditMode ? (
              <Box
                sx={{
                  padding: "1em",
                  width: "80%",
                  height: "auto",
                }}
              >
                <Typography variant="body1" sx={{ color: "white" }}>
                  WBS Code
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    fontWeight: "700",
                    fontSize: "26px",
                    lineHeight: "28px",
                    marginTop: ".4em",
                  }}
                >
                  {project.wbs}
                </Box>
              </Box>
            ) : (
              <Input
                error={!formik.touched.wbs ? "" : formik.errors?.wbs}
                value={formik.values.wbs}
                handleChange={formik.handleChange}
                handleBlur={(e) => formikTrimStrings(formik, e)}
                label="WBS Code"
                name="wbs"
                type="text"
                width="80%"
                height="2rem"
                backgroundColor="white"
                borderRadius="16px"
                errorColor="#E94040"
              />
            )}
          </div>
          {inEditMode ? (
            <div className="create-project-input-field">
              <InputSelector
                error={""}
                label="Status"
                value={formik.values.status}
                handleChange={formik.handleChange}
                height="auto"
                target="status"
                emptyValue=""
                emptyLabel=""
                values={statusOptions}
                padding="1em"
                maxWidth="70%"
                backgroundColor="white"
                borderRadius="16px"
                multiple={false}
              />
            </div>
          ) : null}
        </div>
        <div className="create-project-row">
          <div className="create-project-input-field-startDate">
            <InputDate
              error={!formik.touched.startDate ? "" : formik.errors?.startDate}
              value={formik.values.startDate}
              handleChange={formik.handleChange}
              label="Start Date"
              name={"startDate"}
              width="80%"
              backgroundColor="white"
              borderRadius="16px"
              errorColor="#E94040"
              inputFormat="dd MMMM yyyy"
              disableMask
            />
          </div>
          <div className="create-project-input-field">
            <Input
              error={!formik.touched.currency ? "" : formik.errors?.currency}
              value={formik.values.currency}
              handleChange={formik.handleChange}
              handleBlur={(e) => formikTrimStrings(formik, e)}
              label="Currency"
              name="currency"
              type="text"
              width="80%"
              height="2rem"
              backgroundColor="white"
              borderRadius="16px"
              errorColor="#E94040"
            />
          </div>
          <div className="create-project-input-field">
            <InputDate
              error={!formik.touched.billingCycle ? "" : formik.errors?.billingCycle}
              value={formik.values.billingCycle}
              handleChange={formik.handleChange}
              label="Billing Cycle"
              name={"billingCycle"}
              width="80%"
              backgroundColor="white"
              borderRadius="16px"
              errorColor="#E94040"
              inputFormat="dd"
            />
          </div>
        </div>
        <div className="create-project-row">
          <Input
            error={!formik.touched.description ? "" : formik.errors?.description}
            value={formik.values.description}
            handleChange={formik.handleChange}
            handleBlur={(e) => formikTrimStrings(formik, e)}
            label="Description"
            name="description"
            type="text"
            multiline
            maxRows={6}
            width="100%"
            height="9em"
            placeholder="Brief description of the project"
            backgroundColor="white"
            borderRadius="16px"
          />
        </div>
        <div className="create-project-row">
          <Box
            sx={{
              width: "auto",
              height: "auto",
            }}
          >
            <InputAutocomplete
              id="create-project-approvers"
              options={approvers ? [...approvers, emptyApproverVal] : []}
              loading={loadingApprovers}
              value={emptyApproverVal}
              onChange={approverOnChange}
              label="Approvers"
              isEqualToValue={approverIsEqual}
              getOptionLabel={approverGetOption}
            />
            {approversTouched && !project.approvers.length && (
              <FormHelperText
                style={{
                  color: "#E94040",
                  marginLeft: "1rem",
                }}
              >
                It is required to have at least one Approver
              </FormHelperText>
            )}
          </Box>
        </div>
        <div className="create-project-row-approvers">
          {project.approvers?.map((approver, index) => (
            <MiniEmployeeAvatarClose
              key={`create-project-approver-avatar-${index}`}
              avatar={approver}
            />
          ))}
        </div>
      </div>
    </Box>
  );
};
