import { Box, TablePagination, Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { FC } from "react";
import "./VacationsSummaryTable.css";
import {
  getSubmissionStatusFromString,
  getSubmissionStatusText,
  SubmissionStatus,
} from "../../../../../data/common/SubmissionStatus";
import { dateToDDMMYYYY } from "../../../../../utils/date";
import { API_REQUEST_SETTINGS } from "../../../../../constants/internal/apiRequestSettings.const";
import { VacationPaginated } from "../../../../../types/internal/vacation/VacationPaginated";
import { Vacation } from "../../../../../types/internal/vacation/Vacation";
import { Approver } from "../../../../../types/hr/Approver";
import { useTypedDispatch } from "../../../../../stores/store";
import { deleteVacation } from "../../../../../stores/internal/Vacation.store";
import { MiniEmployeeAvatar } from "../../../User/UserDetails/MiniEmployeeAvatar";

interface VacationsSummaryTableProps {
  vacationsPaginated: VacationPaginated;
  tablePage: number;
  onPaginationChange: (newPage: number) => void;
  onRefresh: () => void;
}

export const VacationsSummaryTable: FC<VacationsSummaryTableProps> = ({
  vacationsPaginated,
  tablePage,
  onPaginationChange,
  onRefresh,
}) => {
  const dispatch = useTypedDispatch();
  const getClassNameForSubmissionStatus = (status: string) => {
    switch (getSubmissionStatusFromString(status)) {
      case SubmissionStatus.SUBMITTED:
        return "VacationsSummaryTable-statusDefault";
      case SubmissionStatus.APPROVED:
        return "VacationsSummaryTable-statusApproved";
      case SubmissionStatus.REJECTED:
        return "VacationsSummaryTable-statusRejected";
      default:
        return "VacationsSummaryTable-statusDefault";
    }
  };

  const areThereVacationsToShow = () => {
    return vacationsPaginated && vacationsPaginated.vacations.length > 0;
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPaginationChange(newPage);
  };

  function handleDeleteButton(email: string, id: string | null) {
    return () => handleDelete(email, id);
  }

  async function handleDelete(email: string, id: string | null) {
    await dispatch(deleteVacation({ email: email!!, id: id!! }));
    onRefresh();
  }

  const getTextToDisplayDates = (vacation: Vacation) => {
    return (
      dateToDDMMYYYY(new Date(vacation.startDate)) +
      " to " +
      dateToDDMMYYYY(new Date(vacation.endDate))
    );
  };
  return (
    <div>
      <div className="VacationsSummaryTable-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {vacationsPaginated && vacationsPaginated.totalPages > 1 && (
            <TablePagination
              component="div"
              count={vacationsPaginated ? vacationsPaginated.totalElements : 0}
              page={tablePage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[]}
              rowsPerPage={API_REQUEST_SETTINGS.GET_VACATIONS_TABLE_SIZE}
              labelDisplayedRows={({}) => {
                return ``;
              }}
              onRowsPerPageChange={() => {}}
              sx={{ display: "flex", width: "50%", justifyContent: "end" }}
            />
          )}
        </Box>
      </div>
      {areThereVacationsToShow() && (
        <div className="VacationsSummaryTable-tableContainer">
          <table className="VacationsSummaryTable-table">
            <thead>
              <tr className="VacationsSummaryTable-headRow">
                <th>Period</th>
                <th>Days</th>
                <th>Status</th>
                <th>By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {vacationsPaginated.vacations
                ? vacationsPaginated.vacations.map((vacation, index) => {
                    return (
                      <tr
                        key={index}
                        className={
                          index % 2 == 0
                            ? "VacationsSummaryTable-tableRowOdd"
                            : "VacationsSummaryTable-tableRowEven"
                        }
                      >
                        <td>{getTextToDisplayDates(vacation)}</td>
                        <td>{vacation.total}</td>
                        <td className={getClassNameForSubmissionStatus(vacation.status)}>
                          {getSubmissionStatusText(vacation.status)}
                        </td>
                        <td>
                          {vacation.approvedBy && (
                            <MiniEmployeeAvatar
                              url={
                                vacation.approvedBy && vacation.approvedBy.pictureURL != null
                                  ? vacation.approvedBy.pictureURL
                                  : null
                              }
                              email={vacation.approvedBy.email}
                            />
                          )}
                        </td>{" "}
                        <td className="VacationsSummaryTable-deleteRow">
                          <Tooltip
                            title="Delete Row"
                            onClick={handleDeleteButton(vacation.employee, vacation.id)}
                          >
                            <IconButton className="VacationsSummaryTable-deleteRow">
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </div>
      )}
      {!areThereVacationsToShow() && (
        <div className="VacationsSummaryTable-noContentText">No vacations to show</div>
      )}
    </div>
  );
};
