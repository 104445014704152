import { Box, Tooltip, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement, useState } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { TopBar } from "../../../TopBar/TopBar";
import { useNavigate } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { RootState, useTypedSelector } from "../../../../stores/store";
import { PageCreateContent } from "../../../../types/mkt/PageContent";
import { renderHtml } from "../../../../utils/renderHtml";
import "./Page.css";
import { HomeKodlyIcon } from "../../../../assets/svg/HomeKodlyIcon";

interface PageCreateProps {
  children: ReactElement | null;
  pageContent: PageCreateContent;
  hrMode?: boolean;
}

export const PageCreate: FC<PageCreateProps> = observer(({ children, pageContent, hrMode }) => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);

  const isNotHRMode = () => {
    return hrMode == undefined || hrMode == false;
  };

  const getPageWidth = () => {
    if (!isNotHRMode()) {
      return "100%";
    }
    return open ? "calc(100% - 225px)" : "calc(100% - 75px)";
  };

  const getTopBarElementClassName = (index: number) => {
    return activeTab == index
      ? isNotHRMode()
        ? "InternalPage-menuSwitch_activeElement"
        : "InternalPage-HRmenuSwitch_activeElement"
      : isNotHRMode()
      ? "InternalPage-menuSwitch_element"
      : "InternalPage-HRmenuSwitch_element";
  };

  const getDashlineClassName = () => {
    return isNotHRMode() ? "InternalPage-dashLine" : "InternalPage-HRdashLine";
  };

  return (
    <Box display="flex" sx={{ height: "100vh" }}>
      {isNotHRMode() && (
        <Box sx={{ height: "100vh", width: open ? "225px" : "75px" }}>
          <div className="Home-kodlyIcon">
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "flex-start",
                px: 2,
              }}
            >
              {open ? (
                <img
                  src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/homeKodly_1660662061.png"
                  alt="Logo"
                  width="175px"
                  onClick={() => navigate("/")}
                />
              ) : (
                <div onClick={() => navigate("/")}>
                  <HomeKodlyIcon />
                </div>
              )}
            </ListItemIcon>
          </div>
          <Sidebar />
        </Box>
      )}
      <Box
        sx={{
          width: getPageWidth(),
          backgroundColor: isNotHRMode() ? "white" : "#1F2632",
        }}
      >
        <TopBar>
          <Box alignItems="center" sx={{ flexGrow: 1, display: { xs: "flex" } }}>
            {pageContent.switchTabAction == null || pageContent.tabs == null ? (
              <div className="InternalPage-menuSwitch_container">
                <h2
                  className="InternalPage-title"
                  dangerouslySetInnerHTML={renderHtml(pageContent.pageTitle)}
                ></h2>
                <Tooltip title="Cancel" color="error" sx={{ marginRight: "0.5em" }}>
                  <IconButton onClick={() => navigate(pageContent.previousPage)}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={pageContent.type == "Create" ? "Create" : "Confirm"}
                  sx={{ color: "#00b088", marginRight: "3em" }}
                >
                  <IconButton
                    onClick={() => {
                      pageContent.action();
                    }}
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (
              <div
                className="InternalPage-menuSwitch_container"
                style={{ justifyContent: "space-between" }}
              >
                <div className="PageCreate-tabs" style={{ display: "contents" }}>
                  {pageContent.tabs.map((tab, index) => (
                    <Box
                      key={index}
                      onClick={() => {
                        pageContent.switchTabAction(index);
                        setActiveTab(index);
                      }}
                    >
                      {pageContent.errorTabs != null && pageContent.errorTabs.includes(index) ? (
                        <Box
                          className={
                            activeTab == index
                              ? "InternalPage-menuSwitch_activeErrorElement"
                              : "InternalPage-menuSwitch_errorElement"
                          }
                        >
                          {tab}
                          {activeTab == index ? (
                            <div className="InternalPage-dashLineError" />
                          ) : (
                            <div />
                          )}
                        </Box>
                      ) : (
                        <Box className={getTopBarElementClassName(index)}>
                          {tab}
                          {activeTab == index ? (
                            <div className={getDashlineClassName()} />
                          ) : (
                            <div />
                          )}
                        </Box>
                      )}
                    </Box>
                  ))}
                </div>
                <div style={{ display: "flex", marginRight: "4.5em", marginLeft: "2.5em" }}>
                  <Tooltip className="PageCreate-cancelTooltip" title="Cancel" color="error">
                    <IconButton onClick={() => navigate(pageContent.previousPage)}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    className="PageCreate-confirmTooltip"
                    title={pageContent.type == "Create" ? "Create" : "Confirm"}
                    sx={{ color: "#00b088", marginLeft: "0.5em" }}
                  >
                    <IconButton
                      onClick={() => {
                        pageContent.action();
                      }}
                    >
                      <CheckCircleIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
          </Box>
        </TopBar>
        <Box
          sx={{
            padding: "2em 4em",
            height: "calc(100vh - (92px + 4em))",
            overflowY: isNotHRMode() ? "auto" : "",
            boxSizing: "content-box",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
});
