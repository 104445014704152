import React, { FC, useRef } from "react";
import { Box, Button } from "@mui/material";
import { CVLanguage } from "./CVLanguage/CVLanguage";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormikValues } from "formik";
import { Language } from "../../../../types/internal/cv/Language";
import {
  LANGUAGES,
  LANGUAGE_LEVELS,
  LanguagesDropdown,
} from "../../../../constants/languages.const";
import { sortObjASC } from "../../../../utils/sort.utils";
import "./CVLanguages.css";

interface CVLanguages {
  formik: FormikValues;
  handleInputChange: (event: any) => {};
  hrStyle?: any;
}

export const CVLanguages: FC<CVLanguages> = ({ formik, handleInputChange, hrStyle }) => {
  const languages = sortObjASC(LANGUAGES, "name").map((lang: LanguagesDropdown) => lang.name);
  const notMappedValues = useRef<string[]>([]);
  let formikLanguages = [...formik.values.languages];
  const addLanguage = () => {
    formikLanguages = [...formik.values.languages, { language: "", level: "" }];
    formik.setValues(
      {
        ...formik.values,
        languages: formikLanguages,
      },
      true
    );
    notMappedValues.current = [...getActualLanguages()];
  };

  const handleDelete = (index: number) => {
    let aux = [...formik.values.languages];
    aux.splice(index, 1);
    formik.setFieldValue("languages", aux);
    formikLanguages = aux;
    notMappedValues.current = [...getActualLanguages()];
  };

  const getActualLanguages = () => {
    let aux: string[] = [];
    for (const language of formik.values.languages)
      if (language.language !== "") aux.unshift(language.language);
    return aux;
  };

  const showHRStyle = () => {
    return hrStyle != undefined && hrStyle != null;
  };

  notMappedValues.current = [...getActualLanguages()];
  return (
    <Box id="container" className="CVLanguages-formContainer">
      <Box display={"flex"} justifyContent="center">
        <Button
          sx={{
            backgroundColor: !showHRStyle() ? "white" : "#1F2632",
            color: !showHRStyle() ? "black" : "white",
            border: !showHRStyle() ? "1px solid black" : "1px solid #676CDB",
            margin: "0em 0em 2em 0em",
            "&:hover": {
              backgroundColor: hrStyle?.buttonHoverColor,
              color: hrStyle?.inputLabelColor,
            },
          }}
          endIcon={<AddCircleOutlineIcon />}
          onClick={addLanguage}
        >
          Add a new Language
        </Button>
      </Box>
      <Box className="CVLanguages-dropdowns">
        {formikLanguages.map((language: Language, index: number) => (
          <CVLanguage
            formik={formik}
            title={formik.values.languages[index]?.language}
            key={index}
            index={index}
            length={formikLanguages.length}
            handleDelete={handleDelete}
            languages={languages}
            languageLevels={Object.values(LANGUAGE_LEVELS)}
            notMappedLanguages={notMappedValues.current}
            handleInputChange={handleInputChange}
            hrStyle={hrStyle}
          />
        ))}
      </Box>
    </Box>
  );
};
