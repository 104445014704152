import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../components/internal/Global/Pages/Page";
import { VacationsSummary } from "../../../components/internal/Vacations/VacationsSummary/VacationsSummary";
import { API_REQUEST_SETTINGS } from "../../../constants/internal/apiRequestSettings.const";
import { getEmployeeDetails } from "../../../stores/internal/User.store";
import {
  getVacationFrom,
  getVacationsBalance,
  setCreated,
} from "../../../stores/internal/Vacation.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { dateToYYYMMDD, getFirstDayOfYear, getLastDayOfNextYear } from "../../../utils/date";

export var vacationsPreviousTablePage = 0;

export const VacationsPage: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { email } = useTypedSelector((state: RootState) => state.auth);
  const { vacationsPaginated, vacationsBalance } = useTypedSelector(
    (state: RootState) => state.vacation
  );
  const [tablePage, setTablePage] = React.useState(0);
  const [loadedAssignedVacations, setLoadedAssignedVacations] = useState(false);
  const [loadedVacationsPaginated, setLoadedVacationsPaginated] = useState(false);
  const [loadedBalance, setLoadedBalance] = useState(false);

  useEffect(() => {
    resetLoaded();
    triggerRequests();
  }, []);

  useEffect(() => {
    triggerRequestsForPaginatedVacations();
  }, [tablePage]);

  const resetLoaded = () => {
    setCreated(false);
    setLoadedAssignedVacations(false);
    setLoadedVacationsPaginated(false);
    setLoadedBalance(false);
  };

  const triggerRequests = async () => {
    await fetchUserTotalVacationsSummary();
    await fetchVacationsPaginated();
    await fetchVacationsBalance();
  };

  const triggerRequestsForPaginatedVacations = async () => {
    if (checkIsNotStarterPageFirstVisualization()) {
      await fetchVacationsPaginated();
    }
  };

  const checkIsNotStarterPageFirstVisualization = () => {
    return tablePage != 0 || vacationsPreviousTablePage > tablePage;
  };

  const fetchUserTotalVacationsSummary = async () => {
    await dispatch(
      getEmployeeDetails({
        email: email!!,
      })
    ).then(() => {
      setLoadedAssignedVacations(true);
    });
  };

  const fetchVacationsPaginated = async () => {
    await dispatch(
      getVacationFrom({
        employeeEmail: email!!,
        startDate: dateToYYYMMDD(getFirstDayOfYear()),
        endDate: dateToYYYMMDD(getLastDayOfNextYear()),
        page: tablePage,
        size: API_REQUEST_SETTINGS.GET_VACATIONS_TABLE_SIZE,
      })
    ).then(() => {
      setLoadedVacationsPaginated(true);
    });
  };

  const fetchVacationsBalance = async () => {
    await dispatch(
      getVacationsBalance({
        employeeEmail: email,
      })
    ).then(() => {
      setLoadedBalance(true);
    });
  };

  const navigateToCreateVacation = () => {
    navigate(`/vacations/create`);
  };

  const onPaginationChange = (newPage: number) => {
    const numberOfItemsPerPage = API_REQUEST_SETTINGS.GET_VACATIONS_TABLE_SIZE;
    if (newPage > tablePage && vacationsPaginated.vacations.length < numberOfItemsPerPage) {
      return;
    }
    vacationsPreviousTablePage = tablePage;
    setTablePage(newPage);
  };

  return (
    <Page pageContent={null} backgroundColor={"#fff"}>
      {loadedAssignedVacations && loadedVacationsPaginated && loadedBalance ? (
        <VacationsSummary
          vacationsPaginated={vacationsPaginated}
          navigateToCreateVacation={navigateToCreateVacation}
          onPaginationChange={onPaginationChange}
          tablePage={tablePage}
          vacationsBalance={vacationsBalance}
        />
      ) : null}
    </Page>
  );
};
