import React from "react";
import { SidebarItem } from "../../types/SidebarItem";
import { Employees } from "../../assets/svg/Employees";
import { Projects } from "../../assets/svg/Projects";

export const sidebarItems: SidebarItem[] = [
  {
    text: "Employees",
    icon: <Employees />,
  },
  {
    text: "Projects",
    icon: <Projects />,
  },
];
