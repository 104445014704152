import React, { FC } from "react";
import "./ProjectDetailsTeamMembers.css";
import { Project } from "../../../../../types/hr/Project";
import { TeamMembersTableRow } from "../../ProjectCreate/TeamMembers/TableRow/TeamMembersTableRow";

interface ProjectDetailsTeamMembersProps {
  project: Project;
  currency: string;
}

export const ProjectDetailsTeamMembers: FC<ProjectDetailsTeamMembersProps> = ({
  project,
  currency,
}) => {
  const activeMembers = project.teamMembers.filter((member) => member.active);
  const inactiveMembers = project.teamMembers.filter((member) => !member.active);
  return (
    <div className="project-details-members-main-section">
      <div className="project-details-members-active">
        <div className="project-details-members-title">Active Members</div>
        {activeMembers.length === 0 ? (
          <div>This project currently has no active members</div>
        ) : (
          <div className="project-details-members-table-row">
            <div className="project-details-members-column-header">Name</div>
            <div className="project-details-members-column-header">Role</div>
            <div className="project-details-members-column-header">Type</div>
            <div className="project-details-members-column-header">Rate</div>
          </div>
        )}
        {activeMembers.map((member, index) => (
          <div key={`project-details-table-member-${index}`}>
            <TeamMembersTableRow currency={currency} member={member} index={index} inViewMode />
          </div>
        ))}
      </div>
      <div className="project-details-members-old">
        <div className="project-details-members-title">Inactive Members</div>
        {inactiveMembers.length === 0 ? (
          <div>This project currently has no inactive members</div>
        ) : (
          <div className="project-details-members-table-row">
            <div className="project-details-members-column-header">Name</div>
            <div className="project-details-members-column-header">Role</div>
            <div className="project-details-members-column-header">Type</div>
            <div className="project-details-members-column-header">Rate</div>
          </div>
        )}
        {inactiveMembers.map((member, index) => (
          <div key={`project-details-table-member-${index}`}>
            <TeamMembersTableRow currency={currency} member={member} index={index} inViewMode />
          </div>
        ))}
      </div>
    </div>
  );
};
