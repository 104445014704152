import { useEffect, useState } from "react";
import axios, { Axios, Canceler } from "axios";
import HREmployeeService from "../services/hr/HREmployee.service";
import { isHttpCodeValid } from "../utils/common/http";
import { HREmployee } from "../types/hr/HREmployee";
import { PageSettings } from "../types/hr/PageSettings";

export default function useBookSearch(pageSettings: PageSettings) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [employees, setEmployees] = useState<Array<HREmployee>>([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    const controller = new AbortController();
    getAllHREmployees(controller);
    return () => controller.abort();
  }, [pageSettings.page]);

  const getAllHREmployees = async (controller: AbortController) => {
    const res = await HREmployeeService.getAllEmployees(controller, pageSettings);
    if (isHttpCodeValid(res.code)) {
      if (res.users) {
        setEmployees([...employees, ...res.users]);
        setHasMore(res.users.length > 0);
        setLoading(false);
      } else {
        setError(true);
      }
    }
  };

  return { loading, error, employees, hasMore };
}
