import React, { FC, useEffect, useMemo, useState } from "react";
import { List, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { SidebarItem } from "../../../mkt/Global/Sidebar/SidebarItem";
import { CollapseRight } from "../../../../assets/svg/CollapseRight";
import { CollapseLeft } from "../../../../assets/svg/CollapseLeft";
import { Person } from "../../../../assets/svg/Person";
import { sidebarItems } from "../../../../data/internal/sidebar";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { changeOpen } from "../../../../stores/Sidebar.store";
import { logout } from "../../../../stores/Authentication.store";
import { useNavigate } from "react-router-dom";
import { Finances } from "../../../../assets/svg/Finances";
import { KodlyIo } from "../../../../assets/svg/KodlyIo";
import { Hr } from "../../../../assets/svg/Hr";
import { FINANCE_ROLES, HR_ROLES, KODLY_IO_ROLES } from "../../../../constants/roles";
import LogoutIcon from "@mui/icons-material/Logout";
import "./Sidebar.css";
import { REACT_APP_SHOW_HR_FINANCE } from "../../../../App";
import { SidebarButton } from "../../../common/sidebar/SidebarButton";

export const Sidebar: FC = () => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const { name } = useTypedSelector((state: RootState) => state.auth);
  const { roles } = useTypedSelector((state: RootState) => state.user.user);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [hasFinancesAccess, setHasFinancesAccess] = useState(false);
  const [hasHRAccess, setHasHRAccess] = useState(false);
  const [hasKodlyIoAccess, setHasKodlyIoAccess] = useState(false);

  useEffect(() => {
    setHasFinancesAccess(Boolean(roles.find((role) => FINANCE_ROLES.includes(role))));
    setHasHRAccess(Boolean(roles.find((role) => HR_ROLES.includes(role))));
    setHasKodlyIoAccess(Boolean(roles.find((role) => KODLY_IO_ROLES.includes(role))));
  }, [roles]);

  const checkSitesAcess = () => hasFinancesAccess || hasHRAccess || hasKodlyIoAccess;
  const showExtraSites = useMemo(
    () => checkSitesAcess(),
    [hasFinancesAccess, hasHRAccess, hasKodlyIoAccess]
  );

  const shouldShowExtraSites = () => {
    return REACT_APP_SHOW_HR_FINANCE.toUpperCase() === "TRUE" && showExtraSites;
  };

  const shouldDisplaySidebarItem = (role: string | undefined) => {
    return !role || (role && roles.includes(role));
  };

  return (
    <div className="Home-sidebar">
      <List>
        {sidebarItems.map(
          (item, index) =>
            shouldDisplaySidebarItem(item.requiredRole) && <SidebarItem key={index} item={item} />
        )}
        <Box margin="10px" sx={{ borderBottom: "1px solid #FFFFFF" }}></Box>
        <Box>
          {open ? (
            <Typography color="#FFFFFF" padding="0em 1em">
              Extra Websites
            </Typography>
          ) : null}
          {shouldShowExtraSites() && hasFinancesAccess && (
            <SidebarButton title={"Finance"} onClick={() => {}} icon={<Finances />} open={open} />
          )}
          {hasKodlyIoAccess && (
            <SidebarButton
              title={"Kodly.io"}
              onClick={() => navigate("/careers")}
              icon={<KodlyIo />}
              open={open}
            />
          )}
          {shouldShowExtraSites() && hasHRAccess && (
            <SidebarButton
              title={"HR"}
              onClick={() => navigate("/employees")}
              icon={<Hr />}
              open={open}
            />
          )}
        </Box>
      </List>
      <List className="bottomList">
        <SidebarButton
          title={"Collapse"}
          onClick={() => dispatch(changeOpen(!open))}
          icon={open ? <CollapseLeft /> : <CollapseRight />}
          open={open}
        />
        <SidebarButton
          title={name}
          onClick={() => navigate("/profile")}
          icon={<Person />}
          open={open}
        />
        <SidebarButton
          title={"Logout"}
          onClick={() => dispatch(logout({}))}
          icon={<LogoutIcon />}
          open={open}
        />
      </List>
    </div>
  );
};
