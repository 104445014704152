import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import React, { FC } from "react";
import { Training } from "../../../../../types/internal/Training";
import "./TrainingDetail.css";

interface TrainingDetailProps {
  training?: Training;
  onBack: () => void;
}

const styles = {
  icon: {
    fontSize: "18px",
    border: "3px solid #3aafc9",
    borderRadius: "100%",
    padding: "6px",
    color: "white",
  },
};

export const TrainingDetail: FC<TrainingDetailProps> = ({ training, onBack }) => {
  const renderHeader = () => (
    <div className="header-container" onClick={onBack}>
      <ArrowBackIosNewOutlined sx={styles.icon} />
      <div className="header-title">Training List</div>
    </div>
  );

  const renderInfo = () => (
    <div className="info-container">
      <div className="header-description-container">
        <div className="header-description">
          <div className="header">Name</div>
          <div className="description">{training!.description}</div>
        </div>
        <div className="header-description">
          <div className="header">Year</div>
          <div className="description">{training!.year}</div>
        </div>
        <div className="header-description">
          <div className="header">Hours</div>
          <div className="description">{training!.hours} h </div>
        </div>
      </div>
      <div className="certificate-container">
        <div className="header">Certificate</div>
        <div className="description-certificate">{training!.attachment} </div>
      </div>
    </div>
  );

  return (
    <div className="Training-container">
      {renderHeader()}
      {training && renderInfo()}
    </div>
  );
};
