import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyProject } from "../../data/hr/project";
import ProjectService from "../../services/hr/Project.service";
import { PageSettings } from "../../types/hr/PageSettings";
import { Project } from "../../types/hr/Project";
import { ProjectState } from "../../types/hr/ProjectState";
import { ProjectTeamMember } from "../../types/hr/ProjectTeamMember";
import { WorkType } from "../../types/hr/WorkType";
import { isHttpCodeValid } from "../../utils/common/http";

const initialState = {
  loading: true,
  projects: [],
  project: emptyProject,
  error: null,
  created: false,
  edited: false,
  deleted: false,
  publishChange: false,
  approvers: [],
  approversTouched: false,
  loadingApprovers: false,
  teamMembers: [],
  teamMembersTouched: false,
  loadingTeamMembers: false,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState: { ...initialState } as ProjectState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setPublishState(state, action) {
      state.publishChange = action.payload;
    },
    setProjects(state, action) {
      state.projects = action.payload;
    },
    addChosenApprover(state, action) {
      if (
        state.project.approvers &&
        state.project.approvers.find((approver) => approver.id === action.payload.id)
      )
        return;

      if (state.project.approvers)
        state.project.approvers = [...state.project.approvers, action.payload];
      else state.project.approvers = [action.payload];

      state.approversTouched = true;
    },
    removeChosenApprover(state, action) {
      if (state.project.approvers) {
        state.project.approvers = state.project.approvers.filter(
          (approver) => approver.id !== action.payload
        );
      }
    },
    addTeamMember(state, action) {
      if (
        state.project.teamMembers &&
        state.project.teamMembers.find((member) => member.id === action.payload.id)
      )
        return;
      if (state.project.teamMembers) {
        state.project.teamMembers = [...state.project.teamMembers, action.payload];
      } else {
        state.project.teamMembers = [action.payload];
      }
    },
    removeChosenTeamMember(state, action) {
      if (state.project.teamMembers) {
        state.project.teamMembers = state.project.teamMembers.filter(
          (member) => member.id !== action.payload
        );
      }
    },
    updateTeamMember(state, action) {
      if (state.project.teamMembers && action.payload?.id) {
        const index = state.project.teamMembers.findIndex(
          (member) => member.id === action.payload.id
        );
        state.project.teamMembers[index] = action.payload;
      }
    },
    resetProject(state) {
      state.project = emptyProject;
      state.approversTouched = false;
      state.teamMembersTouched = false;
    },
    updateApproversTouched(state, action) {
      state.approversTouched = action.payload;
    },
    updateTeamMembersTouched(state, action) {
      state.teamMembersTouched = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects = action.payload;
      state.loading = false;
    });
    builder.addCase(getProjects.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProject.fulfilled, (state, action) => {
      state.project = action.payload;
      state.loading = false;
    });
    builder.addCase(getProject.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateProject.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.loading = false;
      state.project = action.payload.project;
    });
    builder.addCase(updateProject.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getApprovers.pending, (state) => {
      state.loadingApprovers = true;
    });
    builder.addCase(getApprovers.fulfilled, (state, action) => {
      state.approvers = action.payload;
      state.loadingApprovers = false;
    });
    builder.addCase(getApprovers.rejected, (state) => {
      state.loadingApprovers = false;
    });
    builder.addCase(getTeamMembers.pending, (state) => {
      state.loadingTeamMembers = true;
    });
    builder.addCase(getTeamMembers.fulfilled, (state, action) => {
      const teamMembers: ProjectTeamMember[] = action.payload.map((employee) => ({
        id: employee.id,
        name: employee.name,
        pictureURL: employee.pictureURL,
        email: employee.email,
        role: "",
        dailyCost: 0,
        workType: WorkType.FULLTIME,
        dailyRate: 0,
        active: true,
      }));
      state.teamMembers = teamMembers;
      state.loadingTeamMembers = false;
    });
    builder.addCase(getTeamMembers.rejected, (state) => {
      state.loadingTeamMembers = false;
    });
    builder.addCase(createProject.pending, (state, _) => {
      state.loading = true;
      state.created = false;
      state.edited = false;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.loading = false;
      state.created = true;
      state.edited = true;
      state.error = null;
      if (isHttpCodeValid(action.payload.code)) state.project = emptyProject;
    });
    builder.addCase(createProject.rejected, (state, _) => {
      state.loading = false;
      state.error = "Error Occured";
    });
  },
});

export const getProjectsByUser = createAsyncThunk(
  "projects/getProjects",
  async (data: { pageSettings: PageSettings; employeeEmail: string; token: string }) => {
    return (
      await ProjectService.getProjectsByUser(data.pageSettings, data.employeeEmail, data.token)
    ).projects;
  }
);
export const getProjects = createAsyncThunk("projects/getProjects", async () => {
  return (await ProjectService.getProjects()).projects;
});

export const getProject = createAsyncThunk("projects/getProject", async (wbs: string) => {
  return (await ProjectService.getProject(wbs)).project;
});

export const updateProject = createAsyncThunk(
  "projects/updateProject",
  async (project: Project) => {
    return await ProjectService.updateProject(project);
  }
);

export const getApprovers = createAsyncThunk("projects/getApprovers", async () => {
  return (await ProjectService.getEmployeesCoreInfo()).employees;
});

export const getTeamMembers = createAsyncThunk("projects/getTeamMembers", async () => {
  return (await ProjectService.getEmployeesCoreInfo()).employees;
});

export const createProject = createAsyncThunk(
  "projects/createProject",
  async (project: Project) => {
    return await ProjectService.createProject(project);
  }
);

export default projectsSlice.reducer;
export const {
  setCreated,
  setEdited,
  setDeleted,
  setError,
  setProjects,
  addChosenApprover,
  removeChosenApprover,
  addTeamMember,
  removeChosenTeamMember,
  updateTeamMember,
  resetProject,
  updateApproversTouched,
  updateTeamMembersTouched,
} = projectsSlice.actions;
