import React, { FC, useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { InputDate } from "../../../../Inputs/InputDate";
import { InputSelector } from "../../../../Inputs/InputSelector";
import { dateToYYYMMDD, differenceBetweenDates } from "../../../../../utils/date";
import { MiniEmployeeMultiAvatar } from "../../../User/UserDetails/MiniEmployeeMultiAvatar";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../../stores/store";
import { Approver } from "../../../../../types/hr/Approver";
import { DAY_OF_WEEK_INDEX } from "../../../../../constants/dates.const";
import { VacationPaginated } from "../../../../../types/internal/vacation/VacationPaginated";
import VacationService from "../../../../../services/internal/Vacation.service";
import { isHttpCodeValid } from "../../../../../utils/common/http";
import { Vacation } from "../../../../../types/internal/vacation/Vacation";
import "./VacationsCreateForm.css";

interface VacationsCreateFormProps {
  formik: any;
  pageTitle: string;
  onVacationsDaysSelectedChange: (selectedDays: number) => void;
  maxVacationsAvailable: number;
  overlappingVacationErrorShow: boolean;
}

export const VacationsCreateForm: FC<VacationsCreateFormProps> = ({
  formik,
  pageTitle,
  onVacationsDaysSelectedChange,
  maxVacationsAvailable,
  overlappingVacationErrorShow,
}) => {
  const dispatch = useTypedDispatch();
  const { email } = useTypedSelector((state: RootState) => state.auth);
  const [vacationsByMonth, setVacationsByMonth] = useState<Array<Vacation>>([]);
  const { approvers } = useTypedSelector((state: RootState) => state.approvers);
  const approverList = () => {
    let name: string[] = [];
    approvers.map((approver, index) => {
      name.push(approver.name);
    });
    return name;
  };
  const APPROVERS = approverList();

  useEffect(() => {
    getVacationsByUser();
  }, []);

  useEffect(() => {
    computeTotalSelectedVacationsDay();
  }, [formik.values.startDate, formik.values.endDate]);

  const computeTotalSelectedVacationsDay = () => {
    const totalSelectedDays = differenceBetweenDates(
      new Date(formik.values.startDate),
      new Date(formik.values.endDate)
    ).length;
    formik.values.total = totalSelectedDays;
    onVacationsDaysSelectedChange(totalSelectedDays);
  };

  useEffect(() => {
    computeTotalSelectedVacationsDay();
  }, [formik.values.startDate, formik.values.endDate]);

  const handleChange = (value: Approver[]) => {
    const updateApprovers = { target: { value: value, name: "approvers" } };
    formik.handleChange(updateApprovers);
  };

  const onCalendarDateChange = (date: Date) => {
    getVacationsByUser(date);
  };

  const getVacationsByUser = async (date: Date = new Date()) => {
    date.setDate(1); //TO DO Delete it after BE ammend
    const endDate = new Date(date);
    endDate.setMonth(date.getMonth() + 1);
    const res = await VacationService.getVacationFrom(
      email,
      dateToYYYMMDD(date),
      dateToYYYMMDD(endDate),
      0,
      40
    );
    setVacationsByMonth(res.vacationsPaginated.vacations);
    if (isHttpCodeValid(res.code)) {
      return;
    }
  };
  const getTextToDisplayApprovers = () => {
    let text = "";
    if (formik.values.approvers.length >= 1) {
      for (let index = 0; index < formik.values.approvers.length - 1; index++) {
        text = text.concat(formik.values.approvers[index].name + ", ");
      }
      text = text.concat(formik.values.approvers[formik.values.approvers.length - 1].name);
    }
    return text;
  };

  const disableWeekends = (date: Date) => {
    const day = date.getDay();
    return day === DAY_OF_WEEK_INDEX.SUNDAY || day === DAY_OF_WEEK_INDEX.SATURDAY;
  };

  const disableVacationDays = (date: Date) => {
    for (const vacation of vacationsByMonth) {
      if (
        new Date(vacation.startDate).getTime() <= date.getTime() &&
        new Date(vacation.endDate).getTime() >= date.getTime()
      ) {
        return true;
      }
    }
    return false;
  };

  const disableRestDays = (date: Date) => {
    return disableWeekends(date) || disableVacationDays(date);
  };

  const approversHandleChange = (event: any) => {
    const eventValue = event.target?.value;
    if (!eventValue) return;
    let approver;
    if (formik.values.approvers.find((ap: any) => ap.name === eventValue)) {
      return;
    }
    if (!Array.isArray(eventValue)) {
      approver = approvers.find((approver) => approver.name === eventValue);
    } else {
      approver = approvers.find((approver) => approver.name === eventValue[eventValue.length - 1]);
    }
    formik.setFieldValue(`approvers`, [...formik.values.approvers, approver]);
  };

  const getMinDateForEndDateInput = () => {
    return formik.values.startDate == undefined || formik.values.startDate == ""
      ? new Date()
      : new Date(formik.values.startDate);
  };

  const renderVacationDaysError = () => {
    if (maxVacationsAvailable != -1 && overlappingVacationErrorShow) {
      return (
        <div className="VacationsCreateForm-maxVacationsError">
          You only have {maxVacationsAvailable} vacation days available. <br /> There are vacations
          overlapping.
        </div>
      );
    } else if (maxVacationsAvailable != -1 && !overlappingVacationErrorShow) {
      return (
        <div className="VacationsCreateForm-maxVacationsError">
          You only have {maxVacationsAvailable} vacation days available.
        </div>
      );
    } else if (maxVacationsAvailable == -1 && overlappingVacationErrorShow) {
      return (
        <div className="VacationsCreateForm-maxVacationsError">
          There are vacation days overlapping.
        </div>
      );
    }
  };

  const createVacation = (
    <Box display={{ color: "#00B189" }}>
      <Box display={{ md: "flex" }}>
        <Box display={{ sm: "flex", md: "flex" }}>
          <Box maxWidth="256px">
            <InputDate
              disableWeekends={disableRestDays}
              onCalendarDateChange={onCalendarDateChange}
              error={formik.errors.startDate}
              value={formik.values.startDate}
              handleChange={formik.handleChange}
              label="Start Date"
              name={`startDate`}
              minDate={new Date()}
            />
          </Box>
          <Box maxWidth="256px">
            <InputDate
              disableWeekends={disableRestDays}
              onCalendarDateChange={onCalendarDateChange}
              error={formik.errors.endDate}
              value={formik.values.endDate}
              handleChange={formik.handleChange}
              label="End Date"
              name={`endDate`}
              minDate={getMinDateForEndDateInput()}
            />
          </Box>
        </Box>
        <Box display={{ sm: "flex", md: "flex" }}>
          <Box padding="1em">
            <Typography variant="body1" sx={{ fontSize: "14px" }}>
              Vacation Days
            </Typography>
            <TextField
              rows={1}
              value={formik.values.total}
              variant="standard"
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "0.5em",
                borderRadius: "24px",
                borderWidth: "0",
                width: "100%",
                height: "2em",
                input: {
                  color: maxVacationsAvailable != -1 ? "#d32f2f" : "#000",
                },
              }}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
            {renderVacationDaysError()}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <InputSelector
          label="Approvers"
          error={formik.errors.approvers}
          value={""}
          target={`approvers`}
          emptyValue={""}
          multiple={false}
          emptyLabel={""}
          values={APPROVERS}
          padding="1em"
          maxWidth="256px"
          height="3.05em"
          handleChange={approversHandleChange}
        />
        <Box
          position="absolute"
          sx={{
            color: "black",
            width: "190px",
            height: "32px",
            marginTop: "45px",
            marginLeft: "25px",
            textAlign: "center",
            lineHeight: "32px",
            overflow: "hidden",
            pointerEvents: "none",
          }}
        >
          {getTextToDisplayApprovers()}
        </Box>
        <Box display="flex" justifyContent="flex-start" sx={{ marginTop: "15px" }}>
          {formik.values.approvers?.map((approver: Approver, index: number) => (
            <MiniEmployeeMultiAvatar
              key={`create-project-approver-avatar-${index}`}
              avatar={approver}
              approverArray={formik.values.approvers}
              handleChange={handleChange}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box padding="1em">
      <Box display="flex" height="50px">
        <Typography
          flexGrow="1"
          sx={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "16px",
            size: "29px",
            color: "#00B189",
          }}
        >
          {pageTitle}
        </Typography>
      </Box>
      {createVacation}
    </Box>
  );
};
