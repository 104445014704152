import React, { FC } from "react";

export const Employees: FC = () => {
  return (
    <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0625 17.25C10.0625 17.25 8.625 17.25 8.625 15.8125C8.625 14.375 10.0625 10.0625 15.8125 10.0625C21.5625 10.0625 23 14.375 23 15.8125C23 17.25 21.5625 17.25 21.5625 17.25H10.0625ZM15.8125 8.625C16.9562 8.625 18.0531 8.17065 18.8619 7.3619C19.6706 6.55315 20.125 5.45625 20.125 4.3125C20.125 3.16875 19.6706 2.07185 18.8619 1.2631C18.0531 0.454352 16.9562 0 15.8125 0C14.6688 0 13.5719 0.454352 12.7631 1.2631C11.9544 2.07185 11.5 3.16875 11.5 4.3125C11.5 5.45625 11.9544 6.55315 12.7631 7.3619C13.5719 8.17065 14.6688 8.625 15.8125 8.625V8.625Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.498 17.2501C7.2849 16.8014 7.17862 16.3093 7.1875 15.8126C7.1875 13.8648 8.165 11.8595 9.9705 10.4651C9.06932 10.1875 8.13043 10.0517 7.1875 10.0626C1.4375 10.0626 0 14.3751 0 15.8126C0 17.2501 1.4375 17.2501 1.4375 17.2501H7.498Z"
        fill="white"
      />
      <path
        d="M6.46875 8.625C7.42187 8.625 8.33596 8.24637 9.00991 7.57241C9.68387 6.89846 10.0625 5.98437 10.0625 5.03125C10.0625 4.07813 9.68387 3.16404 9.00991 2.49009C8.33596 1.81613 7.42187 1.4375 6.46875 1.4375C5.51563 1.4375 4.60154 1.81613 3.92759 2.49009C3.25363 3.16404 2.875 4.07813 2.875 5.03125C2.875 5.98437 3.25363 6.89846 3.92759 7.57241C4.60154 8.24637 5.51563 8.625 6.46875 8.625V8.625Z"
        fill="white"
      />
    </svg>
  );
};
