import React, { FC, useEffect, useState } from "react";
import { getProjects } from "../../../../stores/hr/Project.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { ProjectCard } from "../ProjectCard/ProjectCard";

export const ProjectsList: FC = () => {
  const dispatch = useTypedDispatch();
  const { projects } = useTypedSelector((state: RootState) => state.projects);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    await dispatch(getProjects());
  };

  return (
    <div className="card-container">
      {projects != undefined &&
        projects.map((project, index) => {
          return <ProjectCard key={`project-${index}`} project={project} />;
        })}
    </div>
  );
};
