import { borderColor, height, width } from "@mui/system";
import { hover } from "@testing-library/user-event/dist/hover";
import React, { FC, useEffect, useState } from "react";

import "./LabelWithUpperTitle.css";

interface LabelWithUpperTitleProps {
  title: string;
  label: string;
  role?: string;
  titleTextColor: string;
  labelTextColor: string;
  editEnable: boolean;
}

export const LabelWithUpperTitle: FC<LabelWithUpperTitleProps> = ({
  title,
  label,
  role,
  titleTextColor,
  labelTextColor,
  editEnable,
}) => {
  return (
    <div className="LabelWithUpperTitle-container">
      <div className="LabelWithUpperTitle-title" style={{ color: titleTextColor }}>
        {title}
      </div>
      <div className="LabelWithUpperTitle-content">
        <div className="LabelWithUpperTitle-label" style={{ color: labelTextColor }}>
          {label}
        </div>
        <div className="LabelWithUpperTitle-role" style={{ color: titleTextColor }}>
          {role}
        </div>
      </div>
    </div>
  );
};
