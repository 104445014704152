import React, { FC, useState } from "react";
import "./ImageInputSquareAfterAdd.css";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

interface ImageInputSquareAfterAddProps {
  title: string;
  buttonTitle: string;
  formik: any;
  image: string;
  name?: string;
  folder: string;
  target: string;
  width: string;
  height: string;
  border?: string;
  closeIconBackgroundColor?: string;
  closeIconColor?: string;
  cert?: boolean;
}

export const ImageInputSquareAfterAdd: FC<ImageInputSquareAfterAddProps> = ({
  formik,
  image,
  name,
  target,
  width,
  height,
  border,
  closeIconBackgroundColor,
  closeIconColor,
  cert,
}) => {
  const deleteFile = () => {
    if (image) {
      formik.setFieldValue(name || target, undefined);
    }
  };

  const [isHover, setIsHover] = useState(false);

  const borderProperties = border?.split(" ");
  if (borderProperties) borderProperties[borderProperties.length - 1] = "white";

  const iconHoverStyle = {
    border: isHover ? (border ? borderProperties?.join(" ") : "2px solid #00b189") : undefined,
    backgroundColor: closeIconBackgroundColor ? closeIconBackgroundColor : "#000",
  };

  const iconHoverStyleCert = {
    ...iconHoverStyle,
    right: "0",
    top: "0",
    position: "absolute",
  };

  return (
    <div
      className="ImageInputSquareAfterAdd-uploadContainer"
      style={{
        height: height,
        width: width,
        padding: cert ? "12em" : "0",
        backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "#BEBEBE",
        backgroundSize: "contain",
      }}
    >
      <IconButton
        style={cert ? iconHoverStyleCert : iconHoverStyle}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className="ImageInputSquareAfterAdd-deleteButton"
        onClick={deleteFile}
      >
        <ClearIcon
          style={{
            color: closeIconColor ? closeIconColor : "#00b189",
          }}
        ></ClearIcon>
      </IconButton>
    </div>
  );
};
