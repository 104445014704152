import React, { FC, useEffect, useState } from "react";
import { ImageInputSquareAfterAddIcon } from "./ImageInputAfterAddIcon/ImageInputSquareAfterAddIcon";
import { ImageInputSquareAdd } from "./ImageInputSquareAdd/ImageInputSquareAdd";
import { ImageInputSquareAfterAdd } from "./ImageInputSquareAfterAdd/ImageInputSquareAfterAdd";

interface ImageInputSquareProps {
  title: string;
  buttonTitle: string;
  formik: any;
  error: any;
  image?: string;
  name?: string;
  folder: string;
  target: string;
  width: string;
  height: string;
  acceptType: string | null;
  backgroundColor?: string;
  backgroundColorHover?: string;
  border?: string;
  borderRadius?: string;
  colorContent?: string;
  closeIconBackgroundColor?: string;
  cert?: boolean;
  colorContentHover?: string;
}

export const ImageInputSquare: FC<ImageInputSquareProps> = ({
  title,
  buttonTitle,
  formik,
  image,
  name,
  error,
  target,
  folder,
  width,
  height,
  acceptType,
  backgroundColor,
  backgroundColorHover,
  border,
  borderRadius,
  colorContent,
  closeIconBackgroundColor,
  cert,
  colorContentHover,
}) => {
  const [preview, setPreview] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (image) {
      setPreview(image);
      return;
    }
    setPreview(undefined);
  }, [image]);

  return preview == "" || preview == undefined ? (
    <ImageInputSquareAdd
      title="Image"
      formik={formik}
      buttonTitle={buttonTitle}
      image={image ? image : ""}
      name={name}
      error={error}
      target={target}
      folder={folder}
      height={height}
      width={width}
      acceptType={acceptType}
      backgroundColor={backgroundColor}
      backgroundColorHover={backgroundColorHover}
      border={border}
      borderRadius={borderRadius}
      colorContent={colorContent}
      cert={cert}
      colorContentHover={colorContentHover}
    />
  ) : acceptType == null ? (
    <ImageInputSquareAfterAdd
      title={title}
      formik={formik}
      buttonTitle={buttonTitle}
      image={image ? image : ""}
      name={name}
      target={target}
      folder={folder}
      height={height}
      width={width}
      border={border}
      closeIconBackgroundColor={closeIconBackgroundColor}
      closeIconColor={colorContent}
      cert={cert}
    />
  ) : (
    <ImageInputSquareAfterAddIcon
      formik={formik}
      image={image ? image : ""}
      name={name}
      target={target}
      folder={folder}
      height={height}
      width={width}
    />
  );
};
