import React, { FC, useEffect, useState } from "react";
import { HREmployee } from "../../../../types/hr/HREmployee";
// import { RootState, useTypedDispatch, useTypedSelector } from "../../../../../stores/store";

import "./EmployeeAvatarSquare.css";

interface EmployeeAvatarSquareProps {
  employee: HREmployee;
}

export const EmployeeAvatarSquare: FC<EmployeeAvatarSquareProps> = ({ employee }) => {
  return (
    <div className="EmployeAvatarSquare-container">
      {employee.active ? (
        <div
          className="EmployeAvatarSquare-imageActive"
          style={{
            backgroundImage: `url(${employee.pictureURL})`,
          }}
        ></div>
      ) : (
        <div
          className="EmployeAvatarSquare-imageInactive"
          style={{
            backgroundImage: `url(${employee.pictureURL})`,
          }}
        ></div>
      )}
    </div>
  );
};
