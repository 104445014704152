import React, { FC } from "react";

export const Projects: FC = () => {
  return (
    <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.787132 18.8912H18.104L18.8597 18.3087L23 7.28884L22.2444 6.29706H20.4654V2.3614L19.6783 1.57426H10.5633L9.20945 0.23614L8.65845 0H0.787132L0 0.787132V18.104L0.787132 18.8912ZM1.57426 1.57426H8.32786L9.68173 2.91239L10.2327 3.14853H18.8912V6.29706H11.807L11.256 6.5332L9.90212 7.87132H3.93566L3.19576 8.40657L1.62149 13.2553L1.57426 1.57426ZM17.5216 17.3169H1.87337L4.5024 9.44559H10.2327L10.7837 9.20944L12.1376 7.87132H21.2526L17.5216 17.3169Z"
        fill="white"
      />
    </svg>
  );
};
