import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmployeDetails } from "../../../components/hr/employees/EmployeDetails/EmployeDetails";
import { PageDetails } from "../../../components/hr/Global/PageDetails/PageDetails";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { PageDetailsContent, PageDetailsContentWithoutAction } from "../../../types/hr/PageContent";
import { Employee } from "../../../components/hr/employees/EmployeesInfo/Employee";
import { Trainings } from "../../../components/hr/employees/Training/Training";
import { HRVacations } from "../../../components/hr/employees/Vacations/vacations";
import { useFormik } from "formik";
import { cvValidationSchema } from "../../../utils/internal/cv.utils";
import { CVPage } from "../../internal/CVPage/CVPage";
import { featureToogles } from "../../../constants/hr/featureToggles.consts";
import { EMPLOYEE_PAGE_TABS } from "../../../constants/hr/hr.const";

//import { CurriculumVitae } from "../../../components/hr/employees/CurriculumVitae/CurriculumVitae";

export const EmployeePage: FC = () => {
  const { employeeEmail } = useParams();
  const curriculumVitae = useTypedSelector((state: RootState) => state.CV);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeCVTab, setActiveCVTab] = useState<number>(0);

  const switchTabAction = (index: number) => {
    setActiveTab(index);
  };

  const formik = useFormik({
    initialValues: curriculumVitae,
    onSubmit: () => {},
    validationSchema: cvValidationSchema,
  });

  const [pageContent, setPageContent] = useState<PageDetailsContentWithoutAction>({
    tabs: EMPLOYEE_PAGE_TABS,
    previousPage: "/employees",
    switchTabAction: switchTabAction,
  });

  const CVtabs = ["Personal Info", "Tech Stack", "Experience", "Education and Certs", "Languages"];

  const switchCVTabAction = (index: number) => {
    setActiveCVTab(index);
  };

  const [CVContent, setCVContent] = useState<PageDetailsContentWithoutAction>({
    tabs: CVtabs,
    previousPage: "/employees/details",
    switchTabAction: switchCVTabAction,
  });

  const renderTab = (index: number) => {
    switch (index) {
      case 0:
        return <Employee employeeEmail={employeeEmail} />;
      case 1:
        return <Trainings employeeEmail={employeeEmail} />;
      case 2:
        return <HRVacations employeeEmail={employeeEmail} />;
      case 3:
        return <CVPage hrMode={true} userEmail={employeeEmail} />;
      default:
        return <Employee employeeEmail={employeeEmail} />;
    }
  };

  return (
    <PageDetails pageContent={pageContent} sidebar={true} noPadding>
      {renderTab(activeTab)}
    </PageDetails>
  );
};
