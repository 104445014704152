import React, { FC } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { InputSelector } from "../../../../Inputs/InputSelector";
import DeleteIcon from "@mui/icons-material/Delete";
import "./CVLanguage.css";
import { sortObjASC } from "../../../../../utils/sort.utils";
import { CollapseExpand } from "../../../../CollapseExpand/CollapseExpand";
interface CVLanguageProps {
  formik: any;
  title: string;
  index: number;
  length: number;
  languages: string[];
  languageLevels: string[];
  notMappedLanguages: string[];
  handleDelete: (index: number) => void;
  handleInputChange: (event: any) => {};
  hrStyle?: any;
}

export const CVLanguage: FC<CVLanguageProps> = ({
  formik,
  title,
  index,
  length,
  handleDelete,
  languages,
  languageLevels,
  notMappedLanguages,
  handleInputChange,
  hrStyle,
}) => {
  const showHRStyle = () => {
    return hrStyle != undefined && hrStyle != null;
  };

  return (
    <CollapseExpand
      title={title}
      index={index}
      length={length}
      backgroundColor={hrStyle?.collapseBottomBorder}
      titleColor={hrStyle?.collapseTitleColor}
    >
      <Box display="flex" style={{ marginBottom: "50px" }}>
        <div className="CVLanguage-input">
          <InputSelector
            error={formik.errors.languages && formik.errors.languages[index]?.language}
            value={formik.values.languages[index]?.language}
            handleChange={handleInputChange}
            label="Language"
            height="2rem"
            target={`languages[${index}].language`}
            emptyValue=""
            emptyLabel=""
            values={languages}
            padding="1em"
            maxWidth="100%"
            notMappedValues={notMappedLanguages}
            multiple={false}
            backgroundColor={hrStyle?.inputBackground}
            titleColor={hrStyle?.inputLabelColor}
          />
          <InputSelector
            error={formik.errors.languages && formik.errors.languages[index]?.level}
            value={formik.values.languages[index]?.level}
            handleChange={handleInputChange}
            label="Level"
            height="2rem"
            target={`languages[${index}].level`}
            emptyValue=""
            emptyLabel=""
            values={languageLevels}
            padding="1em"
            maxWidth="100%"
            multiple={false}
            backgroundColor={hrStyle?.inputBackground}
            titleColor={hrStyle?.inputLabelColor}
          />
        </div>
        <div className="CVLanguage-deleteButton CV-deleteButton">
          <Tooltip title="Delete Language" onClick={() => handleDelete(index)}>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Box>
    </CollapseExpand>
  );
};
