import React, { FC } from "react";

interface DeleteIconProps {
  onClick: any;
}
export const DeleteIcon: FC<DeleteIconProps> = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width="27"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.55555H26M23.2222 6.55555V26C23.2222 26.7367 22.9296 27.4432 22.4086 27.9642C21.8877 28.4851 21.1812 28.7778 20.4444 28.7778H6.55556C5.81884 28.7778 5.1123 28.4851 4.59137 27.9642C4.07044 27.4432 3.77778 26.7367 3.77778 26V6.55555M7.94444 6.55555V3.77778C7.94444 3.04107 8.2371 2.33453 8.75804 1.81359C9.27897 1.29266 9.98551 1 10.7222 1H16.2778C17.0145 1 17.721 1.29266 18.242 1.81359C18.7629 2.33453 19.0556 3.04107 19.0556 3.77778V6.55555M10.7222 13.5V21.8333M16.2778 13.5V21.8333"
        stroke="#3AAFC9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
