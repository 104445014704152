import React, { FC, useEffect, useState } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import {
  editVacancy,
  getVacancy,
  setEdited,
  getLocations,
} from "../../../../stores/mkt/Career.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { VacancyCreateEdit } from "../../../../components/mkt/Vacancy/VacancyCreateEdit/VacancyCreateEdit";
import { vacancyValidationSchema } from "../../../../utils/mkt/vacancy";
import { useNavigate, useParams } from "react-router-dom";
import "./VacancyEditPage.css";

export const VacancyEditPage: FC = () => {
  const dispatch = useTypedDispatch();
  let { vacancy, edited, locations } = useTypedSelector((state: RootState) => state.careers);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    const fetchVacancy = async () => {
      await dispatch(getVacancy({ id: id!! })).then(() => {
        setLoaded(true);
        fetchLocations();
      });
    };
    const fetchLocations = async () => {
      await dispatch(getLocations());
    };
    fetchVacancy();
  }, []);

  useEffect(() => {
    if (edited) {
      dispatch(setEdited(false));
      navigate(`/careers`);
    }
  }, [edited]);

  let formik = useFormik({
    initialValues: vacancy,
    onSubmit: (userInputData) => {},
    validationSchema: vacancyValidationSchema,
    enableReinitialize: true,
  });

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return;
    }
    const vacancy = {
      ...formik.values,
      role: formik.values.role.trim(),
      responsibilities: formik.values.responsibilities.trim(),
      skills: formik.values.skills.trim(),
      benefits: formik.values.benefits.trim(),
    };
    dispatch(editVacancy({ vacancy: vacancy }));
  };

  const swithTabAction = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="VacancyEditPage-pageEditDiv">
      <PageCreate
        pageContent={{
          pageTitle: `Edit Vacancy`,
          previousPage: `/careers`,
          action: createAction,
          type: "Edit",
          tabs: ["Basic Information"], //["Basic Information", "SEO"]
          switchTabAction: swithTabAction,
        }}
      >
        {loaded ? (
          <VacancyCreateEdit formik={formik} locations={locations} activeTab={activeTab} />
        ) : null}
      </PageCreate>
    </div>
  );
};
