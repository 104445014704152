import React, { FC } from "react";

export const SaveIcon: FC = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11" cy="11" r="11" fill="white" />
      <path
        d="M18.7783 3.22199C14.4822 -1.074 7.51809 -1.074 3.22204 3.22199C-1.07401 7.51798 -1.07401 14.482 3.22204 18.778C7.51809 23.074 14.4822 23.074 18.7783 18.778C23.0743 14.482 23.0735 7.51798 18.7783 3.22199ZM16.9867 8.28003L10.3023 14.9651C10.1373 15.1301 9.91354 15.2228 9.6802 15.2228C9.44686 15.2228 9.22307 15.1301 9.05805 14.9651L5.01367 10.9199C4.93197 10.8382 4.86716 10.7412 4.82295 10.6345C4.77873 10.5277 4.75597 10.4133 4.75597 10.2978C4.75597 10.0644 4.84867 9.84065 5.01367 9.67565C5.17868 9.51065 5.40247 9.41796 5.63582 9.41796C5.86917 9.41796 6.09296 9.51065 6.25796 9.67565L9.6802 13.0978L15.7424 7.03488C15.8241 6.95318 15.9212 6.88838 16.028 6.84419C16.1348 6.79999 16.2492 6.77727 16.3648 6.77731C16.4804 6.77735 16.5949 6.80016 16.7016 6.84443C16.8084 6.8887 16.9054 6.95356 16.9871 7.03532C17.0688 7.11708 17.1336 7.21413 17.1778 7.32093C17.222 7.42773 17.2447 7.54218 17.2447 7.65777C17.2446 7.77335 17.2218 7.88779 17.1775 7.99456C17.1333 8.10133 17.0684 8.19833 16.9867 8.28003Z"
        fill="#5EA46D"
      />
    </svg>
  );
};
