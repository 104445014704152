import * as yup from "yup";

export const caseStudyValidationSchema = yup.object({
  thumbnail: yup.string().strict().required("Thumbnail is required"),
  title: yup
    .string()
    .strict()
    .required("Title is required")
    .max(100, "Title must be at most 100 characters"),
  subtitle: yup
    .string()
    .strict()
    .required("Subtitle is required")
    .max(200, "Subitle must be at most 200 characters"),
  date: yup.string().required("Date is required"),
  readTime: yup
    .string()
    .strict()
    .required("Read Time is required")
    .matches(/^\d+$/, "Read time must be a number")
    .max(4, "Read Time must be at most 4 digits"),
  challenge: yup.object().shape({
    title: yup
      .string()
      .strict()
      .required("Challenge Title is required")
      .max(100, "Title must be at most 100 characters"),
    description: yup.string().strict().required("Challenge Description is required"),
  }),
  solution: yup.object().shape({
    text: yup.string().strict().required("Solution Text required"),
    stack: yup.string().strict().required("Solution Stack is required"),
  }),
  results: yup.string().nullable(),
});
