import { IconButton } from "@mui/material";
import React, { FC, useState } from "react";
import { useTypedDispatch } from "../../../../../stores/store";
import CancelIcon from "@mui/icons-material/Cancel";

import "./MiniEmployeeAvatarClose.css";
import { Approver } from "../../../../../types/hr/Approver";
import { removeChosenApprover } from "../../../../../stores/hr/Project.store";
interface MiniEmployeeAvatarCloseProps {
  avatar: Approver;
}

export const MiniEmployeeAvatarClose: FC<MiniEmployeeAvatarCloseProps> = ({ avatar }) => {
  const dispatch = useTypedDispatch();

  const [isHover, setIsHover] = useState(false);
  const iconStyle = {
    color: "#3AAFC9",
    position: "absolute" as "absolute",
    top: 0,
    left: "1.9em",
    right: 0,
    backgroundColor: "white",
    width: "20px",
    height: "20px",
    border: isHover ? "3px solid white" : undefined,
  };

  const removeApprover = async () => {
    dispatch(removeChosenApprover(avatar.id));
  };

  return (
    <div className="employee-avatar-close-container">
      <div
        className="employee-avatar-close-image"
        style={{
          backgroundImage: `url("${avatar.pictureURL}")`,
        }}
      ></div>
      <IconButton
        style={iconStyle}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={removeApprover}
      >
        <CancelIcon />
      </IconButton>
    </div>
  );
};
