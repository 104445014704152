import { IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";

import "./MiniEmployeeAvatar.css";
interface MiniEmployeeMultiAvatarProps {
  avatar: any;
  approverArray: string[];
  handleChange: (e: any) => void;
}

export const MiniEmployeeMultiAvatar: FC<MiniEmployeeMultiAvatarProps> = observer(
  ({ avatar, approverArray, handleChange }) => {
    const [isHover, setIsHover] = useState(false);
    const [_, setNewItems] = useState([""]);
    let iconStyle = {
      color: "#3AAFC9",
      position: "absolute" as "absolute",
      top: 0,
      left: "1.9em",
      right: 0,
      backgroundColor: "white",
      width: "20px",
      height: "20px",
      border: isHover ? "3px solid white" : undefined,
    };

    const handleDelete = (index: number) => {
      let newApproverArray = [...approverArray];
      newApproverArray.splice(index, 1);
      setNewItems(newApproverArray);
      handleChange(newApproverArray);
    };
    return (
      <div className="employee-avatar-close-container">
        <div
          className="employee-avatar-close-image"
          style={{
            backgroundImage: `url("${avatar.pictureURL}")`,
            backgroundColor: "#00B189",
          }}
        ></div>
        <IconButton
          style={iconStyle}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <CancelIcon
            onClick={() => {
              handleDelete(approverArray.indexOf(avatar));
            }}
          />
        </IconButton>
      </div>
    );
  }
);
