import React, { FC, useEffect, useState } from "react";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";

export const StartScreen: FC = () => {
  const dispatch = useTypedDispatch();
  const { user, loading, error } = useTypedSelector((state: RootState) => state.user);
  const { token, email } = useTypedSelector((state: RootState) => state.auth);
  const [loaded, setLoaded] = useState(false);

  return <p>Start Screen</p>;
};
