import { Box } from "@mui/material";
import React, { FC, ReactElement, useState } from "react";
import { HRSidebar } from "../Sidebar/HRSidebar";
import { PageDetailsContentWithoutAction } from "../../../../types/hr/PageContent";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useNavigate } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import { KodlyIcon } from "../../../../assets/svg/KodlyIcon";
import "./PageDetails.css";

interface PageDetailsProps {
  children: ReactElement | null;
  pageContent: PageDetailsContentWithoutAction;
  sidebar: any;
  noPadding?: boolean;
}

export const PageDetails: FC<PageDetailsProps> = ({
  children,
  pageContent,
  sidebar,
  noPadding,
}) => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);

  const onChangeActiveTab = (tabIndex: number) => {
    if (pageContent.switchTabAction) pageContent.switchTabAction(tabIndex);
    setActiveTab(tabIndex);
  };

  const renderSideBar = () => (
    <Box sx={{ height: "100vh", width: open ? "225px" : "75px" }}>
      <div className="HRPage-kodlyIcon">
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "flex-start",
            px: 2,
          }}
        >
          {open ? (
            <img
              className="HRPage-kodlyIconImg"
              src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/kodly.png"
              alt="Logo"
              width="175px"
              onClick={() => navigate("/", { replace: true })}
            />
          ) : (
            <KodlyIcon />
          )}
        </ListItemIcon>
      </div>
      <HRSidebar sidebar={sidebar} />
    </Box>
  );

  const renderTabBar = () => (
    <div className="HRPageCreate-tabs">
      {pageContent.tabs?.map((tab, index) => (
        <Box
          className={
            activeTab == index ? "HRPage-menuSwitch_activeElement" : "HRPage-menuSwitch_element"
          }
          key={index}
          onClick={() => onChangeActiveTab(index)}
        >
          {tab}
        </Box>
      ))}
    </div>
  );

  const renderContent = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: open ? "calc(100% - 225px)" : "calc(100% - 75px)",
        backgroundColor: "#1F2632",
      }}
    >
      {pageContent.tabs && pageContent.switchTabAction && renderTabBar()}
      <Box
        sx={{
          padding: noPadding ? "0" : "2em 4em",
          height: "calc(100vh)",
          overflowY: "auto",
          boxSizing: "content-box",
        }}
      >
        {children}
      </Box>
    </Box>
  );

  return (
    <Box display="flex" sx={{ height: "100vh" }}>
      {renderSideBar()}
      {renderContent()}
    </Box>
  );
};
