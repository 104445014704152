import React, { FC, useState } from "react";
import { MiniEmployeeAvatar } from "../../../ProjectCard/avatar/MiniEmployeeAvatar";
import { Input } from "../../../../../Inputs/Input";
import { DeleteIcon } from "../../../../../../assets/svg/DeleteIcon";

import "./TeamMembersTable.css";
import { FormikValues, useFormik } from "formik";
import { ProjectTeamMember } from "../../../../../../types/hr/ProjectTeamMember";
import { projectTeamMemberValidationSchema } from "../../../../../../utils/hr/project.utils";
import { InputSelector } from "../../../../../Inputs/InputSelector";
import {
  workTypeKeys,
  workTypeOptions,
  workTypeStrings,
} from "../../../../../../types/hr/WorkType";
import { Checkbox } from "@mui/material";

interface TeamMembersTableProps {
  index: number;
  formik?: FormikValues;
  member: ProjectTeamMember;
  currency: string;
  inViewMode?: boolean;
  inEditMode?: boolean;
  onDeleteTeamMember?: (index: number) => void;
  handleInputChange?: (event: any) => {};
}

export const TeamMembersTableRow: FC<TeamMembersTableProps> = ({
  index,
  formik,
  member,
  currency,
  inViewMode,
  inEditMode,
  onDeleteTeamMember,
}) => {
  const [roleWasUpdated, setRoleWasUpdated] = useState<boolean>(false);
  const [workTypeWasUpdated, setWorkTypeWasUpdated] = useState<boolean>(false);
  const [dailyRateWasUpdated, setDailyRateWasUpdated] = useState<boolean>(false);

  const handleTypeChange = (event: any) => {
    if (formik != undefined) {
      setWorkTypeWasUpdated(true);
      formik.setFieldValue(`teamMembers[${index}].workType`, workTypeKeys(event.target.value));
    }
  };

  const handleChangeCurrentProject = (isSelected: boolean) => {
    if (formik != undefined) {
      formik.setFieldValue(`teamMembers[${index}].active`, isSelected);
    }
  };

  const onRoleChange = async (event: any) => {
    if (formik != undefined) {
      setRoleWasUpdated(true);
      formik.handleChange(event);
    }
  };

  const onDailyRateChange = async (event: any) => {
    if (formik != undefined) {
      setDailyRateWasUpdated(true);
      formik.handleChange(event);
    }
  };

  const getErrorForRole = () => {
    var error = "";
    if (formik != undefined && roleWasUpdated) {
      try {
        error = formik.errors.teamMembers[index].role;
      } catch (exception) {
        error = "";
      }
    }
    return error;
  };

  const getErrorForWorkType = () => {
    var error = "";
    if (formik != undefined && workTypeWasUpdated) {
      try {
        error = formik.errors.teamMembers[index].workType;
      } catch (exception) {
        error = "";
      }
    }
    return error;
  };

  const getErrorForDailyRate = () => {
    var error = "";
    if (formik != undefined && dailyRateWasUpdated) {
      try {
        error = formik.errors.teamMembers[index].dailyRate;
      } catch (exception) {
        error = "";
      }
    }
    return error;
  };

  return (
    <div>
      <div className="project-team-members-table-row-item">
        <div className="project-team-table-column-name">
          <MiniEmployeeAvatar pictureURL={member.pictureURL} avatarShift={0} />
          <div className="project-team-table-name">{member.name}</div>
        </div>
        <div className="project-team-table-column">
          {inViewMode || formik == undefined ? (
            member.role
          ) : (
            <Input
              noPadding
              error={getErrorForRole()}
              handleChange={onRoleChange}
              handleBlur={formik.handleBlur}
              value={formik.values.teamMembers[index].role}
              name={`teamMembers[${index}].role`}
              type="text"
              width="70%"
              height="auto"
              backgroundColor="white"
              borderRadius="16px"
              errorColor="#E94040"
            />
          )}
        </div>
        <div className="project-team-table-column">
          {inViewMode || formik == undefined ? (
            workTypeStrings(member.workType)
          ) : (
            <InputSelector
              error={getErrorForWorkType()}
              value={workTypeStrings(formik.values.teamMembers[index].workType)}
              handleChange={handleTypeChange}
              height="auto"
              target="workType"
              emptyValue=""
              emptyLabel=""
              values={workTypeOptions}
              padding="0"
              maxWidth="70%"
              backgroundColor="white"
              borderRadius="16px"
              multiple={false}
            />
          )}
        </div>
        <div className="project-team-table-column-cost">
          {inViewMode || formik == undefined ? (
            <>
              {member.dailyRate.toFixed(2)} {currency}
            </>
          ) : (
            <Input
              noPadding
              error={getErrorForDailyRate()}
              handleChange={onDailyRateChange}
              handleBlur={formik.handleBlur}
              value={formik.values.teamMembers[index].dailyRate}
              name={`teamMembers[${index}].dailyRate`}
              type="number"
              width="40%"
              height="auto"
              backgroundColor="white"
              borderRadius="16px"
              errorColor="#E94040"
            />
          )}
        </div>
        {inEditMode && formik != undefined && (
          <div className="project-team-table-column-delete">
            <Checkbox
              name="active"
              checked={!formik.values.teamMembers[index].active}
              value={!formik.values.teamMembers[index].active}
              onChange={(_, checked) => {
                handleChangeCurrentProject(!checked);
              }}
              onBlur={formik.handleBlur}
              sx={{
                color: "white",
                "& .MuiSvgIcon-root": {
                  fontSize: "30px",
                },
                "&.Mui-checked": {
                  color: "#676CDB",
                },
              }}
            />
          </div>
        )}
        {!inViewMode ? (
          <div style={{ justifyContent: "center" }} className="project-team-table-column-delete">
            <DeleteIcon
              onClick={() =>
                index != undefined && onDeleteTeamMember != undefined
                  ? onDeleteTeamMember(index)
                  : {}
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
