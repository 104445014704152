import { Box, IconButton, TablePagination, Tooltip } from "@mui/material";
import React, { FC } from "react";
import { getSubmissionStatusText } from "../../../../data/common/SubmissionStatus";
import { dateToDDMMYYYY } from "../../../../utils/date";
import { API_REQUEST_SETTINGS } from "../../../../constants/internal/apiRequestSettings.const";
import { VacationPaginated } from "../../../../types/internal/vacation/VacationPaginated";
import { Vacation } from "../../../../types/internal/vacation/Vacation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import "./VacationsControlTable.css";
import { RootState, useTypedSelector } from "../../../../stores/store";

interface VacationsControlTableProps {
  vacationsPaginated: VacationPaginated;
  tablePage: number;
  onPaginationChange: (newPage: number) => void;
  onApproveVacationPress: (vacationId: string, reviewer: string) => void;
  onRejectVacationPress: (vacationId: string, reviewer: string) => void;
}

export const VacationsControlTable: FC<VacationsControlTableProps> = ({
  vacationsPaginated,
  tablePage,
  onPaginationChange,
  onApproveVacationPress,
  onRejectVacationPress,
}) => {
  const areThereVacationsToApprove = () => {
    return vacationsPaginated && vacationsPaginated.vacations.length > 0;
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPaginationChange(newPage);
  };

  const getTextToDisplayDates = (vacation: Vacation) => {
    return (
      dateToDDMMYYYY(new Date(vacation.startDate)) +
      " to " +
      dateToDDMMYYYY(new Date(vacation.endDate))
    );
  };

  const getActionButtons = (vacation: Vacation) => {
    const vacationId = vacation.id;
    const { email } = useTypedSelector((state: RootState) => state.auth);
    return (
      <div>
        <Tooltip title="Reject" color="error">
          <IconButton
            onClick={() => (vacationId == null ? {} : onRejectVacationPress(vacationId, email))}
          >
            <CancelIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Confirm" sx={{ color: "#00b088", marginLeft: "0.5em" }}>
          <IconButton
            onClick={() => (vacationId == null ? {} : onApproveVacationPress(vacationId, email))}
          >
            <CheckCircleIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <div>
      <div className="VacationsControlTable-title">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {vacationsPaginated && vacationsPaginated.totalPages > 1 && (
            <TablePagination
              component="div"
              count={vacationsPaginated ? vacationsPaginated.totalElements : 0}
              page={tablePage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[]}
              rowsPerPage={API_REQUEST_SETTINGS.GET_VACATIONS_BY_APPROVERS_TABLE_SIZE}
              labelDisplayedRows={({}) => {
                return ``;
              }}
              onRowsPerPageChange={() => {}}
              sx={{ display: "flex", width: "100%", justifyContent: "end" }}
            />
          )}
        </Box>
      </div>
      {areThereVacationsToApprove() && (
        <div className="VacationsControlTable-tableContainer">
          <table className="VacationsControlTable-table">
            <thead>
              <tr className="VacationsControlTable-headRow">
                <th>Period</th>
                <th>Days</th>
                <th>Requested By</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {vacationsPaginated.vacations
                ? vacationsPaginated.vacations.map((vacation, index) => {
                    return (
                      <tr
                        key={index}
                        className={
                          index % 2 == 0
                            ? "VacationsControlTable-tableRowOdd"
                            : "VacationsControlTable-tableRowEven"
                        }
                      >
                        <td>{getTextToDisplayDates(vacation)}</td>
                        <td>{vacation.total}</td>
                        <td>{vacation.employee}</td>
                        <td>{getSubmissionStatusText(vacation.status)}</td>
                        <td>{getActionButtons(vacation)}</td>
                      </tr>
                    );
                  })
                : ""}
            </tbody>
          </table>
        </div>
      )}
      {!areThereVacationsToApprove() && (
        <div className="VacationsControlTable-noContentText">No vacations to approve</div>
      )}
    </div>
  );
};
