import React, { FC, useEffect } from "react";
import { PageCreate } from "../../../../components/mkt/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { getClient, editClient, setEdited } from "../../../../stores/mkt/Client.store";
import { clientValidationSchema } from "../../../../utils/mkt/client";
import { ClientCreateEdit } from "../../../../components/mkt/Client/ClientCreateEdit/ClientCreateEdit";

export const ClientEditPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { client, loading, error, edited } = useTypedSelector((state: RootState) => state.clients);
  const { token } = useTypedSelector((state: RootState) => state.auth);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getClient({ id: id!! }));
  }, []);

  useEffect(() => {
    if (edited) {
      dispatch(setEdited(false));
      navigate(`/clients/${id}`);
    }
  }, [edited]);

  const createAction = async () => {
    formik.errors = await formik.validateForm(formik.values);
    if (Object.keys(formik.errors).length > 0) {
      return;
    }
    const clientTrimmed = {
      ...formik.values,
      name: formik.values.name.trim(),
    };
    dispatch(editClient({ client: clientTrimmed }));
  };

  const formik = useFormik({
    initialValues: client,
    onSubmit: (userInputData) => {},
    validationSchema: clientValidationSchema,
  });

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Edit Client",
        previousPage: `/clients/${id}`,
        action: createAction,
        type: "Edit",
        tabs: null,
        switchTabAction() {},
      }}
    >
      <ClientCreateEdit formik={formik} />
    </PageCreate>
  );
};
