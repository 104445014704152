import React, { FC } from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material";

interface SidebarButtonProps {
  title: string;
  onClick: () => any;
  icon: JSX.Element;
  open: boolean;
}

export const SidebarButton: FC<SidebarButtonProps> = ({ title, onClick, icon, open }) => {
  return (
    <ListItem key={title} disablePadding sx={{ display: "block", margin: "1em 0" }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: "flex-start",
          px: 2.5,
        }}
        onClick={() => onClick()}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "flex-start",
            color: "white",
          }}
        >
          {icon}
        </ListItemIcon>
        {open && <ListItemText primary={title} />}
      </ListItemButton>
    </ListItem>
  );
};
