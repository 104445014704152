import { Box, FormControl, FormHelperText, TextField, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { dateToYYYMMDD } from "../../utils/date";
import { DAY_OF_WEEK_INDEX } from "../../constants/dates.const";

interface InputDateProps {
  disableWeekends?: (date: Date) => boolean;
  onCalendarDateChange?: (date: Date) => void;
  disabled?: boolean;
  width?: string;
  value: string;
  label?: string;
  error?: string;
  name: string;
  hr?: boolean;
  minDate?: Date;
  handleChange: (e: any) => void;
  backgroundColor?: string;
  titleColor?: string;
  borderRadius?: string;
  errorColor?: string;
  inputFormat?: string;
  disableMask?: boolean;
}
export const InputDate: FC<InputDateProps> = ({
  disableWeekends,
  onCalendarDateChange,
  value,
  label,
  error,
  name,
  minDate,
  handleChange,
  width,
  disabled,
  hr,
  backgroundColor,
  titleColor,
  borderRadius,
  errorColor,
  inputFormat,
  disableMask,
}) => {
  const onChange = (e: Date | null) => {
    if (e) {
      handleChange({ target: { value: dateToYYYMMDD(e), name: name }, type: "change" });
    }
  };

  const normalStyle = {
    width: "100%",
    backgroundColor: backgroundColor ? backgroundColor : "#C9ECE4",
    padding: "0.5em",
    borderRadius: "24px",
    "& div": {
      height: "32px",
    },
    "& fieldset": {
      border: 0,
    },
  };

  const hrStyle = {
    ...normalStyle,
    backgroundColor: "#FFFF",
    padding: "2em",
  };

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        padding: hr ? "0em" : "1em",
        width: width,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: error ? (errorColor ? "white" : "#d32f2f") : titleColor ? titleColor : "inherit",
          fontSize: "14px",
        }}
      >
        {label}
      </Typography>
      <FormControl fullWidth variant="standard" error={Boolean(error)}>
        <DesktopDatePicker
          shouldDisableDate={disableWeekends}
          disabled={disabled}
          inputFormat={inputFormat ? inputFormat : "dd/MM/yyyy"}
          disableMaskedInput={disableMask}
          onMonthChange={onCalendarDateChange}
          onYearChange={onCalendarDateChange}
          value={value == null ? null : new Date(value)}
          onChange={onChange}
          minDate={minDate ? minDate : undefined}
          renderInput={(params) => (
            <TextField
              onKeyDown={onKeyDown}
              {...params}
              inputProps={{ ...params.inputProps, readOnly: false }}
              sx={hr ? hrStyle : normalStyle}
            />
          )}
        />
        <FormHelperText
          style={{
            color: errorColor ? errorColor : "#d32f2f",
          }}
        >
          {error}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};
