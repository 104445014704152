import React, { FC, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormHelperText } from "@mui/material";
import { useTypedDispatch } from "../../../../stores/store";
import { uploadImage } from "../../../../stores/Image.store";

import "./ImageInputSquareAdd.css";
import { FormikValues } from "formik";

interface ImageInputSquareAddProps {
  title: string;
  buttonTitle: string;
  formik: FormikValues;
  image: string;
  name?: string;
  folder: string;
  target: string;
  error: any;
  width: string;
  height: string;
  acceptType: string | null;
  backgroundColor?: string;
  backgroundColorHover?: string;
  border?: string;
  borderRadius?: string;
  colorContent?: string;
  colorContentHover?: string;
  cert?: boolean;
}

export const ImageInputSquareAdd: FC<ImageInputSquareAddProps> = ({
  buttonTitle,
  formik,
  name,
  target,
  folder,
  error,
  width,
  height,
  acceptType,
  backgroundColor,
  backgroundColorHover,
  border,
  borderRadius,
  colorContent,
  colorContentHover,
  cert,
}) => {
  const dispatch = useTypedDispatch();
  const [onHover, setOnHover] = useState<boolean>(false);

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      var data = dispatch(uploadImage({ file: e.target.files[0]!!, folder }));
      data.then((data: any) => {
        formik.setFieldValue(name || target, data.payload.url);
      });
    }
    e.target.value = null;
  };

  const inputFile = useRef<HTMLInputElement | null>(null);

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const chooseBackgroundColor = backgroundColor ? backgroundColor : "#e6f7f3";
  const chooseBackgroundColorHover = backgroundColorHover ? backgroundColorHover : "#b3e8dc";
  const chooseContentColor = colorContent ? colorContent : "#00B189";
  const chooseContentColorHover = colorContentHover
    ? colorContentHover
    : colorContent
    ? colorContent
    : "#007c60";

  return (
    <div
      className="ImageInputSquareAdd-uploadContainer"
      style={{
        height: height,
        width: width,
        backgroundColor: onHover ? chooseBackgroundColorHover : chooseBackgroundColor,
        border: border,
        borderRadius: borderRadius,
        padding: cert ? "12em" : "0",
      }}
      onMouseEnter={() => {
        setOnHover(true);
      }}
      onMouseLeave={() => {
        setOnHover(false);
      }}
      onMouseDown={() => {
        onButtonClick();
      }}
    >
      <div className="ImageInputSquareAdd-uploadContainerContent">
        <CloudUploadIcon
          sx={{
            color: onHover ? chooseContentColorHover : chooseContentColor,
            maxWidth: cert ? "15px" : "5px",
            maxHeight: cert ? "15px" : "5px",
            minWidth: cert ? "5em" : "3em",
            minHeight: cert ? "5em" : "3em",
          }}
        />
        <div
          style={{
            color: onHover ? chooseContentColorHover : chooseContentColor,
            marginTop: "1em",
            textAlign: "center",
          }}
        >
          {buttonTitle}
        </div>
        <FormHelperText sx={{ color: error ? "#d32f2f" : "inherit" }}>{error}</FormHelperText>
        <input
          type="file"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
          onChange={onSelectFile}
          accept={acceptType == null ? "image/*" : acceptType}
        />
      </div>
    </div>
  );
};
