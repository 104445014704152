import React, { FC } from "react";

import "./MiniEmployeeAvatar.css";

interface AvatarProperties {
  avatarShift: number;
  pictureURL: string;
  height?: string;
  width?: string;
  marginLeft?: string;
}

export const MiniEmployeeAvatar: FC<AvatarProperties> = ({
  avatarShift,
  pictureURL,
  height,
  width,
  marginLeft,
}) => {
  return (
    <div
      style={{ left: avatarShift, marginLeft: marginLeft ? marginLeft : "0" }}
      className="employee-avatar-container"
    >
      <div
        className="employee-avatar-image"
        style={{
          backgroundImage: `url("${pictureURL}")`,
          height: height ? height : "30px",
          width: width ? width : "30px",
        }}
      ></div>
    </div>
  );
};
