import React, { FC, useEffect, useState } from "react";
import { Page } from "../../../components/mkt/Global/Pages/Page";
import { pages } from "../../../data/mkt/pageContent";
import {
  editVacancy,
  getVacancies,
  deleteVacancy,
  publishVacancy,
  getVacancy,
  createVacancy,
} from "../../../stores/mkt/Career.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { VacancyDnd } from "../../../components/mkt/Vacancy/VacancyLists/VacancyDnd/VacancyDnd";
import { VacancyGrid } from "../../../components/mkt/Vacancy/VacancyLists/VacancyGrid/VacancyGrid";
import { useNavigate } from "react-router-dom";

export const VacanciesPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { vacancies, vacancy, loading, error, created, edited, deleted } = useTypedSelector(
    (state: RootState) => state.careers
  );
  const [dndEnabled, setDndEnabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  function fetchVacancies() {
    dispatch(getVacancies());
  }

  useEffect(() => {
    fetchVacancies();
  }, []);

  const dndAction = () => {
    for (let index = 0; index < vacancies.length; index++) {
      dispatch(editVacancy({ vacancy: { ...vacancies[index], order: index } }));
    }
    setDndEnabled(false);
  };

  const fetchVacancy = async () => {
    await dispatch(getVacancies()).then(() => {
      setLoaded(true);
    });
  };

  async function cancelDndAction() {
    setDndEnabled(false);
    setLoaded(false);
    fetchVacancy();
  }

  useEffect(() => {
    fetchVacancy();
  }, [deleted]);

  function editAction(id: string) {
    navigate(`/careers/vacancy/${id}/edit`);
  }

  async function publishAction(id: string, publish: boolean) {
    await dispatch(publishVacancy({ id: id!!, publish: publish }));
    fetchVacancies();
  }

  function duplicateAction(id: string) {
    var fetchVacancy = dispatch(getVacancy({ id: id }));
    fetchVacancy.then(async (data: any) => {
      await dispatch(createVacancy({ vacancy: data.payload }));
      fetchVacancies();
    });
  }

  async function deleteAction(id: string) {
    await dispatch(deleteVacancy({ id: id!! }));
    fetchVacancies();
  }

  return (
    <Page
      pageContent={pages.careers}
      search={(term) => {}}
      dndEnabled={dndEnabled}
      dndAction={dndAction}
      cancelDndAction={cancelDndAction}
      setDndEnabled={setDndEnabled}
    >
      {loaded ? (
        dndEnabled ? (
          <VacancyDnd vacancies={vacancies} />
        ) : (
          <VacancyGrid
            vacancies={vacancies}
            editAction={editAction}
            publishAction={publishAction}
            deleteAction={deleteAction}
            duplicateAction={duplicateAction}
          />
        )
      ) : null}
    </Page>
  );
};
