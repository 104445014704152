import React, { FC } from "react";
import { Box, IconButton, Typography, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import { Input } from "../../../../Inputs/Input";
import DeleteIcon from "@mui/icons-material/Delete";
import { InputDate } from "../../../../Inputs/InputDate";
import { CollapseExpand } from "../../../../CollapseExpand/CollapseExpand";
import { formikTrimStrings } from "../../../../../utils/string.utils";
import "./CVProfessionalExperience.css";
interface CVProfessionalExperienceProps {
  formik: any;
  title: string;
  length: number;
  index: number;
  handleDelete: (index: number) => void;
  handleChangeCurrentProject: (index: number, isCurrent: boolean) => void;
  handleInputChange: (event: any) => {};
  hrStyle: any;
}

export const CVProfessionalExperience: FC<CVProfessionalExperienceProps> = ({
  formik,
  title,
  index,
  length,
  handleDelete,
  handleChangeCurrentProject,
  handleInputChange,
  hrStyle,
}) => {
  const isActual = formik.values.experience[index]?.actualProject;

  const onCurrentProjectCheckBoxClick = (index: number) => {
    const isCurrent = !formik.values.experience[index]?.actualProject;
    formik.setFieldValue(`experience[${index}].actualProject`, isCurrent);
    handleChangeCurrentProject(index, isCurrent);
  };

  return (
    <CollapseExpand
      title={title}
      index={index}
      length={length}
      backgroundColor={hrStyle?.collapseBottomBorder}
      titleColor={hrStyle?.collapseTitleColor}
    >
      <div className="CVProfExp-box">
        <div className="CVProfExp-inputs">
          <Box display="flex" className="CVProfExp-1stRow">
            <InputDate
              error={formik.errors.experience && formik.errors.experience[index]?.startDate}
              value={formik.values.experience[index]?.startDate}
              handleChange={handleInputChange}
              label="Start Date"
              name={`experience[${index}].startDate`}
              width="100%"
              backgroundColor={hrStyle?.inputBackground}
              titleColor={hrStyle?.inputLabelColor}
            />
            <div className="CVProfExp-Checkbox" style={{ color: hrStyle?.inputLabelColor }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={() => {
                      onCurrentProjectCheckBoxClick(index);
                    }}
                    sx={{
                      color: hrStyle?.inputLabelColor ? hrStyle?.inputLabelColor : "#00B189",
                      "&.Mui-checked": {
                        color: hrStyle?.inputLabelColor ? hrStyle?.inputLabelColor : "#00B189",
                      },
                    }}
                    name={`experience[${index}].actualProject`}
                    checked={formik.values.experience[index]?.actualProject}
                  />
                }
                label="Current Project"
              />
            </div>
            {!isActual && (
              <InputDate
                error={formik.errors.experience && formik.errors.experience[index]?.endDate}
                value={formik.values.experience[index]?.endDate}
                handleChange={handleInputChange}
                label="End Date"
                name={`experience[${index}].endDate`}
                disabled={formik.values.experience[index]?.actualProject}
                width="100%"
                backgroundColor={hrStyle?.inputBackground}
                titleColor={hrStyle?.inputLabelColor}
              />
            )}
            {isActual && (
              <Input
                error={undefined}
                value={"Present"}
                handleChange={() => {}}
                handleBlur={() => {}}
                label="End Date"
                type="text"
                width="100%"
                height="2rem"
                name={"Present"}
                disabled={true}
                backgroundColor={hrStyle?.inputBackground}
                titleColor={hrStyle?.inputLabelColor}
              />
            )}
            <Input
              error={formik.errors.experience && formik.errors.experience[index]?.companyName}
              value={formik.values.experience[index]?.companyName}
              handleChange={handleInputChange}
              handleBlur={(e) => formikTrimStrings(formik, e)}
              label="Company Name"
              name={`experience[${index}].companyName`}
              type="text"
              width="50%"
              height="2rem"
              backgroundColor={hrStyle?.inputBackground}
              titleColor={hrStyle?.inputLabelColor}
            />
            <Input
              error={formik.errors.experience && formik.errors.experience[index]?.professionalTitle}
              value={formik.values.experience[index]?.professionalTitle}
              handleChange={handleInputChange}
              handleBlur={(e) => formikTrimStrings(formik, e)}
              label="Professional Title"
              name={`experience[${index}].professionalTitle`}
              type="text"
              width="50%"
              height="2rem"
              backgroundColor={hrStyle?.inputBackground}
              titleColor={hrStyle?.inputLabelColor}
            />
          </Box>
          <div className="CVProfExp-2ndRow">
            <Box display="flex">
              <Input
                error={formik.errors.experience && formik.errors.experience[index]?.description}
                value={formik.values.experience[index]?.description}
                handleChange={handleInputChange}
                handleBlur={(e) => formikTrimStrings(formik, e)}
                placeholder="Describe the project you were involved with."
                label="Description"
                name={`experience[${index}].description`}
                type="text"
                multiline
                maxRows={6}
                width="100%"
                height="9em"
                backgroundColor={hrStyle?.inputBackground}
                titleColor={hrStyle?.inputLabelColor}
              />
            </Box>
            <Box display="flex">
              <Input
                error={
                  formik.errors.experience && formik.errors.experience[index]?.technologiesUsed
                }
                value={formik.values.experience[index]?.technologiesUsed}
                handleChange={handleInputChange}
                handleBlur={(e) => formikTrimStrings(formik, e)}
                placeholder="Describe the technologies involved in this project."
                label="Technologies Used"
                name={`experience[${index}].technologiesUsed`}
                type="text"
                multiline
                maxRows={6}
                width="100%"
                height="9em"
                backgroundColor={hrStyle?.inputBackground}
                titleColor={hrStyle?.inputLabelColor}
              />
            </Box>
          </div>
        </div>
        <div className="CVProfExp-deleteButton CV-deleteButton">
          <Tooltip title="Delete Professional Experience" onClick={() => handleDelete(index)}>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </CollapseExpand>
  );
};
