import React, { FC, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Approver } from "../../../../types/hr/Approver";
import { ProjectTeamMember } from "../../../../types/hr/ProjectTeamMember";

interface InputAutocompleteProps {
  id: string;
  label: string;
  noPadding?: boolean;
  onChange: (event: any, value: any) => void;
  value: Approver | ProjectTeamMember;
  options: readonly Approver[] | readonly ProjectTeamMember[];
  loading: boolean;
  isEqualToValue: (option: any, value: any) => boolean;
  getOptionLabel: (option: any) => string;
}

export const InputAutocomplete: FC<InputAutocompleteProps> = ({
  id,
  label,
  noPadding,
  onChange,
  value,
  options,
  loading,
  isEqualToValue,
  getOptionLabel,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        padding: noPadding ? "0" : "1em",
        width: "20%",
        height: "auto",
      }}
    >
      {label && (
        <Typography variant="body1" sx={{ color: "inherit" }}>
          {label}
        </Typography>
      )}
      <Autocomplete<Approver | ProjectTeamMember>
        id={id}
        sx={{ width: 300, backgroundColor: "white", borderRadius: "32px" }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onChange={onChange}
        onClose={(event) => {
          setOpen(false);
        }}
        value={value}
        isOptionEqualToValue={isEqualToValue}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search Name"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};
