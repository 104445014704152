import { Formik, useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { CancelIcon } from "../../../../../assets/svg/CancelIcon";
import { SaveIcon } from "../../../../../assets/svg/SaveIcon";
import { setEdited } from "../../../../../stores/mkt/Career.store";
import { Training } from "../../../../../types/internal/Training";
import { ImageInputSquare } from "../../../../ImageInputs/ImageInputSquare/ImageInputSquare";
import { Input } from "../../../../Inputs/Input";
import {
  CERT_FORMIK_INIT_VALUES_FROM_CERT,
  CERT_FORMIK_VALIDATION,
  CERT_FORM_CONFIG,
  EMPTY_CERT_FORMIK_INIT_VALUES,
  FormConfig,
} from "./TrainingForm.consts";
import "./TrainingForm.css";

interface TrainingFormProps {
  training?: Training;
  onCreate: boolean;
  onCancel: (event: any) => void;
  onSave: (event: any, training: Training) => void;
}

const TrainingFormCreate: FC<TrainingFormProps> = ({ training, onCancel, onSave }) => {
  const [cert, setCert] = useState({ ...CERT_FORMIK_INIT_VALUES_FROM_CERT() });

  const formik = useFormik({
    initialValues: CERT_FORMIK_INIT_VALUES_FROM_CERT(),
    onSubmit: () => {},
    validationSchema: CERT_FORMIK_VALIDATION,
  });

  useEffect(() => {
    setCert({ ...CERT_FORMIK_INIT_VALUES_FROM_CERT() });
    formik.setValues({ ...CERT_FORMIK_INIT_VALUES_FROM_CERT() });
  }, [training]);

  const onChangeInput = (event: any) => {
    setCert((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleFormikChange = (event: any) => {
    formik.handleChange(event);
    onChangeInput(event);
  };

  const saving = (event: any) => {
    const newtraining: Training = {
      id: training ? training.id : "",
      ...formik.values,
    };

    onSave(event, newtraining);
  };

  const renderHeader = () => (
    <div className="Training-header-container">
      <p className="Training-Details-Create-Header">Create Training</p>
      <div className="Training-buttons-container">
        <div className="Training-Details-cancel" onClick={onCancel}>
          <CancelIcon />
        </div>
        <div className="Training-Details-cancel" onClick={saving}>
          <SaveIcon />
        </div>
      </div>
    </div>
  );

  const renderForm = () => (
    <>
      <form className="forms">
        <Input
          name={CERT_FORM_CONFIG[0].name}
          error={formik.errors.description}
          label={CERT_FORM_CONFIG[0].label}
          type="text"
          width="50%"
          noPadding
          value={formik.values.description}
          handleChange={handleFormikChange}
          multiline={false}
          hr={true}
          cert={true}
          errorColor={"#E94040"}
        />
        <Input
          name={CERT_FORM_CONFIG[1].name}
          error={formik.errors.year}
          label={CERT_FORM_CONFIG[1].label}
          type="text"
          width="15%"
          noPadding
          value={formik.values.year}
          handleChange={handleFormikChange}
          multiline={false}
          hr={true}
          cert={true}
          errorColor={"#E94040"}
        />
        <Input
          name={CERT_FORM_CONFIG[2].name}
          error={formik.errors.hours}
          label={CERT_FORM_CONFIG[2].label}
          type="text"
          width="15%"
          noPadding
          value={formik.values.hours.toString()}
          handleChange={handleFormikChange}
          multiline={false}
          hr={true}
          cert={true}
          errorColor={"#E94040"}
        />
      </form>
      <div className="cert-label">{CERT_FORM_CONFIG[3].label}</div>
      <ImageInputSquare
        title="Image"
        formik={formik}
        buttonTitle="Upload Certificate"
        name="attachment"
        image={formik.values.attachment}
        error={formik.errors.attachment}
        target="image"
        folder="Certifications"
        height="50%"
        width="23%"
        acceptType={null}
        backgroundColor="#1F2632"
        backgroundColorHover="#203353"
        border="2px solid #676CDB"
        borderRadius="15px"
        colorContent="white"
        closeIconBackgroundColor="#3AAFC9"
        cert={true}
      />
    </>
  );

  return (
    <div className="Training-container">
      {renderHeader()}
      {renderForm()}
    </div>
  );
};

const TrainingFormEdit: FC<TrainingFormProps> = ({ training, onCancel, onSave }) => {
  const [cert, setCert] = useState({ ...training });

  const formik = useFormik({
    initialValues: CERT_FORMIK_INIT_VALUES_FROM_CERT(),
    onSubmit: () => {},
    validationSchema: CERT_FORMIK_VALIDATION,
  });

  useEffect(() => {
    setCert({ ...training });

    const updateForm = () => ({
      description: training ? training.description : "",
      year: training ? training.year : "",
      hours: training ? training.hours : 0,
      attachment: training ? training.attachment : "",
    });

    formik.setValues({ ...updateForm() });
  }, [training]);

  const onChangeInput = (event: any) => {
    setCert((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const saving = (event: any) => {
    const newtraining: Training = {
      id: training ? training.id : "",
      ...formik.values,
    };

    onSave(event, newtraining);
  };
  const handleFormikChange = (event: any) => {
    formik.handleChange(event);
    onChangeInput(event);
  };

  const renderHeader = () => (
    <div className="Training-header-container">
      <p className="Training-Details-Create-Header">Update Training</p>
      <div className="Training-buttons-container">
        <div className="Training-Details-cancel" onClick={onCancel}>
          <CancelIcon />
        </div>
        <div className="Training-Details-cancel" onClick={saving}>
          <SaveIcon />
        </div>
      </div>
    </div>
  );

  const renderForm = () => (
    <>
      <form className="forms">
        <Input
          name={CERT_FORM_CONFIG[0].name}
          error={formik.errors.description}
          label={CERT_FORM_CONFIG[0].label}
          type="text"
          width="50%"
          noPadding
          value={formik.values.description}
          handleChange={handleFormikChange}
          multiline={false}
          hr={true}
          cert={true}
          errorColor={"#E94040"}
        />
        <Input
          name={CERT_FORM_CONFIG[1].name}
          error={formik.errors.year}
          label={CERT_FORM_CONFIG[1].label}
          type="text"
          width="15%"
          noPadding
          value={formik.values.year}
          handleChange={handleFormikChange}
          multiline={false}
          hr={true}
          cert={true}
          errorColor={"#E94040"}
        />
        <Input
          name={CERT_FORM_CONFIG[2].name}
          error={formik.errors.hours}
          label={CERT_FORM_CONFIG[2].label}
          type="text"
          width="15%"
          noPadding
          value={formik.values.hours.toString()}
          handleChange={handleFormikChange}
          multiline={false}
          hr={true}
          cert={true}
          errorColor={"#E94040"}
        />
      </form>
      <div className="cert-label">{CERT_FORM_CONFIG[3].label}</div>
      <ImageInputSquare
        title="Image"
        formik={formik}
        buttonTitle="Upload Certificate"
        name="attachment"
        image={formik.values.attachment}
        error={formik.errors.attachment}
        target="image"
        folder="Certifications"
        height="50%"
        width="23%"
        acceptType={null}
        backgroundColor="#1F2632"
        backgroundColorHover="#203353"
        border="2px solid #676CDB"
        borderRadius="15px"
        colorContent="white"
        closeIconBackgroundColor="#3AAFC9"
        cert={true}
      />
    </>
  );

  return (
    <div className="Training-container">
      {renderHeader()}
      {renderForm()}
    </div>
  );
};
export const TrainingForm: FC<TrainingFormProps> = ({ training, onCreate, onCancel, onSave }) => {
  return (
    <>
      {onCreate ? (
        <TrainingFormCreate
          training={training}
          onCreate={onCreate}
          onCancel={onCancel}
          onSave={onSave}
        />
      ) : (
        <TrainingFormEdit
          training={training}
          onCreate={onCreate}
          onCancel={onCancel}
          onSave={onSave}
        />
      )}
    </>
  );
};
