import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HRPage } from "../../../../components/hr/Global/HRPage/HRPage";
import { ProjectDetailsComponent } from "../../../../components/hr/projects/ProjectDetails/Component/ProjectDetailsComponent";
import {
  getProject,
  updateApproversTouched,
  updateProject,
  updateTeamMembersTouched,
} from "../../../../stores/hr/Project.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { PageContentMainComponent } from "../../../../types/hr/PageContent";
import { Project } from "../../../../types/hr/Project";
import { isHttpCodeValid } from "../../../../utils/common/http";
import { projectValidationSchema } from "../../../../utils/hr/project.utils";

type UpdateProjectResponse = {
  code: number;
  response: Project;
};

export const ProjectDetailsPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { id } = useParams();
  const { project } = useTypedSelector((state: RootState) => state.projects);
  const [activeTab, setActiveTab] = useState<number>(0);
  var requestsWereTriggered = false;

  const fetchProject = async () => {
    await dispatch(getProject(id ?? ""));
  };

  useEffect(() => {
    if (!requestsWereTriggered) {
      dispatch(updateApproversTouched(true));
      dispatch(updateTeamMembersTouched(true));
      fetchProject();
    }
    requestsWereTriggered = !requestsWereTriggered;
  }, []);

  const switchEditMode = () => {
    setPageContent((prev) => ({
      ...prev,
      inEditMode: !prev.inEditMode,
    }));
  };

  const submitProject = async (values: Project) => {
    const { payload } = await dispatch(
      updateProject({
        ...values,
        approvers: project.approvers,
        teamMembers: formik.values.teamMembers,
      })
    );
    if (isHttpCodeValid((payload as UpdateProjectResponse).code)) {
      switchEditMode();
      dispatch(updateApproversTouched(false));
      dispatch(updateTeamMembersTouched(false));
      formik.setValues(project);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: project,
    onSubmit: submitProject,
    validationSchema: projectValidationSchema,
  });

  const [pageContent, setPageContent] = useState<PageContentMainComponent>({
    tabs: ["Basic Information", "Team Members"],
    switchTabAction: (number) => setActiveTab(number),
    inEditMode: false,
    submitForm: formik.submitForm,
    switchMode: switchEditMode,
  });

  useEffect(() => {
    if (!requestsWereTriggered) {
      dispatch(updateApproversTouched(true));
      dispatch(updateTeamMembersTouched(true));
      fetchProject();
    }
    requestsWereTriggered = !requestsWereTriggered;
  }, [pageContent]);

  return (
    <HRPage pageContent={pageContent} sidebar={true}>
      <ProjectDetailsComponent
        inEditMode={pageContent.inEditMode ?? false}
        activeTab={activeTab}
        formik={formik}
      />
    </HRPage>
  );
};
