import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveIcon } from "../../../../assets/svg/ActiveIcon";
import { InactiveIcon } from "../../../../assets/svg/InactiveIcon";
import { EditIcon } from "../../../../assets/svg/EditIcon";
import { FORMIK_VALIDATION, INFO_CONFIG, INITIAL_EMPLOYEE } from "./EmployeeDetailsConfig";
import { useFormik } from "formik";
import { HREmployee } from "../../../../types/hr/HREmployee";
import { Input } from "../../../Inputs/Input";
import "./EmployeeInfo.css";
import { CancelIcon } from "../../../../assets/svg/CancelIcon";
import { SaveIcon } from "../../../../assets/svg/SaveIcon";
import HREmployeeService from "../../../../services/hr/HREmployee.service";
import { RootState, useTypedSelector } from "../../../../stores/store";
import { isHttpCodeValid } from "../../../../utils/common/http";
import { InputDate } from "../../../Inputs/InputDate";
import { Loading } from "../../../Loading/loading";
import { ActiveLabel } from "../../../../assets/svg/ActiveLabel";
import { InactiveLabel } from "../../../../assets/svg/InactiveLabel";
import { Button, ButtonTypes } from "../../../buttons/button/Button";
import { TextButton, TextButtonTypes } from "../../../TextButton/TextButton";
import { dateToYYYMMDD } from "../../../../utils/date";

interface EmployeeDetailsFieldProps {
  name: string;
  active: boolean;
  dateOfBirth: string;
  kodlyOfBirth: string;
  costs: number;
  employee: HREmployee;
  onShowModal: () => void;
  onInactivate: () => void;
  onActivate: () => void;
}

export const EmployeeDetailsField: FC<EmployeeDetailsFieldProps> = ({
  name,
  active,
  dateOfBirth,
  kodlyOfBirth,
  costs,
  employee,
  onShowModal,
  onActivate,
  onInactivate,
}) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [usedEmployee, setUsedEmployee] = useState({ ...employee });
  const { token, email } = useTypedSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const formik = useFormik({
    initialValues: { ...INITIAL_EMPLOYEE(usedEmployee) },
    onSubmit: () => {},
    validationSchema: FORMIK_VALIDATION,
  });

  useEffect(() => {
    setUsedEmployee({ ...employee });
    formik.setValues({ ...INITIAL_EMPLOYEE(employee) }, false);
  }, [employee]);

  const httpError = "Error during submission. Please try again later!";

  const onChangeInput = (event: any) => {
    setUsedEmployee((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleFormikChange = (event: any) => {
    formik.handleChange(event);
    onChangeInput(event);
  };

  const onClickEdit = (event: any) => {
    event.preventDefault();
    setEditMode((prev) => {
      return !prev;
    });
  };

  const onClickSaveEdit = async (event: any) => {
    event.preventDefault();
    setError(false);
    if (!formik.isValid || !token) return;
    setLoading(true);
    const res = await HREmployeeService.amendEmployee(employee.id, usedEmployee, token);
    if (isHttpCodeValid(res.code)) {
      setUsedEmployee(isHttpCodeValid(res.code) ? res.user : employee);
      formik.setValues({ ...res.user });
      setEditMode(false);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const onClickCancelEdit = (event: any) => {
    event.preventDefault();
    setUsedEmployee({ ...employee });
    setError(false);
    setEditMode(false);
  };

  const renderHeader = () => (
    <div className="EmployeeInfo">
      <div className="EmployeeInfo-name">{name}</div>
      {active ? (
        <>
          <div className="EmployeeInfo-cursor-pointer" onClick={onInactivate}>
            <ActiveLabel />
          </div>
          <div className="EmployeeInfo-cursor-pointer" onClick={onInactivate}>
            <ActiveIcon />
          </div>
          <div className="EmployeeInfo-cursor-pointer" onClick={onClickEdit}>
            <EditIcon />
          </div>
        </>
      ) : (
        <>
          <div className="EmployeeInfo-cursor-pointer" onClick={onActivate}>
            <InactiveLabel />
          </div>
          <div className="EmployeeInfo-cursor-pointer" onClick={onActivate}>
            <InactiveIcon />
          </div>
        </>
      )}
    </div>
  );

  const renderOnEditHeader = () => (
    <div className="EmployeeInfo">
      <div className="EmployeeInfo-name">{name}</div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="EmployeeInfo-edit" onClick={onClickCancelEdit}>
            <CancelIcon />
          </div>
          <div className="EmployeeInfo-edit" onClick={onClickSaveEdit}>
            <SaveIcon />
          </div>
          <div className="EmployeeInfo-error">{error ? httpError : null}</div>
        </>
      )}
    </div>
  );

  const renderEmployeeInfo = (title: string, field: any) => (
    <div className="EmployeeInfo-data">
      <div className="EmployeeInfo-data-firstRow">{title} </div>
      {
        <div className="EmployeeInfo-data-secondRow">
          {title === INFO_CONFIG.dailyCost.label
            ? field
              ? field + " EUR"
              : "N/A"
            : field
            ? field
            : "N/A"}
        </div>
      }
    </div>
  );
  const allEmployeeInfo = () => (
    <>
      <div className="EmployeeInfo-container">
        {renderHeader()}
        <br />
        <br />
        <div className="EmployeeDetailInfo-container">
          <div className="EmployeeInfo-content">
            {renderEmployeeInfo(INFO_CONFIG.dob.label, dateToYYYMMDD(new Date(usedEmployee.dob)))}
            <br />
            <div className="EmployeeInfo-vacations">
              {renderEmployeeInfo(INFO_CONFIG.vacations.assignedDays.label, 0)}
            </div>
          </div>
          <br />
          <div className="EmployeeInfo-content">
            {renderEmployeeInfo(INFO_CONFIG.kob.label, dateToYYYMMDD(new Date(usedEmployee.kob)))}
            <br />
            {renderEmployeeInfo(INFO_CONFIG.dailyCost.label, usedEmployee.dailyCost)}
          </div>
        </div>
      </div>
    </>
  );

  const [value, updateValue] = useState(new Date());

  const onChange = (date: React.SetStateAction<Date>) => {
    updateValue(date);
  };

  const editingAllEmployeeInfo = () => (
    <div className="EmployeeInfo-container">
      {renderOnEditHeader()}
      <br />
      <br />
      <div className="EmployeeDetailInfo-container">
        <div className="EmployeeInfo-content">
          <div className="EmployeeInfo-data-firstRow">{INFO_CONFIG.dob.label} </div>
          <InputDate
            error={formik.errors.dob}
            value={formik.values.dob}
            handleChange={handleFormikChange}
            name={INFO_CONFIG.dob.name}
            width="70%"
            hr={true}
          />
          <br />
          <div className="EmployeeInfo-data-firstRow">
            {INFO_CONFIG.vacations.assignedDays.label}{" "}
          </div>
          <Input
            name={INFO_CONFIG.vacations.assignedDays.name}
            type="number"
            value={"0"}
            handleChange={handleFormikChange}
            disabled={!editMode}
            error={""}
            width="70%"
            height="100%"
            hr={true}
          />
        </div>
        <br />
        <div className="EmployeeInfo-content">
          <div className="EmployeeInfo-data-firstRow">{INFO_CONFIG.kob.label} </div>
          <InputDate
            error={formik.errors.kob}
            value={formik.values.kob}
            handleChange={handleFormikChange}
            name={INFO_CONFIG.kob.name}
            width="70%"
            hr={true}
          />
          <br />
          <div className="EmployeeInfo-data-firstRow">{INFO_CONFIG.dailyCost.label} </div>
          <Input
            name={INFO_CONFIG.dailyCost.name}
            type="number"
            value={formik.values.dailyCost?.toString()}
            handleChange={handleFormikChange}
            disabled={!editMode}
            error={formik.errors.dailyCost}
            width="70%"
            height="100%"
            hr={true}
          />
        </div>
      </div>
    </div>
  );

  return !editMode ? allEmployeeInfo() : editingAllEmployeeInfo();
};
