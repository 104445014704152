import { Box, Button, Typography, Tooltip, IconButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { FC, ReactElement } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { TopBar } from "../../../TopBar/TopBar";
import { PageDetailsContent } from "../../../../types/mkt/PageContent";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { useNavigate } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import { KodlyIcon } from "../../../../assets/svg/KodlyIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { renderHtml } from "../../../../utils/renderHtml";
import "./Page.css";

interface PageDetailsProps {
  children: ReactElement | null;
  pageContent: PageDetailsContent;
}

export const PageDetails: FC<PageDetailsProps> = observer(({ children, pageContent }) => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  return (
    <Box display="flex" sx={{ height: "100vh" }}>
      <Box sx={{ height: "100vh", width: open ? "225px" : "75px" }}>
        <div className="Page-kodlyIcon">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "flex-start",
              px: 2,
            }}
          >
            {open ? (
              <img
                className="Page-kodlyIconImg"
                src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/kodly.png"
                alt="Logo"
                width="175px"
                onClick={() => navigate("/")}
              />
            ) : (
              <KodlyIcon />
            )}
          </ListItemIcon>
        </div>
        <Sidebar />
      </Box>
      <Box
        sx={{
          width: open ? "calc(100% - 225px)" : "calc(100% - 75px)",
          backgroundColor: "#F5F5F5",
        }}
      >
        <TopBar>
          <Box alignItems="center" sx={{ flexGrow: "1", display: { xs: "flex" } }}>
            <img
              src="https://kodly-assets.s3.eu-west-1.amazonaws.com/global/black_arrow.svg"
              className="backArrow"
              onClick={() => navigate(pageContent.previousPage)}
            />
            {/* <h2
              className="Page-title"
              style={{ color: "#00B189", fontWeight: "700", marginRight: "1em" }}
              dangerouslySetInnerHTML={renderHtml(pageContent.pageTitle)}
            ></h2> */}
          </Box>

          <Box display="flex">
            <Tooltip title="Delete" color="error" sx={{ marginRight: "0.5em" }}>
              <IconButton onClick={() => dispatch(pageContent.deleteAction)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit" sx={{ color: "#00b088", marginRight: "0.5em" }}>
              <IconButton onClick={() => dispatch(pageContent.editAction)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={pageContent.isPublish ? "Unpublish" : "Publish"}
              sx={{ color: "#00b088", marginRight: "3em" }}
            >
              <IconButton onClick={() => dispatch(pageContent.publishAction)}>
                {pageContent.isPublish ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </TopBar>
        <Box
          sx={{
            padding: "2em 4em",
            height: "calc(100vh - (92px + 4em))",
            overflowY: "auto",
            boxSizing: "content-box",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
});
