import React, { FC } from "react";

export const InactiveIcon: FC = () => {
  return (
    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.1 25C2.1 25 0 25 0 22.9167C0 20.8333 2.1 14.5833 10.5 14.5833C18.9 14.5833 21 20.8333 21 22.9167C21 25 18.9 25 18.9 25H2.1ZM10.5 12.5C12.1709 12.5 13.7733 11.8415 14.9548 10.6694C16.1363 9.49731 16.8 7.9076 16.8 6.25C16.8 4.5924 16.1363 3.00269 14.9548 1.83058C13.7733 0.65848 12.1709 0 10.5 0C8.82914 0 7.22671 0.65848 6.04523 1.83058C4.86375 3.00269 4.2 4.5924 4.2 6.25C4.2 7.9076 4.86375 9.49731 6.04523 10.6694C7.22671 11.8415 8.82914 12.5 10.5 12.5V12.5Z"
        fill="white"
      />
      <circle cx="22" cy="20" r="5" fill="white" />
      <path
        d="M22 15C19.2384 15 17 17.2384 17 20C17 22.7616 19.2384 25 22 25C24.7616 25 27 22.7616 27 20C27 17.2384 24.7616 15 22 15ZM24.6 20.4H22.4V22.6C22.4 22.7061 22.3579 22.8078 22.2828 22.8828C22.2078 22.9579 22.1061 23 22 23C21.8939 23 21.7922 22.9579 21.7172 22.8828C21.6421 22.8078 21.6 22.7061 21.6 22.6V20.4H19.4C19.2939 20.4 19.1922 20.3579 19.1172 20.2828C19.0421 20.2078 19 20.1061 19 20C19 19.8939 19.0421 19.7922 19.1172 19.7172C19.1922 19.6421 19.2939 19.6 19.4 19.6H21.6V17.4C21.6 17.2939 21.6421 17.1922 21.7172 17.1172C21.7922 17.0421 21.8939 17 22 17C22.1061 17 22.2078 17.0421 22.2828 17.1172C22.3579 17.1922 22.4 17.2939 22.4 17.4V19.6H24.6C24.7061 19.6 24.8078 19.6421 24.8828 19.7172C24.9579 19.7922 25 19.8939 25 20C25 20.1061 24.9579 20.2078 24.8828 20.2828C24.8078 20.3579 24.7061 20.4 24.6 20.4Z"
        fill="#00A707"
      />
    </svg>
  );
};
