export const REGEX = {
  SINGLE_WORD_LATIN_CHARS: /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*)$/gi,
  MULTIPLE_WORDS_LATIN_CHARS: /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
  MULTIPLE_WORDS_NO_LATIN_CHARS: /^[A-Za-z ]+$/,
  TEXT_WITH_NUMBERS_NO_LATIN_CHARS:
    /^[^àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]*$/,
  NO_FULL_WHITE_SPACE: /^(?!\s+$)/,
  IMAGE_URL: /=s(\d+)-c/,
  NUMBERS: /^[0-9]+$/,
  ALPHANUMERIC: /^[a-zA-Z0-9 ]+$/,
  ALPHA_UPPERCASE: /^[A-Z]+$/,
  ALPHABET: /^[A-Za-z]+$/,
  ALPHABET_SPACE: /^[A-Za-z ]+$/,
  NUMBERS_TWO_DECIMAL_PLACES_TEXT: /^[0-9]+(\.[0-9]{1,2})?$/,
  EMAIL:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ONLY_NUMBERS: /^[0-9]*$/,
};
