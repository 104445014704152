import React, { FC, useEffect, useState } from "react";
import { useTypedSelector, RootState, useTypedDispatch } from "../../../../../stores/store";
import { Box, FormHelperText } from "@mui/material";
import { InputAutocomplete } from "../../../Global/input-autocomplete/input-autocomplete";
import { ProjectTeamMember } from "../../../../../types/hr/ProjectTeamMember";
import {
  addTeamMember,
  getTeamMembers,
  removeChosenTeamMember,
  updateTeamMembersTouched,
} from "../../../../../stores/hr/Project.store";
import { emptyProjectTeamMember } from "../../../../../data/hr/project";
import { TeamMembersTableRow } from "./TableRow/TeamMembersTableRow";
import { FormikValues } from "formik";
import "./TeamMembers.css";

interface TeamMembersProps {
  inEditMode: boolean;
  formik: FormikValues;
}

export const TeamMembers: FC<TeamMembersProps> = ({ inEditMode, formik }) => {
  const dispatch = useTypedDispatch();
  const { project, loadingTeamMembers, teamMembers, teamMembersTouched } = useTypedSelector(
    (state: RootState) => state.projects
  );
  const [selectedMember, setSelectedMember] = useState<ProjectTeamMember | null>(null);
  let formikTeamMembers = [...formik.values.teamMembers];

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    await dispatch(getTeamMembers());
  };

  const memberIsEqual = (option: ProjectTeamMember, value: ProjectTeamMember) => {
    return option.id === value.id;
  };

  const memberGetOption = (option: ProjectTeamMember) => {
    return option.name;
  };

  const memberOnChange = (_: any, value: ProjectTeamMember | null) => {
    setSelectedMember(value);
  };

  const onAddTeamMember = () => {
    const memberToAdd = selectedMember;
    if (!isSelectedTeamMemberValid(memberToAdd)) {
      return;
    }
    formikTeamMembers = [...formik.values.teamMembers, memberToAdd];
    formik.setValues({
      ...formik.values,
      teamMembers: formikTeamMembers,
    });
  };

  const isSelectedTeamMemberValid = (memberToCheck: ProjectTeamMember | null) => {
    if (memberToCheck == null) {
      return false;
    }
    return (
      formik.values.teamMembers &&
      formik.values.teamMembers.find(
        (member: ProjectTeamMember) => member.id === memberToCheck.id
      ) == undefined
    );
  };

  const onDeleteTeamMember = (index: number) => {
    let aux = [...formik.values.teamMembers];
    aux.splice(index, 1);
    formik.setFieldValue("teamMembers", aux);
    formikTeamMembers = aux;
  };

  return (
    <Box className="project-team-members-container">
      <Box className="project-team-members-top-section">
        <div className="project-team-members-top-section-button-align">
          <Box
            sx={{
              width: "auto",
              height: "auto",
            }}
          >
            <InputAutocomplete
              id="team-members-project"
              options={teamMembers ? [...teamMembers, emptyProjectTeamMember] : []}
              loading={loadingTeamMembers}
              value={selectedMember == null ? emptyProjectTeamMember : selectedMember}
              onChange={memberOnChange}
              noPadding
              label="Name"
              isEqualToValue={memberIsEqual}
              getOptionLabel={memberGetOption}
            />
            {teamMembersTouched && !project.teamMembers.length && (
              <FormHelperText
                style={{
                  color: "#E94040",
                  marginLeft: "1rem",
                }}
              >
                It is required to have at least one Team Member
              </FormHelperText>
            )}
          </Box>
        </div>
        <div className="project-team-members-top-section-button-align">
          <button
            onClick={onAddTeamMember}
            className={
              isSelectedTeamMemberValid(selectedMember)
                ? "project-team-members-top-section-button"
                : "project-team-members-top-section-button-disabled"
            }
            disabled={!isSelectedTeamMemberValid(selectedMember)}
          >
            Add Member
          </button>
        </div>
      </Box>
      {formik.values.teamMembers?.length !== 0 ? (
        <Box className="project-team-members-bottom-section">
          <div className="project-team-members-table-row">
            <div className="project-team-table-column-header">Name</div>
            <div className="project-team-table-column-header">Role</div>
            <div className="project-team-table-column-header">Daily Cost</div>
            <div className="project-team-table-column-header">Type</div>
            <div className="project-team-table-column-header">Rate</div>
            {inEditMode && <div className="project-team-table-column-header-empty">Exclude</div>}
            <div className="project-team-table-column-header-empty"></div>
          </div>
          {formik.values.teamMembers?.map((member: ProjectTeamMember, index: number) => (
            <div key={`project-table-item-${index}`}>
              <TeamMembersTableRow
                index={index}
                formik={formik}
                inEditMode={inEditMode}
                member={member}
                currency={project.currency}
                onDeleteTeamMember={onDeleteTeamMember}
              />
            </div>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};
