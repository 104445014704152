import React, { FC, useEffect, useState } from "react";
import "./TitleValueSquareBox.css";

interface TitleValueSquareBoxProps {
  title: string;
  value: string;
  titleBackgroundColor: string;
  valueBackgroundColor: string;
}

export const TitleValueSquareBox: FC<TitleValueSquareBoxProps> = ({
  title,
  value,
  titleBackgroundColor,
  valueBackgroundColor,
}) => {
  return (
    <div className="TitleValueSquareBox-container">
      <div className="TitleValueSquareBox-value" style={{ backgroundColor: valueBackgroundColor }}>
        {value}
      </div>
      <div className="TitleValueSquareBox-title" style={{ backgroundColor: titleBackgroundColor }}>
        {title}
      </div>
    </div>
  );
};
