import React, { FC } from "react";
import ReactPDF, { Page, Text, View, Document, StyleSheet, Font, Image } from "@react-pdf/renderer";
import FontRobotoRegular from "../../assets/fonts/Roboto-Regular.ttf";
import FontRobotoBold from "../../assets/fonts/Roboto-Black.ttf";
import { CV } from "../../types/internal/cv/CV";
import { Technology } from "../../types/internal/cv/Technology";
import { ProfessionalExperience } from "../../types/internal/cv/ProfessionalExperience";
import { Language } from "../../types/internal/cv/Language";
import { EducationCertification } from "../../types/internal/cv/EducationCertification";
import { dateToMMMYY } from "../../utils/date";

interface CVPDFProps {
  cv: CV;
  anonymous?: Boolean;
}

export const CVPDF: FC<CVPDFProps> = ({ cv, anonymous }) => {
  const getAnonymousTitle = () => {
    const nameInits = cv.personalInfo.firstName[0] + cv.personalInfo.lastName[0];
    const year = new Date().getFullYear().toString().slice(-2);
    const roleInits = cv.personalInfo.professionalTitle
      .split(" ")
      .reduce((acc, word) => acc + word[0], "");
    return `${nameInits}${year}_${roleInits}`;
  };

  const Header = () => (
    <>
      <View
        fixed
        render={({ pageNumber }) => pageNumber > 1 && <View style={{ height: LARGE_SPACE }} />}
      />
      <View style={styles.headerContainer}>
        <Image
          src={"https://kodly-assets.s3.eu-west-1.amazonaws.com/backoffice/kodly-logo-white.png"}
          style={styles.headerImage}
        />
        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            {anonymous
              ? getAnonymousTitle()
              : `${cv.personalInfo.firstName} ${cv.personalInfo.lastName}`}
          </Text>
          {!anonymous && <Text style={styles.email}>{cv.email}</Text>}
        </View>
      </View>
    </>
  );

  const Body = () => (
    <View style={styles.body}>
      <PersonalInfo />
      <Skills />
      <WorkExperience />
      <EducationAndCertificates />
      <Languages />
      <Interests />
    </View>
  );

  const PersonalInfo = () => (
    <>
      <Text style={styles.jobTitleContainer}>{cv.personalInfo.professionalTitle}</Text>
      <View style={styles.sectionContainer}>
        <Text style={styles.label}>PERSONAL INFO</Text>
        <Text style={styles.personalInfoText}>{cv.personalInfo.summary}</Text>
      </View>
    </>
  );

  const Skills = () => (
    <>
      {cv.techStack.length > 0 && (
        <View style={styles.sectionContainer}>
          <Text style={styles.label}>SKILLS</Text>
          <View style={styles.skillsList}>
            {cv.techStack.map((skill) => (
              <TechItem key={skill.technologyArea} {...skill} />
            ))}
          </View>
        </View>
      )}
    </>
  );

  const TechItem: FC<Technology> = ({ technologyArea, technologies }) => (
    <View style={styles.skillContainer}>
      <Text style={styles.skillLabel}>{technologyArea}</Text>
      <Text style={styles.skillValue}>{technologies}</Text>
    </View>
  );

  const WorkExperience = () => (
    <>
      {cv.experience.length > 0 && (
        <View style={styles.sectionContainer}>
          <Text style={styles.label}>WORK EXPERIENCE</Text>
          <View style={styles.workListParent}>
            {cv.experience.map((exp: ProfessionalExperience) => (
              <WorkExperienceItem key={exp.startDate} {...exp} />
            ))}
          </View>
        </View>
      )}
    </>
  );

  const WorkExperienceItem: FC<ProfessionalExperience> = ({
    startDate,
    actualProject,
    endDate,
    companyName,
    professionalTitle,
    description,
    technologiesUsed,
  }) => (
    <View style={styles.workList}>
      <View style={styles.timelineLeftContainer}>
        <CircleAndLineDecorator />
        <View style={styles.workHeader}>
          <Text style={styles.workDate}>
            {dateToMMMYY(startDate!) + " - " + (endDate ? dateToMMMYY(endDate!) : "Present")}
          </Text>
          <Text style={styles.workCompany}>{companyName}</Text>
        </View>
      </View>
      <View style={styles.workBody}>
        <Text style={styles.roleTitle}>{professionalTitle}</Text>
        <Text style={styles.roleBody}>{description}</Text>
        <Text style={[styles.roleTitle, { marginTop: BASE_SPACE }]}>Technologies Used</Text>
        <Text style={styles.roleBody}>{technologiesUsed}</Text>
        <View style={{ height: LARGE_SPACE }} />
      </View>
    </View>
  );

  const EducationAndCertificates = () => (
    <>
      {cv.certifications.length > 0 && (
        <View style={styles.educationContainer}>
          <Text style={styles.label}>EDUCATION & CERTIFICATES</Text>
          <View>
            {cv.certifications.map((edu) => (
              <EducationAndCertificatesItem key={edu.startDate} {...edu} />
            ))}
          </View>
        </View>
      )}
    </>
  );

  const EducationAndCertificatesItem: FC<EducationCertification> = ({
    startDate,
    endDate,
    institutionName,
    degree,
    area,
  }) => (
    <View style={styles.workList}>
      <View style={styles.timelineLeftContainer}>
        <View style={styles.timelineSeparatorContainer}>
          <View style={styles.circle} />
          <View style={styles.line} />
        </View>
        <View style={styles.workHeader}>
          <Text style={styles.workDate}>
            {dateToMMMYY(startDate!) + ", " + (endDate ? dateToMMMYY(endDate!) : "Present")}
          </Text>
        </View>
      </View>
      <View style={styles.workBody}>
        <Text style={styles.roleTitle}>{degree + " - " + area}</Text>
        <Text style={styles.roleBody}>{institutionName}</Text>
        <View style={{ height: LARGE_SPACE }} />
      </View>
    </View>
  );

  const Languages = () => (
    <>
      {cv.languages.length > 0 && (
        <View style={styles.languageContainer}>
          <Text style={styles.label}>LANGUAGES</Text>
          {cv.languages.map((lang) => (
            <LanguageItem key={lang.language} {...lang} />
          ))}
        </View>
      )}
    </>
  );

  const LanguageItem: FC<Language> = ({ language, level }) => (
    <View>
      <Text>
        <Text style={styles.languageTitle}>{language + " - "}</Text>
        <Text>{level}</Text>
      </Text>
    </View>
  );

  const Interests = () => (
    <>
      {cv.interest.description && (
        <View style={styles.interestsContainer}>
          <Text style={styles.label}>INTERESTS</Text>
          <Text>{cv.interest.description}</Text>
        </View>
      )}
    </>
  );

  const CircleAndLineDecorator = () => (
    <View style={styles.timelineSeparatorContainer}>
      <View style={styles.circle} />
      <View style={styles.line} />
    </View>
  );

  const Footer = () => (
    <>
      <Text
        style={styles.pageNumberContainer}
        render={({ pageNumber }) => (pageNumber === 1 ? "" : pageNumber)}
        fixed
      />
      <Image
        src={"https://kodly-assets.s3.eu-west-1.amazonaws.com/backoffice/kodlyiconblack.png"}
        style={styles.footerImage}
        fixed
      />
      <View
        fixed
        style={styles.footerImage}
        render={({ pageNumber }) =>
          pageNumber !== 1 ? null : <View style={{ backgroundColor: WHITE }} />
        }
      />
    </>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <Body />
        <Footer />
      </Page>
    </Document>
  );
};

const PRIMARY_COLOR = "#00B088";
const WHITE = "#fff";
const BLACK = "#000";

const ALL_CENTERED = {
  justifyContent: "center",
  alignItems: "center",
} as ReactPDF.ViewProps;

const LARGEST_SPACE = "48px";
const LARGE_SPACE = "32px";
const BASE_SPACE = "16px";
const SMALL_SPACE = "8px";
const SMALLER_SPACE = "4px";

Font.register({
  family: "Roboto",
  fonts: [{ src: FontRobotoRegular }, { src: FontRobotoBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: WHITE,
    paddingBottom: LARGEST_SPACE,
    fontFamily: "Roboto",
  },
  pageNumberContainer: {
    position: "absolute",
    bottom: 32,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 6,
    color: BLACK,
  },
  footerImage: {
    position: "absolute",
    bottom: 24,
    right: 16,
    width: "84px",
    height: "20px",
  },
  headerContainer: {
    backgroundColor: BLACK,
    padding: `${LARGE_SPACE} ${LARGE_SPACE} 0px ${LARGE_SPACE}`,
  },
  headerImage: {
    width: "84px",
    height: "20px",
    marginBottom: 24,
  },
  titleContainer: {
    ...ALL_CENTERED,
    border: `3px solid ${PRIMARY_COLOR}`,
    borderBottom: "0px",
    padding: BASE_SPACE,
    width: "70%",
    alignSelf: "center",
  },
  title: {
    color: PRIMARY_COLOR,
    fontSize: 32,
    fontWeight: 700,
    textAlign: "center",
  },
  email: {
    marginVertical: BASE_SPACE,
    color: WHITE,
    fontSize: 14,
    fontWeight: 700,
  },
  jobTitleContainer: {
    ...ALL_CENTERED,
    width: "70%",
    padding: SMALL_SPACE,
    alignSelf: "center",
    border: `3px solid ${BLACK}`,
    borderTop: "0px",
    textAlign: "center",
    marginBottom: LARGEST_SPACE,
    fontWeight: 700,
    fontSize: 12,
  },
  body: {
    padding: `0px ${LARGE_SPACE}`,
  },
  sectionContainer: {
    marginBottom: LARGE_SPACE,
  },
  label: {
    color: PRIMARY_COLOR,
    borderBottom: `2px solid ${PRIMARY_COLOR}`,
    width: "40%",
    fontSize: 14,
    paddingBottom: SMALLER_SPACE,
    marginBottom: SMALL_SPACE,
  },
  personalInfoText: {
    fontWeight: 400,
    fontSize: 12,
  },
  skillsList: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  skillContainer: {
    width: "50%",
    marginVertical: SMALL_SPACE,
  },
  skillLabel: {
    color: PRIMARY_COLOR,
    fontSize: 12,
    fontWeight: 400,
  },
  skillValue: {
    color: BLACK,
    fontSize: 10,
    fontWeight: 400,
  },
  workListParent: {
    display: "flex",
  },
  workList: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  timelineLeftContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  timelineSeparatorContainer: {
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "18px",
  },
  circle: {
    backgroundColor: PRIMARY_COLOR,
    height: "18px",
    width: "18px",
    borderRadius: "50px",
  },
  line: {
    flexWrap: "nowrap",
    backgroundColor: PRIMARY_COLOR,
    width: "1px",
  },
  workHeader: {
    marginLeft: SMALL_SPACE,
  },
  workDate: {
    color: PRIMARY_COLOR,
    fontSize: 11,
    fontWeight: 700,
    marginBottom: SMALL_SPACE,
  },
  workCompany: {
    color: BLACK,
    fontSize: 9,
    marginLeft: BASE_SPACE,
  },
  workBody: {
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  roleTitle: {
    color: BLACK,
    fontSize: 12,
    fontWeight: 700,
    marginBottom: BASE_SPACE,
  },
  roleBody: {
    flexWrap: "wrap",
    color: BLACK,
    fontSize: 9,
    fontWeight: 400,
  },
  educationContainer: {
    marginBottom: LARGEST_SPACE,
    fontSize: 12,
  },
  languageContainer: {
    marginBottom: LARGEST_SPACE,
    fontSize: 12,
  },
  languageTitle: {
    fontWeight: 700,
  },
  interestsContainer: {
    marginBottom: LARGEST_SPACE,
    fontSize: 11,
  },
});
