import React, { FC, ReactElement, useState } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface IProps {
  title: string;
  length: number;
  index: number;
  children: ReactElement;
  backgroundColor?: string;
  titleColor?: string;
}

export const CollapseExpand: FC<IProps> = ({
  title,
  length,
  index,
  children,
  backgroundColor,
  titleColor,
}) => {
  const [collapse, setCollapse] = useState<boolean[]>(new Array(length).fill(true));

  const handleChange = (index: number) => {
    const aux = [...collapse];
    aux.splice(index, 1, !collapse[index]);
    setCollapse([...aux]);
  };

  const getBorderBottom = () => {
    return backgroundColor == undefined
      ? "1px solid #D0D4D4"
      : "1px solid " + String(backgroundColor);
  };

  return (
    <Box width="100%">
      <Box padding="0em 2em">
        <Box display="flex" height="50px" alignItems="center" borderBottom={getBorderBottom()}>
          <Typography
            flexGrow="1"
            sx={{
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "16px",
              linheight: "24px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              color: titleColor,
            }}
          >
            {title}
          </Typography>
          {collapse[index] ? (
            <>
              <KeyboardArrowUpIcon
                fontSize="large"
                onClick={() => handleChange(index)}
                style={{ color: titleColor }}
              />
            </>
          ) : (
            <KeyboardArrowDownIcon
              fontSize="large"
              onClick={() => handleChange(index)}
              style={{ color: titleColor }}
            />
          )}
        </Box>
        <>{collapse[index] && children}</>
      </Box>
    </Box>
  );
};
