import * as yup from "yup";
import { REGEX } from "../../constants/regex.const";

export const projectTeamMemberValidationSchema = yup.object({
  role: yup.string().required("Role is required"),
  dailyCost: yup
    .number()
    .positive("Daily Cost must be a positive number")
    .required("Daily Cost is required"),
  dailyRate: yup
    .number()
    .positive("Daily Rate must be a positive number")
    .required("Daily Rate is required"),
});

export const projectValidationSchema = yup.object({
  wbs: yup
    .string()
    .matches(REGEX.ALPHANUMERIC, "Only alphanumeric characters are allowed")
    .strict()
    .required("WBS code is required"),
  name: yup
    .string()
    .matches(REGEX.ALPHANUMERIC, "Only alphanumeric characters are allowed")
    .strict()
    .required("Project name is required"),
  startDate: yup.date().required("Date is required"),
  billingCycle: yup.date().required("Date is required"),
  currency: yup
    .string()
    .matches(REGEX.ALPHA_UPPERCASE, "Only upper case letters are allowed")
    .strict()
    .required("Currency is required"),
  description: yup.string().optional(),
  status: yup.string().matches(REGEX.ALPHABET, "Only alphabets are allowed").optional(),
  pictureURL: yup.string().optional(),
  teamMembers: yup.array().of(projectTeamMemberValidationSchema),
});
