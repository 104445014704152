import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmployeDetails } from "../../../../components/hr/employees/EmployeDetails/EmployeDetails";
import { PageDetails } from "../../../../components/hr/Global/PageDetails/PageDetails";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import {
  PageDetailsContent,
  PageDetailsContentWithoutAction,
} from "../../../../types/hr/PageContent";

export const EmployeDetailsPage: FC = () => {
  const dispatch = useTypedDispatch();
  const { blogPost, loading, error, deleted } = useTypedSelector(
    (state: RootState) => state.blogPosts
  );
  const { token } = useTypedSelector((state: RootState) => state.auth);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const [pageContent, setPageContent] = useState<PageDetailsContentWithoutAction>({
    previousPage: "/employees",
  });

  return (
    <PageDetails pageContent={pageContent} sidebar={true}>
      <EmployeDetails />
    </PageDetails>
  );
};
