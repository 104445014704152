import { useFormik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HRPage } from "../../../../components/hr/Global/HRPage/HRPage";
import { ProjectComponent } from "../../../../components/hr/projects/ProjectCreate/Component/ProjectComponent";
import { emptyProject } from "../../../../data/hr/project";
import {
  createProject,
  resetProject,
  updateApproversTouched,
  updateTeamMembersTouched,
} from "../../../../stores/hr/Project.store";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { PageContentMainComponent } from "../../../../types/hr/PageContent";
import { Project } from "../../../../types/hr/Project";
import { isHttpCodeValid } from "../../../../utils/common/http";
import { projectValidationSchema } from "../../../../utils/hr/project.utils";

type CreateProjectResponse = {
  code: number;
  project: Project;
};

export const CreateProjectPage: FC = () => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);
  const { project } = useTypedSelector((state: RootState) => state.projects);

  useEffect(() => {
    dispatch(resetProject());
  }, []);

  const submitProject = async (values: Project) => {
    if (!project.approvers.length) {
      dispatch(updateApproversTouched(true));
      return;
    }
    if (!formik.values.teamMembers.length) {
      dispatch(updateTeamMembersTouched(true));
      return;
    }

    values.approvers = project.approvers;
    values.teamMembers = formik.values.teamMembers;

    const { payload } = await dispatch(createProject(values));
    const response = payload as CreateProjectResponse;

    if (isHttpCodeValid(response.code)) {
      formik.resetForm(emptyProject);
      dispatch(updateApproversTouched(false));
      dispatch(updateTeamMembersTouched(false));
      navigate(`/projects/${response.project.wbs}`, { replace: true });
    } else {
      formik.setFieldError("wbs", "WBS code already exists");
    }
  };

  const formik = useFormik({
    initialValues: emptyProject,
    onSubmit: submitProject,
    validationSchema: projectValidationSchema,
  });

  const [pageContent, _] = useState<PageContentMainComponent>({
    tabs: ["Basic Information", "Team Members"],
    submitForm: formik.submitForm,
    switchTabAction: (number) => setActiveTab(number),
  });

  return (
    <HRPage pageContent={pageContent} sidebar={true}>
      <ProjectComponent activeTab={activeTab} formik={formik} />
    </HRPage>
  );
};
