import React, { FC } from "react";
import { Box } from "@mui/material";

import "./ProjectComponent.css";
import { CreateProject } from "../CreateProject/CreateProject";
import { TeamMembers } from "../TeamMembers/TeamMembers";

interface ProjectProps {
  activeTab: number;
  formik: any;
}

export const ProjectComponent: FC<ProjectProps> = ({ activeTab, formik }) => {
  const renderTab = (index: number) => {
    switch (index) {
      case 0:
        return <CreateProject inEditMode={false} formik={formik} />;
      case 1:
        return <TeamMembers inEditMode={false} formik={formik} />;
    }
  };

  return (
    <div>
      <Box className="create-project-container">{renderTab(activeTab)}</Box>
    </div>
  );
};
