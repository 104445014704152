import {
  DAY_OF_WEEK_INDEX,
  MONTH_INDEX,
  MONTH_SHORT_NAME,
  SPECIFIC_DATES,
} from "../constants/dates.const";
import { pad } from "./string.utils";

export const SEC_TO_MS_MULTIPLIER = 1000;

export const dateToString = (date: string) => {
  return `${MONTH_SHORT_NAME[new Date(date).getMonth()]} ${pad(
    new Date(date).getDay().toString(),
    2
  )}, ${new Date(date).getFullYear()}`;
};

export const dateToMMMYY = (date: string): string => {
  return `${MONTH_SHORT_NAME[new Date(date).getMonth()]} ${new Date(date).getFullYear()}`;
};

export const dateToYYYMMDD = (date: Date): string =>
  `${date.getFullYear()}-${pad((date.getMonth() + 1).toString(), 2)}-${pad(
    date.getDate().toString(),
    2
  )}`;

export const dateToDDMMYYYY = (date: Date): string =>
  `${pad(date.getUTCDate().toString(), 2)}-${pad((date.getMonth() + 1).toString(), 2)}-${pad(
    date.getFullYear().toString(),
    2
  )}`;

export const fullDate = (date: Date): string =>
  `${dateToYYYMMDD(date)}T${pad(date.getHours().toString(), 2)}:${pad(
    date.getMinutes().toString(),
    2
  )}:${pad(date.getSeconds().toString(), 2)}.${pad(date.getMilliseconds().toString(), 3)}Z`;

export const differenceBetweenDates = (startDate: Date, endDate: Date) => {
  const curDate = new Date(startDate.getTime());
  const vacationDays = [];
  while (curDate <= endDate) {
    if (!doesDateBelongToWeekend(curDate)) {
      vacationDays.push(dateToYYYMMDD(curDate));
    }
    curDate.setDate(curDate.getDate() + 1);
  }
  return vacationDays;
};

export const doesDateBelongToWeekend = (date: Date) => {
  const day = date.getDay();
  return day == DAY_OF_WEEK_INDEX.SUNDAY || day == DAY_OF_WEEK_INDEX.SATURDAY;
};

export const getFirstDayOfYear = () => {
  const currentYear = new Date().getFullYear();
  return new Date(currentYear, MONTH_INDEX.JANUARY, SPECIFIC_DATES.FIRST_DAY_OF_JANUARY);
};

export const getLastDayOfYear = () => {
  const currentYear = new Date().getFullYear();
  return new Date(currentYear, MONTH_INDEX.DECEMBER, SPECIFIC_DATES.LAST_DAY_OF_DECEMBER);
};

export const getExtensiveDateString = (date: string): string => {
  try {
    const startDateArray = new Date(Date.parse(date))
      .toLocaleString("default", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
      .split(" ");

    startDateArray[0] = startDateArray[0] + "th";
    return startDateArray.join(" ");
  } catch (err: any) {
    return "";
  }
};

export const getDayThDateString = (date: string): string => {
  try {
    return (
      new Date(Date.parse(date)).toLocaleString("default", {
        day: "numeric",
      }) + "th of each month"
    );
  } catch (err: any) {
    return "";
  }
};

export const getLastDayOfNextYear = () => {
  const nextYear = new Date().getFullYear() + 1;
  return new Date(nextYear, MONTH_INDEX.DECEMBER, SPECIFIC_DATES.LAST_DAY_OF_DECEMBER);
};
