import { emptyVacation } from "./emptyVacation";
import { emptyProject } from "./project";

export const emptyEmployee = {
  id: "",
  name: "",
  email: "",
  pictureURL: "",
  phoneNumber: 0,
  dob: "",
  kob: "",
  dailyCost: 0,
  vacations: [emptyVacation],
  projects: [emptyProject],
  active: false,
  role: "",
};
