import React, { FC } from "react";
import { HREmployee } from "../../../../../types/hr/HREmployee";

import "./EmployeAvatar.css";

interface EmployeAvatarProps {
  employee?: HREmployee;
}

export const EmployeAvatar: FC<EmployeAvatarProps> = ({ employee }) => (
  <div className="EmployeAvatar-container">
    <div
      className="EmployeAvatar-image"
      style={{
        backgroundImage: `url(${employee?.pictureURL})`,
      }}
    ></div>
    <span className="EmployeAvatar-name">{employee?.name}</span>
    <br />
    <span className="EmployeAvatar-since">
      {employee && "Since " + new Date(employee?.kob).getFullYear()}
    </span>
  </div>
);
