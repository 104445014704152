import * as yup from "yup";
import { HREmployee } from "../../../../types/hr/HREmployee";

export const INFO_CONFIG = {
  dob: {
    label: "Date of Birth (DOB)",
    name: "dob",
  },
  kob: {
    label: "Kodly of Birth (KOB)",
    name: "kob",
  },
  vacations: {
    assignedDays: { label: "Total Vacations", name: "vacations" },
  },
  dailyCost: {
    label: "Daily Cost",
    name: "dailyCost",
  },
};

export const CONTACTS_CONFIG = {
  telephone: "Telephone",
  email_address: "Email Address",
};

export const INITIAL_EMPLOYEE = (employee: HREmployee): HREmployee => ({
  id: employee.id,
  name: employee.name,
  email: employee.email,
  pictureURL: employee.pictureURL,
  phoneNumber: employee.phoneNumber,
  dob: employee.dob,
  kob: employee.kob,
  dailyCost: employee.dailyCost ? employee.dailyCost : 0,
  vacations: employee.vacations,
  projects: employee.projects,
  active: employee.active,
  role: employee.role,
});

export const FORMIK_VALIDATION = yup.object({
  dob: yup.string().nullable().optional(),
  kob: yup.string().nullable().optional(),
  dailyCost: yup.number().optional(),
  assignedDays: yup.number(),
});
