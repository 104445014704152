import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyCV } from "../../data/internal/cv/cv";
import CVService from "../../services/internal/CV.service";
import { CV } from "../../types/internal/cv/CV";

const initialState = {
  loading: false,
  error: null,
  curriculumVitae: emptyCV,
  created: false,
  edited: false,
  deleted: false,
  publishChange: false,
};

const curriculumVitaeSlice = createSlice({
  name: "curriculumVitae",
  initialState: { ...initialState } as any,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setPublishState(state, action) {
      state.publishChange = action.payload;
    },
    setCurriculumVitae(state, action) {
      state.curriculumVitae = action.payload;
    },
    resetState(state) {
      return (state = { ...initialState });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurriculumVitae.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getCurriculumVitae.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.curriculumVitae = action.payload;
      }
    });
    builder.addCase(getCurriculumVitae.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createCurriculumVitae.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
        state.edited = false;
      }
    });
    builder.addCase(createCurriculumVitae.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
        state.edited = true;
        state.error = null;
        state.curriculumVitae = action.payload;
      }
    });
    builder.addCase(createCurriculumVitae.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const createCurriculumVitae = createAsyncThunk(
  "cv/create",
  async (data: { cv: CV; userEmail: string }) => {
    data.cv.email = data.userEmail;
    const response = await CVService.createCV(data.cv);
    return response.cv;
  }
);

export const getCurriculumVitae = createAsyncThunk(
  "cv/getByEmployee",
  async (data: { userEmail: string }) => {
    const response = await CVService.getCV(data.userEmail);
    return response.cv;
  }
);

export default curriculumVitaeSlice.reducer;
export const { setCreated, setEdited, setDeleted, setCurriculumVitae, setError, resetState } =
  curriculumVitaeSlice.actions;
