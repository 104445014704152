import * as yup from "yup";
import { REGEX } from "../../../../../constants/regex.const";

export interface FormConfig {
  name: string;
  label: string;
}

export const CERT_FORM_CONFIG: FormConfig[] = [
  {
    name: "description",
    label: "Description",
  },
  {
    name: "year",
    label: "Year",
  },
  {
    name: "hours",
    label: "Hours",
  },
  { name: "attachment", label: "Attachment" },
];

export const EMPTY_CERT_FORMIK_INIT_VALUES = {
  description: "",
  year: "",
  hours: 0,
  attachment: "",
};

export const CERT_FORMIK_INIT_VALUES_FROM_CERT = () => ({
  description: "",
  year: "",
  hours: 0,
  attachment: "",
});

export const CERT_FORMIK_VALIDATION = yup.object({
  description: yup.string().optional().nullable(),
  year: yup
    .string()
    .matches(REGEX.ONLY_NUMBERS, "Only numbers are valid")
    .min(4, "Min 4 numbers")
    .max(4, "Max 4 numbers")
    .optional()
    .nullable(),
  hours: yup.number().optional().nullable(),
});
