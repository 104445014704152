import { FormikValues } from "formik";

export function pad(number: string, width: number): string {
  let aux = `${number.toString()}`;
  while (aux.length < width) {
    aux = `0${aux}`;
  }
  return aux;
}

export const formikTrimStrings = (
  formik: FormikValues,
  event: React.FocusEvent<HTMLInputElement>
) => {
  if (event.target.value) {
    const trimValue = event.target.value.trim();
    const setValue = setValueByPath(formik.values, event.target.name, trimValue);
    formik.values = setValue;
  }
  formik.handleBlur(event);
};

/**
 * Update value by an object path
 * @param obj
 * @param path
 * @param value
 * @returns an updated object
 */
function setValueByPath(obj: any, path: string, value: string) {
  const pathArray = mapPathToArray(path);
  let last = pathArray.pop();
  const length = pathArray.length;

  // if there is just one property in the path
  if (length === 0) {
    obj[last as string] = value;
    return obj;
  } else {
    let i = 1;
    let current = pathArray[0];

    while ((obj = obj[current]) && i < length) {
      current = pathArray[i];
      i++;
    }

    if (obj && last) {
      obj[last] = value;
      return obj;
    }
  }
}

/**
 * Map all keys in the path to an array
 * @param path
 * @returns an array with all params
 */
function mapPathToArray(path: string) {
  let pathArray: string[] = [];
  // Split to an array with dot notation
  path.split(".").forEach((item: string) => {
    // Split to an array with bracket notation
    item.split(/\[([^}]+)\]/g).forEach(function (key) {
      // Push to the new array
      if (key.length > 0) {
        pathArray.push(key);
      }
    });
  });
  return pathArray;
}

export function stringTitleCase(string: string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}
