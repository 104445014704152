import React, { FC } from "react";
import { Box } from "@mui/material";
import { VacationsSummaryTable } from "./VacationsSummaryTable/VacationsSummaryTable";
import { VacationsSummaryCount } from "./VacationsSummaryCount/VacationsSummaryCount";
import { Vacation } from "../../../../types/internal/vacation/Vacation";
import { VacationPaginated } from "../../../../types/internal/vacation/VacationPaginated";
import { VacationsBalance } from "../../../../types/internal/vacation/VacationsBalance";

interface VacationsSummaryProps {
  vacationsPaginated: VacationPaginated;
  tablePage: number;
  vacationsBalance: VacationsBalance;
  navigateToCreateVacation: () => void;
  onPaginationChange: (newPage: number) => void;
}

export const VacationsSummary: FC<VacationsSummaryProps> = ({
  vacationsPaginated,
  tablePage,
  vacationsBalance,
  navigateToCreateVacation,
  onPaginationChange,
}) => {
  const onRefresh = async () => {
    window.location.reload();
  };
  return (
    <Box>
      <Box sx={{ backgroundColor: "#ffffff" }} height="66vh">
        <Box>
          <Box>
            <VacationsSummaryCount
              navigateToCreateVacation={navigateToCreateVacation}
              vacationsBalance={vacationsBalance}
            />
          </Box>
        </Box>
        <Box paddingTop="3em">
          <VacationsSummaryTable
            vacationsPaginated={vacationsPaginated}
            onPaginationChange={onPaginationChange}
            tablePage={tablePage}
            onRefresh={onRefresh}
          />
        </Box>
      </Box>
    </Box>
  );
};
