import { action } from "mobx";
import React, { FC, useEffect, useState } from "react";
import { HRPage } from "../../../components/hr/Global/HRPage/HRPage";
import { ProjectsList } from "../../../components/hr/projects/ProjectsList/ProjectsList";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";

export const ProjectsPage: FC = () => {
  return (
    <HRPage pageContent={null} sidebar={true}>
      <ProjectsList />
    </HRPage>
  );
};
