import React, { FC, useEffect, useRef, useState } from "react";
import { PageCreate } from "../../../components/internal/Global/Pages/PageCreate";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../stores/store";
import { useFormik } from "formik";
import {
  createCurriculumVitae,
  getCurriculumVitae,
  resetState,
  setEdited,
  setError,
} from "../../../stores/internal/CV.store";
import { cvValidationSchema } from "../../../utils/internal/cv.utils";
import { CVComponent } from "../../../components/internal/CV/CV";
import "./CVPage.css";
import { CV } from "../../../types/internal/cv/CV";

interface CVPageProps {
  hrMode?: boolean;
  userEmail?: string;
}

export const CVPage: FC<CVPageProps> = ({ hrMode, userEmail }) => {
  const dispatch = useTypedDispatch();
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { email, token } = useTypedSelector((state: RootState) => state.auth);
  const { edited, error, curriculumVitae } = useTypedSelector((state: RootState) => state.CV);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [errorTabsIndex, setErrorTabsIndex] = useState<number[]>([]);
  const [alreadySubmitedOneTime, setAlreadySubmitedOneTime] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: curriculumVitae,
    onSubmit: () => {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: cvValidationSchema,
  });

  const hrStyle = {
    backgroundColor: "#1F2632",
    inputBackground: "#fff",
    inputLabelColor: "#fff",
    collapseTitleColor: "#3AAFC9",
    collapseBottomBorder: "#fff",
    buttonBorder: "#676CDB",
    buttonFontColor: "#fff",
    buttonBackgroundColor: "#1F2632",
    buttonHoverColor: "#676CDB",
    uploadImageContentColor: "#fff",
  };

  useEffect(() => {
    dispatch(resetState());
    dispatch(getCurriculumVitae({ userEmail: getEmailForCV() }));
  }, []);

  useEffect(() => {
    defineMessageTimeDuration("edited", edited);
  }, [edited]);

  useEffect(() => {
    defineMessageTimeDuration("error", error);
  }, [error]);

  const defineMessageTimeDuration = (header: string, value: boolean) => {
    if (value) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(
        () => dispatch(header === "edited" ? setEdited(false) : setError(false)),
        2000
      );
    }
  };

  const switchTabAction = (index: number) => {
    setActiveTab(index);
  };

  const getEmailForCV = () => {
    return userEmail ? userEmail : email;
  };

  const createAction = async () => {
    const cvRequestBody: CV = prepareRequestBody();
    formik.errors = await formik.validateForm(cvRequestBody);
    setTabsWithErrors();
    setAlreadySubmitedOneTime(true);
    if (Object.keys(formik.errors).length > 0 || !token) {
      return;
    }
    dispatch(
      createCurriculumVitae({ cv: cvRequestBody, userEmail: userEmail ? userEmail : email })
    ).then(() => {
      if (alreadySubmitedOneTime == true) {
        setAlreadySubmitedOneTime(false);
      }
    });
  };

  const prepareRequestBody = () => {
    var cv: CV = JSON.parse(JSON.stringify(formik.values)) as typeof formik.values;
    cv.experience.map((experience, index) => {
      if (cv.experience[index].endDate == null) {
        cv.experience[index].endDate = undefined;
      }
    });
    return cv;
  };

  const handleInputChange = async (event: any) => {
    formik.handleChange(event);
    await validateErrorsIfAlreadySubmitted();
  };

  const validateErrorsIfAlreadySubmitted = async () => {
    if (alreadySubmitedOneTime) {
      const cvRequestBody: CV = prepareRequestBody();
      formik.errors = await formik.validateForm(cvRequestBody);
      setTabsWithErrors();
    }
  };

  const setTabsWithErrors = () => {
    const tabsWithErrors = [];
    if (formik.errors.personalInfo != undefined) {
      tabsWithErrors.push(0);
    }
    if (formik.errors.techStack != undefined) {
      tabsWithErrors.push(1);
    }
    if (formik.errors.experience != undefined) {
      tabsWithErrors.push(2);
    }
    if (formik.errors.certifications != undefined) {
      tabsWithErrors.push(3);
    }
    setErrorTabsIndex(tabsWithErrors);
  };

  const getPreviousPagePath = () => {
    return hrMode ? `/employees` : `/`;
  };

  return (
    <PageCreate
      pageContent={{
        pageTitle: "Edit CV",
        previousPage: getPreviousPagePath(),
        action: createAction,
        type: "Edit",
        tabs: ["Personal Info", "Tech Stack", "Experience", "Education and Certs", "Languages"],
        switchTabAction,
        errorTabs: errorTabsIndex,
      }}
      hrMode={hrMode}
    >
      <>
        <div style={{ position: "relative" }}>
          <div className="CVPage-main">
            <CVComponent
              activeTab={activeTab}
              formik={formik}
              handleInputChange={handleInputChange}
              hrStyle={hrMode ? hrStyle : undefined}
              email={getEmailForCV()}
            />
          </div>
          <div className="CV-message-container">
            {edited && <div className="CV-message-success"> Success </div>}
            {error && <div className="CV-message-fail"> Failed </div>}
          </div>
        </div>
      </>
    </PageCreate>
  );
};
