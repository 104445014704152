import React, { FC, useRef } from "react";
import { Box, Button } from "@mui/material";
import "./CVTechnologyStack.css";
import { CVTechnology } from "./CVTechnology/CVTechnology";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormikValues } from "formik";
import { Technology } from "../../../../types/internal/cv/Technology";
import { emptyTechStack } from "../../../../data/internal/cv/techStack";

interface CVTechnologyStackProps {
  formik: FormikValues;
  handleInputChange: (event: any) => {};
  hrStyle?: any;
}

export const CVTechnologyStack: FC<CVTechnologyStackProps> = ({
  formik,
  handleInputChange,
  hrStyle,
}) => {
  let formikTechStack = [...formik.values.techStack];
  const addTechnology = () => {
    formikTechStack = [...formik.values.techStack, emptyTechStack];
    formik.setValues({
      ...formik.values,
      techStack: formikTechStack,
    });
  };

  const handleDelete = (index: number) => {
    let aux = [...formik.values.techStack];
    aux.splice(index, 1);
    formik.setFieldValue("techStack", aux);
    formikTechStack = aux;
  };

  const showHRStyle = () => {
    return hrStyle != undefined && hrStyle != null;
  };

  return (
    <Box className="Technology-formContainer">
      <Box display="flex" justifyContent="center">
        <Button
          sx={{
            backgroundColor: !showHRStyle() ? "white" : hrStyle.buttonBackgroundColor,
            color: !showHRStyle() ? "black" : hrStyle.buttonFontColor,
            border: !showHRStyle()
              ? "1px solid black"
              : "1px solid " + String(hrStyle?.buttonBorder),
            margin: "0em 0em 2em 0em",
            "&:hover": {
              backgroundColor: hrStyle?.buttonHoverColor,
              color: hrStyle?.inputLabelColor,
            },
          }}
          endIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            addTechnology();
          }}
        >
          Add a new Tech Stack
        </Button>
      </Box>
      <Box className="CVTechnology-dropdowns">
        {formikTechStack.map((tech: Technology, index: number) => (
          <CVTechnology
            formik={formik}
            title={formik.values.techStack[index]?.technologyArea}
            key={index}
            index={index}
            handleDelete={handleDelete}
            length={formikTechStack.length}
            handleInputChange={handleInputChange}
            hrStyle={hrStyle}
          />
        ))}
      </Box>
    </Box>
  );
};
