import { HTTP_CODES } from "../../constants/httpstatus.const";
import { emptyProject } from "../../data/hr/project";
import { Approver } from "../../types/hr/Approver";
import { Project } from "../../types/hr/Project";
import { PageSettings } from "../../types/hr/PageSettings";
import { TeamMember } from "../../types/mkt/TeamMember";
import { apiClient, getAuthConfig, buildConfigWithPaginationSettings } from "../HttpClient";

const PROJECT_PATH = "/hr/project";
const APPROVER_PATH = "/teamMember";
const EMPLOYEES_CORE_INFO_PATH = "/hr/employee/core-info";

export default class ProjectService {
  static getProjectsByUser = async (
    pageSettings: PageSettings,
    employeeEmail: string,
    token: string
  ) => {
    try {
      const response = await apiClient.get(
        PROJECT_PATH,
        buildConfigWithPaginationSettings(token, pageSettings, employeeEmail)
      );
      return {
        projects: response.data.content as Project[],
        totalProjects: response.data.totalElements,
        code: response.status,
      };
    } catch (err: any) {
      return {
        projects: [],
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };

  static getProjects = async () => {
    try {
      const response = await apiClient.get(PROJECT_PATH, getAuthConfig());
      return {
        projects: response.data as Project[],
        code: response.status,
      };
    } catch (err: any) {
      return {
        projects: [],
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };

  static getProject = async (wbs: string) => {
    try {
      const response = await apiClient.get(`${PROJECT_PATH}/${wbs}`, getAuthConfig());
      const projectResponse = response.data as Project;
      const finalProject = {
        ...projectResponse,
        teamMembers: projectResponse.teamMembers.sort((a, b) => a.name.localeCompare(b.name)),
      };
      return {
        project: finalProject,
        code: response.status,
      };
    } catch (err: any) {
      return {
        project: emptyProject,
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };

  static updateProject = async (project: Project) => {
    try {
      const response = await apiClient.patch(
        `${PROJECT_PATH}/${project.wbs}`,
        project,
        getAuthConfig()
      );
      return {
        project: response.data as Project,
        code: response.status,
      };
    } catch (err: any) {
      return {
        project: emptyProject,
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };

  static getEmployeesCoreInfo = async () => {
    try {
      const response = await apiClient.get(EMPLOYEES_CORE_INFO_PATH, getAuthConfig());
      return {
        employees: response.data as Approver[],
        code: response.status,
      };
    } catch (err: any) {
      return {
        employees: [],
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };

  static createProject = async (project: Project) => {
    try {
      const response = await apiClient.post(PROJECT_PATH, project, getAuthConfig());
      return {
        project: response.data as Project,
        code: response.status,
      };
    } catch (err: any) {
      return {
        project: emptyProject,
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };
}
