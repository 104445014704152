import React, { FC, ReactElement, useState } from "react";
import { Box, Tooltip, IconButton } from "@mui/material";
import { RootState, useTypedSelector } from "../../../../stores/store";
import { useNavigate } from "react-router-dom";
import "./HRPage.css";
import { ListItemIcon } from "@mui/material";
import { HomeKodlyIcon } from "../../../../assets/svg/HomeKodlyIcon";
import { HRSidebar } from "../Sidebar/HRSidebar";
import { TopBarWithColor } from "../../../TopBar/TopBarWithColor";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { PageContentMainComponent } from "../../../../types/hr/PageContent";
import { EditIcon } from "../../../../assets/svg/EditIcon";
import { KODLY_LOGO } from "../../../../constants/hr/hr.const";

interface PageProps {
  children: ReactElement | null;
  pageContent: PageContentMainComponent | null;
  sidebar: any;
}

export const HRPage: FC<PageProps> = ({ children, pageContent, sidebar }) => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <Box display="flex" sx={{ height: "100vh" }}>
      <Box sx={{ height: "100vh", width: open ? "225px" : "75px" }}>
        <div className="HRPage-kodlyIcon">
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "flex-start",
              px: 2,
            }}
          >
            {open ? (
              <img
                src={KODLY_LOGO}
                alt="Logo"
                width="175px"
                onClick={() => navigate("/", { replace: true })}
              />
            ) : (
              <div onClick={() => navigate("/", { replace: true })}>
                <HomeKodlyIcon />
              </div>
            )}
          </ListItemIcon>
        </div>
        <HRSidebar sidebar={sidebar} />
      </Box>
      <Box
        sx={{
          width: open ? "calc(100% - 225px)" : "calc(100% - 75px)",
          backgroundColor: "#1F2632",
        }}
      >
        {pageContent ? (
          <TopBarWithColor backgroundColor="#1F2632">
            {pageContent.switchTabAction == null || pageContent.tabs == null ? null : (
              <div className="project-page-container">
                {pageContent.tabs.map((tab, index) => (
                  <Box
                    className={
                      activeTab == index ? "project-page-active-element" : "project-page-element"
                    }
                    key={index}
                    onClick={() => {
                      pageContent.switchTabAction(index);
                      setActiveTab(index);
                    }}
                  >
                    {tab}
                  </Box>
                ))}
              </div>
            )}
            <div className="top-bar-icons">
              {pageContent.switchMode && !pageContent.inEditMode ? (
                <Tooltip title={"Edit Project"} sx={{ color: "#00b088", marginLeft: "0.5em" }}>
                  <IconButton onClick={() => pageContent.switchMode?.()}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Cancel" color="error">
                    <IconButton
                      onClick={() =>
                        pageContent.inEditMode ? pageContent.switchMode?.() : navigate("/projects")
                      }
                    >
                      <CancelIcon sx={{ zIndex: "2" }} />
                      <Box
                        sx={{
                          position: "absolute",
                          width: "18px",
                          height: "18px",
                          borderRadius: "100%",
                          backgroundColor: "white",
                          zIndex: "1",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={!pageContent.inEditMode ? "Create Project" : "Update Project"}
                    sx={{ color: "#00b088", marginLeft: "0.5em" }}
                  >
                    <IconButton onClick={() => pageContent.submitForm()}>
                      <CheckCircleIcon sx={{ zIndex: "2" }} />
                      <Box
                        sx={{
                          position: "absolute",
                          width: "18px",
                          height: "18px",
                          borderRadius: "100%",
                          backgroundColor: "white",
                          zIndex: "1",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </TopBarWithColor>
        ) : (
          <TopBarWithColor backgroundColor="#1F2632">
            <Box
              alignItems="flex-end"
              justifyContent="flex-end"
              sx={{ flexGrow: "1", display: { xs: "flex" } }}
            >
              <button
                onClick={() => navigate("/projects/create")}
                className="create-project-button"
              >
                Create Project
              </button>
            </Box>
          </TopBarWithColor>
        )}

        <Box
          sx={{
            padding: "1em 2em",
            height: "calc(100vh - (92px + 2em))",
            overflowY: "auto",
            boxSizing: "content-box",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
