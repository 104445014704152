import React, { FC } from "react";
import { CVPersonalInfo } from "./CVPersonalInfo/CVPersonalInfo";
import { CVTechnologyStack } from "./CVTechnologyStack/CVTechnologyStack";
import { CVProfessionalExperiences } from "./CVProfessionalExperiences/CVProfessionalExperiences";
import { CVEducationCertifications } from "./CVEducationCertifications/CVEducationCertifications";
import { CVLanguages } from "./CVLanguages/CVLanguages";
import { Box } from "@mui/material";
import "./CV.css";
import { observer } from "mobx-react-lite";

interface CVProps {
  activeTab: number;
  formik: any;
  handleInputChange: (event: any) => {};
  hrStyle?: any;
  email?: string;
}

export const CVComponent: FC<CVProps> = observer(
  ({ activeTab, formik, handleInputChange, hrStyle, email }) => {
    const renderTab = (index: number) => {
      switch (index) {
        case 0:
          return (
            <CVPersonalInfo
              formik={formik}
              handleInputChange={handleInputChange}
              hrStyle={hrStyle}
              email={email}
            />
          );
        case 1:
          return (
            <CVTechnologyStack
              formik={formik}
              handleInputChange={handleInputChange}
              hrStyle={hrStyle}
            />
          );
        case 2:
          return (
            <CVProfessionalExperiences
              formik={formik}
              handleInputChange={handleInputChange}
              hrStyle={hrStyle}
            />
          );
        case 3:
          return (
            <CVEducationCertifications
              formik={formik}
              handleInputChange={handleInputChange}
              hrStyle={hrStyle}
            />
          );
        case 4:
          return (
            <CVLanguages formik={formik} handleInputChange={handleInputChange} hrStyle={hrStyle} />
          );
      }
    };

    return (
      <div>
        <Box className="CV-container">{renderTab(activeTab)}</Box>
      </div>
    );
  }
);
