import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { emptyVacation } from "../../data/internal/vacation";
import { emptyVacationsPaginated } from "../../data/internal/vacationsPaginated";
import VacationService from "../../services/internal/Vacation.service";
import { Status } from "../../types/common/Status";
import { Vacation } from "../../types/internal/vacation/Vacation";
import { VacationState } from "../../types/internal/vacation/VacationState";
import { emptyVacationsBalance } from "../../data/internal/vacationsBalance";

const vacationsSlice = createSlice({
  name: "vacation",
  initialState: {
    loading: false,
    error: null,
    vacation: emptyVacation,
    updatedVacation: emptyVacation,
    vacations: [],
    vacationsForTimeReport: [],
    vacationsPaginated: emptyVacationsPaginated,
    vacationsByApprover: emptyVacationsPaginated,
    vacationsByYear: [],
    vacationsProfile: [],
    created: false,
    edited: false,
    deleted: false,
    statusUpdated: false,
    vacationsBalance: emptyVacationsBalance,
  } as VacationState,
  reducers: {
    setCreated(state, action) {
      state.created = action.payload;
    },
    setEdited(state, action) {
      state.edited = action.payload;
    },
    setStatusUpdated(state, action) {
      state.statusUpdated = action.payload;
    },
    setDeleted(state, action) {
      state.deleted = action.payload;
    },
    setVacation(state, action) {
      state.vacations = action.payload;
    },
    setUpdatedVacation(state, action) {
      state.updatedVacation = action.payload;
    },
    setVacationsPaginated(state, action) {
      state.vacationsPaginated = action.payload;
    },
    setVacationsByApprover(state, action) {
      state.vacationsByApprover = action.payload;
    },
    setVacationsForTimeReport(state, action) {
      state.vacationsForTimeReport = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVacationBy.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getVacationBy.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacation = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacationBy.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getVacationFrom.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getVacationByYear.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getVacationByYear.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacationsByYear = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacationByYear.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });

    builder.addCase(getVacationFrom.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacationsPaginated = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacationFrom.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getVacationProfile.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getVacationProfile.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacationsProfile = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacationProfile.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getVacationForTimeReport.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getVacationForTimeReport.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacationsForTimeReport = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacationForTimeReport.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(createVacation.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.created = false;
      }
    });
    builder.addCase(createVacation.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.created = true;
      }
    });
    builder.addCase(createVacation.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(editVacation.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.edited = false;
      }
    });
    builder.addCase(editVacation.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.edited = true;
      }
    });
    builder.addCase(editVacation.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(updateStatus.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
        state.statusUpdated = false;
      }
    });
    builder.addCase(updateStatus.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.statusUpdated = true;
      }
    });
    builder.addCase(updateStatus.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getVacationsByApprovers.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getVacationsByApprovers.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacationsByApprover = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacationsByApprovers.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
    builder.addCase(getVacationsBalance.pending, (state, action) => {
      if (state.loading === false) {
        state.loading = true;
      }
    });
    builder.addCase(getVacationsBalance.fulfilled, (state, action) => {
      if (state.loading === true) {
        state.vacationsBalance = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getVacationsBalance.rejected, (state, action) => {
      if (state.loading === true) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

export const getVacationBy = createAsyncThunk("vacation/getss", async (data: { id: string }) => {
  const response = await VacationService.getVacationBy(data.id);
  return response.vacation;
});

export const getVacationFrom = createAsyncThunk(
  "vacation/get",
  async (data: {
    employeeEmail: string;
    startDate: string;
    endDate: string;
    page: number;
    size: number;
  }) => {
    const response = await VacationService.getVacationFrom(
      data.employeeEmail,
      data.startDate,
      data.endDate,
      data.page,
      data.size
    );
    return response.vacationsPaginated;
  }
);

export const getVacationByYear = createAsyncThunk(
  "vacationByYear/get",
  async (data: {
    employeeEmail: string;
    startDate: string;
    endDate: string;
    page: number;
    size: number;
  }) => {
    const response = await VacationService.getVacationFrom(
      data.employeeEmail,
      data.startDate,
      data.endDate,
      data.page,
      data.size
    );
    return response.vacationsPaginated?.vacations;
  }
);

export const getVacationProfile = createAsyncThunk(
  "vacationProfile/get",
  async (data: {
    employeeEmail: string;
    startDate: string;
    endDate: string;
    page: number;
    size: number;
  }) => {
    const response = await VacationService.getVacationFrom(
      data.employeeEmail,
      data.startDate,
      data.endDate,
      data.page,
      data.size
    );
    return response.vacationsPaginated?.vacations;
  }
);

export const getVacationForTimeReport = createAsyncThunk(
  "vacationForTimeReport/get",
  async (data: {
    employeeEmail: string;
    startDate: string;
    endDate: string;
    page: number;
    size: number;
  }) => {
    const response = await VacationService.getVacationFrom(
      data.employeeEmail,
      data.startDate,
      data.endDate,
      data.page,
      data.size
    );
    return response.vacationsPaginated.vacations;
  }
);

export const createVacation = createAsyncThunk(
  "vacations/create",
  async (data: { vacation: Vacation }) => {
    const response = await VacationService.createVacation(data.vacation);
    return response.vacation;
  }
);

export const editVacation = createAsyncThunk(
  "vacations/edit",
  async (data: { vacation: Vacation }) => {
    const response = await VacationService.editVacation(data.vacation);
    return response.vacation;
  }
);

export const updateStatus = createAsyncThunk(
  "vacations/updateStatus",
  async (data: { vacationId: string; status: Status; reviewer: string }) => {
    const response = await VacationService.updateStatus(
      data.vacationId,
      data.status,
      data.reviewer
    );
    return response.vacation;
  }
);

export const getVacationsByApprovers = createAsyncThunk(
  "vacations/getVacationsByApprovers",
  async (data: { employeeEmail: string; page: number; size: number }) => {
    const response = await VacationService.getVacationsByApprovers(
      data.employeeEmail,
      data.page,
      data.size
    );
    return response.vacationsPaginated;
  }
);

export const deleteVacation = createAsyncThunk(
  "vacations/delete",
  async (data: { email: string; id: string }) => {
    await VacationService.deleteVacation(data.email, data.id);
    return;
  }
);

export const getVacationsBalance = createAsyncThunk(
  "vacations/balance",
  async (data: { employeeEmail: string }) => {
    const response = await VacationService.getVacationsBalance(data.employeeEmail);
    return response.vacationsBalance;
  }
);

export default vacationsSlice.reducer;
export const { setCreated, setEdited, setDeleted, setVacation, setStatusUpdated } =
  vacationsSlice.actions;
