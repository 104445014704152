import React, { FC } from "react";
import { HREmployee } from "../../../../types/hr/HREmployee";
import { Project } from "../../../../types/hr/Project";
import { ProjectTeamMember } from "../../../../types/hr/ProjectTeamMember";
import Slider from "react-slick";
import "./EmployeeProject.css";
import { CircularProgress } from "@mui/material";
import { Loading } from "../../../Loading/loading";
import { PROJECTS_PAGE_SIZE } from "./Employee.consts";

interface EmployeeProjectsProps {
  projects: (Project | null)[];
  employee: HREmployee;
  getProject: (pageN: number) => void;
}

export const EmployeeProjects: FC<EmployeeProjectsProps> = ({ projects, employee, getProject }) => {
  const ProjectCard: FC<Project> = (project) => {
    const userMember = project.teamMembers.find(
      (teamMembers: ProjectTeamMember) => teamMembers.id === employee.id
    );

    return (
      <div className="EmployeeProject-container">
        <div
          className={
            userMember?.active ? "EmployeeProject-content-current" : "EmployeeProject-content"
          }
        >
          {userMember?.active && <div className="EmployeeProject-current"> Current </div>}
          <div className={"EmployeeProject-data"}>
            <img className="EmployeeProject-logo" src={project.pictureURL} />
            <br />
            <br />
            <div className="EmployeeProject-role">
              <div>{userMember?.role}</div>
            </div>
            <div className="EmployeeProject-salary">
              {userMember?.dailyRate + " " + project.currency}
            </div>
            <div className="EmployeeProject-type">{userMember?.workType}</div>
          </div>
        </div>
      </div>
    );
  };

  const slideSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: PROJECTS_PAGE_SIZE,
    slidesToScroll: PROJECTS_PAGE_SIZE,
    beforeChange: (curr: number, next: number) => {
      const pageIndex = Math.ceil(next / PROJECTS_PAGE_SIZE);
      getProject(pageIndex);
    },
  };

  const slides = projects.map((item: Project | null) => {
    return item ? (
      <ProjectCard {...item} />
    ) : (
      <div className="EmployeeProject-loader">
        <CircularProgress className="EmployeeProject-progress" />
      </div>
    );
  });

  return projects.length > 0 ? (
    <div className="EmployeeProject-parent">
      <div className="EmployeeProject-title">Projects</div>
      <br />
      <Slider className="EmployeeProject-slider" {...slideSettings}>
        {slides}
      </Slider>
    </div>
  ) : null;
};
