import { apiClient, getAuthConfig } from "../HttpClient";
import { HTTP_CODES } from "../../constants/httpstatus.const";
import { Email } from "@mui/icons-material";
import { emptyTraining } from "../../data/hr/emptyTraining";
import { Training } from "../../types/internal/Training";

const TRAINING_BY_EMAIL = (email: string) => "/hr/training/" + email + "/employee";
const TRAINING_BY_ID_AND_EMAIL = (id: string, email: string) =>
  "/hr/training/" + id + "/" + email + "/employee";

export default class TrainingService {
  static getTrainingByEmployee = async (email: string) => {
    try {
      const response = await apiClient.get(TRAINING_BY_EMAIL(email), getAuthConfig());
      const trainings: Training[] = response.data;
      return {
        trainings,
        code: response.status,
      };
    } catch (err: any) {
      return {
        trainings: [],
        code: err.response ? err.response.status : HTTP_CODES.INTERNAL_SERVER_ERROR,
      };
    }
  };

  static createTraining = async (email: string, newTraining: Training) => {
    try {
      const response = await apiClient.post(TRAINING_BY_EMAIL(email), newTraining, getAuthConfig());
      const training: Training = response.data;
      return {
        training,
        code: response.status,
      };
    } catch (err: any) {
      return {
        training: emptyTraining,
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };

  static deleteTraining = async (id: string, email: string) => {
    try {
      const response = await apiClient.delete(TRAINING_BY_ID_AND_EMAIL(id, email), getAuthConfig());
      return {
        code: response.status,
      };
    } catch (err: any) {
      return {
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };

  static updateTraining = async (id: string, email: string, newTraining: Training) => {
    try {
      const response = await apiClient.put(
        TRAINING_BY_ID_AND_EMAIL(id, email),
        newTraining,
        getAuthConfig()
      );
      const training: Training = response.data;
      return {
        training,
        code: response.status,
      };
    } catch (err: any) {
      return {
        training: emptyTraining,
        code: err.response ? err.response.status : HTTP_CODES.ERROR,
      };
    }
  };
}
