import React, { FC, useEffect, useState } from "react";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import { EmployeeDetailsField } from "./EmployeeInfo";
import { EmployeeAvatarSquare } from "./EmployeeAvatarSquare";
import { EmployeeContacts } from "./EmployeeContacts";
import { EmployeeProjects } from "./EmployeeProjects";
import "./Employee.css";
import HREmployeeService from "../../../../services/hr/HREmployee.service";
import { emptyEmployee } from "../../../../data/hr/emptyEmployee";
import ClearIcon from "@mui/icons-material/Clear";
import { isHttpCodeValid } from "../../../../utils/common/http";
import ProjectService from "../../../../services/hr/Project.service";
import { Project } from "../../../../types/hr/Project";
import { PROJECTS_PAGE_SIZE } from "./Employee.consts";
import { PageSettings } from "../../../../types/hr/PageSettings";
import { useNavigate } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CVPDF } from "../../../cv/CVPDF";
import { CV } from "../../../../types/internal/cv/CV";
import CVService from "../../../../services/internal/CV.service";
import { Loading } from "../../../Loading/loading";

interface EmployeeProjects {
  title: string;
  current: boolean;
  role: string;
  salary: string;
  type: string;
  logo: string;
}

interface EmployeeProps {
  employeeEmail?: string;
}

export const Employee: FC<EmployeeProps> = ({ employeeEmail }) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { token } = useTypedSelector((state: RootState) => state.auth);
  const [loadingUser, setLoadingUser] = useState(true);
  const [employee, setEmployee] = useState(emptyEmployee);
  const [projects, setProjects] = useState<Array<Project | null>>([]);
  const [showVacationModal, setShowVacationModal] = useState(false);
  const [cv, setCv] = useState<CV>();
  const [anonymizedChecked, setAnonymizedChecked] = useState(false);
  const [showCurriculumModal, setShowCurriculumModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getHREmployee();
      await getHRProjects();
    };

    fetchData();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const getHREmployee = async () => {
    if (!employeeEmail) {
      goBack();
      return;
    }
    setLoadingUser(true);
    const res = await HREmployeeService.getEmployee(employeeEmail!);
    if (isHttpCodeValid(res.code)) {
      setEmployee(res.user);
    } else {
      goBack();
    }
    setLoadingUser(false);
  };

  useEffect(() => {
    if (!employee) return;
    const fetchCV = async () => {
      const res = await CVService.getCV(employeeEmail);
      setCv(isHttpCodeValid(res.code) ? res.cv : undefined);
    };
    fetchCV();
  }, [employee]);

  const activateUser = async () => {
    if (!employee) return;
    const res = await HREmployeeService.activateEmployee(employee.id);
    const activeEmployee = res.user;
    setEmployee(activeEmployee);
  };

  const inactivateUser = async () => {
    if (!employee) return;
    const res = await HREmployeeService.inactivateEmployee(employee.id);
    const inactiveEmployee = res.user;
    setEmployee(inactiveEmployee);
  };

  const getHRProjects = async (pageN: number = 0) => {
    if (!employeeEmail || !employee || !token) return;
    const pageSettings = { page: pageN, size: PROJECTS_PAGE_SIZE };
    const res = await ProjectService.getProjectsByUser(pageSettings, employeeEmail, token);
    if (isHttpCodeValid(res.code)) {
      onGetProjectSuccess(res, pageSettings);
      return;
    }
  };

  const onGetProjectSuccess = (res: any, pageSettings: PageSettings) => {
    const newArr = projects.length > 0 ? [...projects] : new Array(res.totalProjects).fill(null);

    const newPageStartIndex = pageSettings.page * pageSettings.size;
    const totalProjectsInPage = res.projects.length;
    newArr.splice(newPageStartIndex, totalProjectsInPage, ...res.projects);

    setProjects(newArr);
  };

  const onCloseModal = () => {
    setShowVacationModal(false);
    setShowCurriculumModal(false);
  };

  const onAnonymizedCheckChange = (e: any) => {
    setAnonymizedChecked(e.target.checked);
  };

  const cvFileName = !cv
    ? ""
    : `cv_${cv.personalInfo.firstName[0] + cv.personalInfo.lastName[0]}.pdf`;

  const mockVacations = [
    { year: "1992", scheduledDays: 30, approvedDays: 28, remainingDays: 30, totalDays: 60 },
    { year: "2022", scheduledDays: 1, approvedDays: 1, remainingDays: 21, totalDays: 22 },
    { year: "0", scheduledDays: 0, approvedDays: 0, remainingDays: 0, totalDays: 0 },
  ];

  const renderVacationModal = () => (
    <div className="Modal-container" onClick={onCloseModal}>
      <div className="Modal-popup" onClick={(e) => e.stopPropagation()}>
        <div className="Popup-header">
          <ClearIcon className="Clear-icon" onClick={onCloseModal} />
          Vacation Days
        </div>
        <div className="Popup-content">
          <div className="Popup-year" />
          <div className="Popup-schedule-days"> Scheduled Days </div>
          <div className="Popup-approved-days"> Approved Days</div>
          <div className="Popup-remaining-days"> Remaining Days</div>
          <div className="Popup-total-days"> Total Days</div>
        </div>
        <div className="Popup-list-content">
          {mockVacations.map((item: any) => (
            <div key={item.year} className="Popup-container">
              <div className="Popup-content-vacations">{item.year}</div>
              <div className="Popup-content-vacations">{item.scheduledDays}</div>
              <div className="Popup-content-vacations">{item.approvedDays}</div>
              <div className="Popup-content-vacations">{item.remainingDays}</div>
              <div className="Popup-content-vacations">{item.totalDays}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderPdfDownloadButton = () => (
    <div className="EmployeeCurriculum-container-popup">
      {cv && (
        <PDFDownloadLink
          document={<CVPDF cv={cv} anonymous={anonymizedChecked} />}
          fileName={cvFileName}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <div className="EmployeeCurriculum">Loading...</div>
            ) : (
              <div className="EmployeeCurriculum">Download</div>
            )
          }
        </PDFDownloadLink>
      )}
    </div>
  );

  const renderCurriculumModal = () => (
    <div className="Modal-container-cv" onClick={onCloseModal}>
      <div className="Modal-popup-cv" onClick={(e) => e.stopPropagation()}>
        <div className="Popup-header-cv">
          <ClearIcon className="Clear-icon" onClick={onCloseModal} />
          Export Curriculum
        </div>
        <div className="Popup-title">
          Do you want the curriculum to be in the anonymized format?
        </div>
        <div className="Popup-content-cv">
          <div className="checkbox-element">
            <input
              className="checkmark"
              type="checkbox"
              id="anonymized"
              onChange={onAnonymizedCheckChange}
            />
            <label className="container" htmlFor="anonymized">
              Yes
            </label>
          </div>
        </div>
        {renderPdfDownloadButton()}
      </div>
    </div>
  );

  const renderContent = () => (
    <div className="EmployeeDetails-Parent">
      <div className="-">
        <div className="EmployeDetails-content">
          <div className="EmployeDetails-info">
            <EmployeeDetailsField
              employee={employee}
              name={employee.name}
              dateOfBirth={employee.dob}
              kodlyOfBirth={employee.kob}
              costs={employee.dailyCost}
              active={employee.active}
              onShowModal={() => setShowVacationModal(true)}
              onInactivate={inactivateUser}
              onActivate={activateUser}
            />
            <br />
            <EmployeeContacts
              title={"Contact Details"}
              telephone={employee.phoneNumber}
              email={employee.email}
            />
          </div>
          <div className="EmployeDetails-curriculum">
            <>
              <EmployeeAvatarSquare employee={employee} />
              <br />
              {cv && (
                <div
                  className="EmployeeCurriculum-container"
                  onClick={() => setShowCurriculumModal(true)}
                >
                  <div className="EmployeeCurriculum">Export Curriculum</div>
                </div>
              )}
            </>
          </div>
        </div>
        <EmployeeProjects projects={projects} employee={employee} getProject={getHRProjects} />
      </div>
      {showVacationModal ? renderVacationModal() : null}
      {showCurriculumModal ? renderCurriculumModal() : null}
    </div>
  );

  return <>{!loadingUser ? renderContent() : <Loading extraStyle={{ marginTop: "4em" }} />}</>;
};
