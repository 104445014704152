import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React, { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState, useTypedSelector } from "../../../../../stores/store";
import { SidebarItem as SidebarItemType } from "../../../../../types/SidebarItem";

interface SidebarItemProps {
  item: SidebarItemType;
}
export const SidebarItem: FC<SidebarItemProps> = ({ item }) => {
  const { open } = useTypedSelector((state: RootState) => state.sidebar);
  const navigate = useNavigate();

  var isActivePage = false;

  const checkIfIsOpenPage = () => {
    useLocation().pathname.includes(item.text.toLowerCase().replace(" ", "_"))
      ? (isActivePage = true)
      : (isActivePage = false);

    return isActivePage;
  };

  return (
    <ListItem
      key={item.text}
      disablePadding
      sx={{ display: "block" }}
      className={checkIfIsOpenPage() ? "activeLink" : "notActiveLink"}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: "flex-start",
          px: 2.5,
          backgroundColor: isActivePage ? "#3AAFC9" : "transparent",
          boxShadow: isActivePage ? "1px 1px 1px #36454F" : "none",
        }}
        onClick={() => navigate("/" + item.text.toLowerCase().replace(" ", "_"), { replace: true })}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "flex-start",
            width: 32,
            height: 32,
          }}
        >
          {item.icon}
        </ListItemIcon>
        {open && <ListItemText primary={item.text} />}
      </ListItemButton>
    </ListItem>
  );
};
