import React, { FC } from "react";

export const EditIcon: FC = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4258 1.47598e-05C21.9562 -0.00133944 21.491 0.0905041 21.0572 0.270224C20.6234 0.449944 20.2296 0.713966 19.8985 1.04699L1.99624 18.9493L1.8953 19.0497L0 26L6.95033 24.1047L24.953 6.10149C25.286 5.77041 25.55 5.37657 25.7297 4.94276C25.9094 4.50895 26.0013 4.0438 26 3.57424C25.999 2.6266 25.6221 1.71806 24.952 1.04798C24.2819 0.377898 23.3734 0.00100966 22.4258 1.47598e-05ZM3.13503 19.3289L16.3356 6.12833L19.8717 9.66444L6.67114 22.865L3.13503 19.3289ZM17.0948 5.36914L18.4832 3.98068L22.0193 7.51679L20.6309 8.90524L17.0948 5.36914ZM2.65074 20.363L5.63758 23.3498L1.53074 24.4698L2.65074 20.363ZM24.1938 5.34229L22.7785 6.7576L19.2424 3.22149L20.6577 1.80619C20.8893 1.57324 21.1649 1.38856 21.4683 1.26285C21.7718 1.13714 22.0973 1.0729 22.4258 1.07384C23.0887 1.07455 23.7243 1.33821 24.193 1.80698C24.6618 2.27574 24.9254 2.91131 24.9262 3.57424C24.9271 3.90274 24.8629 4.22816 24.7372 4.53165C24.6114 4.83514 24.4268 5.11068 24.1938 5.34229Z"
        fill="#3AAFC9"
      />
    </svg>
  );
};
