import React, { FC, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { VacanciesPage } from "./pages/mkt/VacanciesPage/VacanciesPage";
import { ServicesPage } from "./pages/mkt/ServicesPage/ServicesPage";
import { observer } from "mobx-react-lite";
import { RootState, useTypedDispatch, useTypedSelector } from "./stores/store";
import { checkToken } from "./stores/Authentication.store";
import jwtDecode from "jwt-decode";
import { DecodedJWT } from "./types/mkt/Auth";
import { VacancyCreatePage } from "./pages/mkt/VacanciesPage/VacancyCreatePage/VacancyCreatePage";
import { VacancyEditPage } from "./pages/mkt/VacanciesPage/VacancyEditPage/VacancyEditPage";
import { TeamMemberCreatePage } from "./pages/mkt/TeamMembersPage/TeamMemberCreatePage/TeamMemberCreatePage";
import { TeamMemberEditPage } from "./pages/mkt/TeamMembersPage/TeamMemberEditPage/TeamMemberEditPage";
import { ServiceCreatePage } from "./pages/mkt/ServicesPage/ServiceCreatePage/ServiceCreatePage";
import { BlogPostsPage } from "./pages/mkt/BlogPostsPage/BlogPostsPage";
import { ServiceEditPage } from "./pages/mkt/ServicesPage/ServiceEditPage/ServiceEditPage";
import { TeamMembersPage } from "./pages/mkt/TeamMembersPage/TeamMembersPage";
import { TestimonialsPage } from "./pages/mkt/TestimonialsPage/TestimonialsPage";
import { BlogPostDetailsPage } from "./pages/mkt/BlogPostsPage/BlogPostDetailsPage/BlogPostDetailsPage";
import { CaseStudiesPage } from "./pages/mkt/CaseStudiesPage/CaseStudiesPage";
import { CaseStudyDetailsPage } from "./pages/mkt/CaseStudiesPage/CaseStudyDetailsPage/CaseStudyDetailsPage";
import { BlogPostCreatePage } from "./pages/mkt/BlogPostsPage/BlogPostCreatePage/BlogPostCreatePage";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { BlogPostEditPage } from "./pages/mkt/BlogPostsPage/BlogPostEditPage/BlogPostEditPage";
import { TestimonialEditPage } from "./pages/mkt/TestimonialsPage/TestimonialEditPage/TestimonialEditPage";
import { TestimonialCreatePage } from "./pages/mkt/TestimonialsPage/TestimonialCreatePage/TestimonialCreatePage";
import { ClientsPage } from "./pages/mkt/ClientsPage/ClientsPage";
import { ClientCreatePage } from "./pages/mkt/ClientsPage/ClientCreatePage/ClientCreatePage";
import { ClientEditPage } from "./pages/mkt/ClientsPage/ClientEditPage/ClientEditPage";
import { CaseStudyCreatePage } from "./pages/mkt/CaseStudiesPage/CaseStudyCreatePage/CaseStudyCreatePage";
import { CaseStudyEditPage } from "./pages/mkt/CaseStudiesPage/CaseStudyEditPage/CaseStudyEditPage";
import { PublicRoute } from "./components/Routes/PublicRoute";
import { PrivateRoute } from "./components/Routes/PrivateRoute";
import { TimeReportPage } from "./pages/internal/TimeReportsPage/TimeReportPage";
import { CVPage } from "./pages/internal/CVPage/CVPage";
import { UserPage } from "./pages/internal/UserPage/UserPage";
import { HomePage as HomePageHR } from "./pages/hr/HomePage/HomePage";
import { EmployeePage } from "./pages/hr/EmployePage/EmployeePage";
import { ProjectsPage } from "./pages/hr/ProjectsPage/ProjectsPage";
import { EmployeDetailsPage } from "./pages/hr/EmployePage/EmployeDetailsPage/EmployeDetailsPage";
import { CreateProjectPage } from "./pages/hr/ProjectsPage/CreateProjectPage/CreateProjectPage";
import { ProjectDetailsPage } from "./pages/hr/ProjectsPage/ProjectDetailsPage/ProjectDetailsPage";
import { SEC_TO_MS_MULTIPLIER } from "./utils/date";
import { VacationsPage } from "./pages/internal/VacationsPage/VacationsPage";
import { VacationsCreatePage } from "./pages/internal/VacationsPage/VacationsCreatePage/VacationsCreatePage";
import { VacationsControlPage } from "./pages/internal/VacationsControlPage/VacationsControlPage";

export const REACT_APP_SHOW_HR_FINANCE = process.env.REACT_APP_SHOW_HR_FINANCE
  ? `${process.env.REACT_APP_SHOW_HR_FINANCE}`
  : "";

export const App: FC = observer(() => {
  const dispatch = useTypedDispatch();
  const { token } = useTypedSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(checkToken({}));
  }, []);

  useEffect(() => {
    manageTokenStatus(token);
  }, [token]);

  const manageTokenStatus = (token: string | null) => {
    if (token) {
      const decoded: DecodedJWT = jwtDecode(token);

      const msTillTokenExpiry = decoded.exp * SEC_TO_MS_MULTIPLIER - new Date().getTime();
      const tokenTimeout = setTimeout(() => {
        dispatch(checkToken({}));
        clearTimeout(tokenTimeout);
      }, msTillTokenExpiry);
    }
    if (!token && window.location.pathname != "/login") {
      window.location.replace("/");
    }
  };

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<UserPage />} />
            <Route path="/careers" element={<VacanciesPage />} />
            <Route path="/careers/vacancy/:id/edit" element={<VacancyEditPage />} />
            <Route path="/careers/vacancy/create" element={<VacancyCreatePage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/services/:id/edit" element={<ServiceEditPage />} />
            <Route path="/services/create" element={<ServiceCreatePage />} />
            <Route path="/team_members" element={<TeamMembersPage />} />
            <Route path="/team_members/:id/edit" element={<TeamMemberEditPage />} />
            <Route path="/team_members/create" element={<TeamMemberCreatePage />} />
            <Route path="/blog_posts" element={<BlogPostsPage />} />
            <Route path="/blog_posts/:id" element={<BlogPostDetailsPage />} />
            <Route path="/blog_posts/:id/edit" element={<BlogPostEditPage />} />
            <Route path="/blog_posts/create" element={<BlogPostCreatePage />} />
            <Route path="/testimonials" element={<TestimonialsPage />} />
            <Route path="/testimonials/:id/edit" element={<TestimonialEditPage />} />
            <Route path="/testimonials/create" element={<TestimonialCreatePage />} />
            <Route path="/case_studies" element={<CaseStudiesPage />} />
            <Route path="/case_studies/:id" element={<CaseStudyDetailsPage />} />
            <Route path="/case_studies/create" element={<CaseStudyCreatePage />} />
            <Route path="/case_studies/:id/edit" element={<CaseStudyEditPage />} />
            <Route path="/clients" element={<ClientsPage />} />
            <Route path="/clients/create" element={<ClientCreatePage />} />
            <Route path="/clients/:id/edit" element={<ClientEditPage />} />
            <Route path="/time_report" element={<TimeReportPage />} />
            <Route path="/hr" element={<HomePageHR />} />
            <Route path="/employees" element={<EmployeDetailsPage />} />
            <Route path="/employees/details/:employeeEmail" element={<EmployeePage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/projects/create" element={<CreateProjectPage />} />
            <Route path="/projects/:id" element={<ProjectDetailsPage />} />
            <Route path="/vacations" element={<VacationsPage />} />
            <Route path="/vacations/create" element={<VacationsCreatePage />} />
            <Route path="/curriculum_vitae" element={<CVPage />} />
            <Route path="/vacations_control" element={<VacationsControlPage />} />
            <Route path="/profile" element={<UserPage />} />
          </Route>
          <Route path="/" element={<PublicRoute />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
        </Routes>
      </LocalizationProvider>
    </BrowserRouter>
  );
});
