import React, { FC } from "react";

export const ActiveIcon: FC = () => {
  return (
    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.1 25C2.1 25 0 25 0 22.9167C0 20.8333 2.1 14.5833 10.5 14.5833C18.9 14.5833 21 20.8333 21 22.9167C21 25 18.9 25 18.9 25H2.1ZM10.5 12.5C12.1709 12.5 13.7733 11.8415 14.9548 10.6694C16.1363 9.49731 16.8 7.9076 16.8 6.25C16.8 4.5924 16.1363 3.00269 14.9548 1.83058C13.7733 0.65848 12.1709 0 10.5 0C8.82914 0 7.22671 0.65848 6.04523 1.83058C4.86375 3.00269 4.2 4.5924 4.2 6.25C4.2 7.9076 4.86375 9.49731 6.04523 10.6694C7.22671 11.8415 8.82914 12.5 10.5 12.5V12.5Z"
        fill="white"
      />
      <circle cx="22" cy="20" r="5" fill="white" />
      <path
        d="M22 15C21.0111 15 20.0444 15.2932 19.2222 15.8427C18.3999 16.3921 17.759 17.173 17.3806 18.0866C17.0022 19.0002 16.9031 20.0055 17.0961 20.9754C17.289 21.9454 17.7652 22.8363 18.4645 23.5355C19.1637 24.2348 20.0546 24.711 21.0245 24.9039C21.9945 25.0969 22.9998 24.9978 23.9134 24.6194C24.827 24.241 25.6079 23.6001 26.1573 22.7778C26.7068 21.9556 27 20.9889 27 20C27 19.3434 26.8707 18.6932 26.6194 18.0866C26.3681 17.48 25.9998 16.9288 25.5355 16.4645C25.0712 16.0002 24.52 15.6319 23.9134 15.3806C23.3068 15.1293 22.6566 15 22 15ZM24.355 21.645L23.65 22.35L22 20.705L20.355 22.355L19.645 21.645L21.295 20L19.645 18.355L20.355 17.645L22 19.295L23.645 17.65L24.35 18.355L22.705 20L24.355 21.645Z"
        fill="#FF0000"
      />
    </svg>
  );
};
