export const emptyUser = {
  id: "",
  name: "",
  secondaryEmail: "",
  biography: "",
  pictureURL: "",
  phoneNumber: 0,
  roles: [],
  vacations: [],
  trainings: [],
};
