import React, { FC } from "react";
import "./MiniEmployeeAvatar.css";

interface AvatarProperties {
  members: number;
  shift: number;
}

export const MiniEmployeeAvatarNumber: FC<AvatarProperties> = ({ members, shift }) => {
  return (
    <div style={{ left: shift }} className="employee-avatar-container">
      <div className="employee-avatar-image">
        <span className="number-text">+{members}</span>
      </div>
    </div>
  );
};
