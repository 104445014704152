import { observer } from "mobx-react-lite";
import React, { FC, useState } from "react";

import "./MiniEmployeeAvatar.css";
interface MiniEmployeeAvatarProps {
  url: string | null;
  email: string;
}
export const MiniEmployeeAvatar: FC<MiniEmployeeAvatarProps> = observer(({ url, email }) => {
  if (!url) {
    let initials = email ? email.split(".")[0][0] + email.split(".")[1][0] : "";
    initials = initials.toUpperCase();

    return (
      <div className="employee-avatar-close-container">
        <div
          className="employee-avatar-close-image"
          style={{
            backgroundImage: url ? `url("${url}")` : "",
            backgroundColor: "#00B189",
          }}
        >
          {initials && <div className="initials">{initials}</div>}
        </div>
      </div>
    );
  } else {
    return (
      <div className="employee-avatar-close-container">
        <div
          className="employee-avatar-close-image"
          style={{
            backgroundImage: `url("${url}")`,
            backgroundColor: "#00B189",
          }}
        ></div>
      </div>
    );
  }
});
