import React, { FC, useEffect, useMemo, useState } from "react";
import { TrainingList } from "./TrainingListPage/TrainingListPage";
import { TrainingDetail } from "./TrainingDetail/TrainingDetail";
import { TrainingForm } from "./TrainingForm/TrainingForm";
import { RootState, useTypedDispatch, useTypedSelector } from "../../../../stores/store";
import {
  createTraining,
  deleteTraining,
  getTrainingByEmployee,
  setDeleteIndex,
  setEditIndex,
  updateTraining,
} from "../../../../stores/hr/Training.store";
import { Training } from "../../../../types/internal/Training";

interface TrainingsProps {
  employeeEmail?: string;
}

export const Trainings: FC<TrainingsProps> = ({ employeeEmail }) => {
  const dispatch = useTypedDispatch();
  const { trainings, editIndex } = useTypedSelector((state: RootState) => state.training);
  const { email } = useTypedSelector((state: RootState) => state.auth);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [onEdit, setOnEdit] = useState(false);
  const [onCreate, setOnCreate] = useState(false);

  const activeTraining = useMemo(() => {
    return activeIndex !== null ? trainings[activeIndex] : undefined;
  }, [activeIndex]);

  useEffect(() => {
    getTraining();
  }, []);

  const getTraining = () => {
    dispatch(getTrainingByEmployee({ email }));
  };

  const onClickItem = (index: number) => {
    setActiveIndex(index);
  };

  const onDeleteTraining = (index: number) => {
    dispatch(deleteTraining({ id: trainings[index].id, email }));
    dispatch(setDeleteIndex(index));
    setOnCreate(false);
    setOnEdit(false);
    setActiveIndex(null);
  };

  const onClickEdit = (index: number) => {
    setOnEdit(true);
    setActiveIndex(index);
    dispatch(setEditIndex(index));
  };

  const onClickAdd = () => {
    setOnCreate(true);
  };

  const onBack = () => {
    resetActiveAndEditMode();
  };

  const resetActiveAndEditMode = () => {
    setActiveIndex(null);
  };

  const onSaveEditing = async (event: any, newTraining: Training) => {
    event.preventDefault();
    if (onCreate) dispatch(createTraining({ email: email, newTraining }));
    else if (onEdit) dispatch(updateTraining({ id: newTraining.id, email: email, newTraining }));
    setOnCreate(false);
    setOnEdit(false);
    setActiveIndex(null);
  };

  const onCancelEditing = (event: any) => {
    event.preventDefault();
    setOnCreate(false);
    setOnEdit(false);
    setActiveIndex(null);
  };

  return (
    <>
      {activeIndex !== null ? (
        onEdit ? (
          <TrainingForm
            training={trainings[editIndex]}
            onCreate={false}
            onCancel={onCancelEditing}
            onSave={onSaveEditing}
          />
        ) : (
          <TrainingDetail training={activeTraining} onBack={onBack} />
        )
      ) : onCreate ? (
        <TrainingForm onCreate={true} onCancel={onCancelEditing} onSave={onSaveEditing} />
      ) : (
        <TrainingList
          training={trainings}
          employeeEmail={employeeEmail}
          onDeleteTraining={onDeleteTraining}
          onClickEdit={onClickEdit}
          onClickAdd={onClickAdd}
          onClickItem={onClickItem}
        />
      )}
    </>
  );
};
