import React, { FC } from "react";
import { Loading } from "../../../Loading/loading";
import "./ListPageController.css";

export interface ListPageControllerProps {
  totalPages: number;
  currentPage: number;
  onNextPage: () => void;
  onPrevPage: () => void;
  loading?: boolean;
  extraStyle?: React.CSSProperties;
}

export const ListPageController: FC<ListPageControllerProps> = ({
  totalPages,
  currentPage,
  onNextPage,
  onPrevPage,
  extraStyle,
  loading,
}) => {
  const isNextPageBtnDisabled = currentPage === totalPages - 1;
  const isPrevButtonDisabled = currentPage === 0;

  const ControlButtons = () => (
    <div
      className="Controller-Buttons-Container"
      style={{
        ...extraStyle,
      }}
    >
      <div
        className="Controller-Button Controller-Button-Active"
        style={{ opacity: isPrevButtonDisabled ? "60%" : "100%" }}
        onClick={isPrevButtonDisabled ? undefined : onPrevPage}
      >
        {"<"}
      </div>
      <div
        className="Controller-Button Controller-Button-Active"
        style={{ opacity: isNextPageBtnDisabled ? "60%" : "100%" }}
        onClick={isNextPageBtnDisabled ? undefined : onNextPage}
      >
        {">"}
      </div>
    </div>
  );

  return (
    <>
      {loading && <Loading />}
      {!loading && totalPages > 0 && <ControlButtons />}
    </>
  );
};
