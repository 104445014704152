import React, { FC } from "react";
import { AddButton, ButtonTypes } from "../../../../../components/buttons/button/Button";
import { VacationsBalance } from "../../../../../types/internal/vacation/VacationsBalance";
import { TitleValueSquareBox } from "../../../../common/TitleValueSquareBox/TitleValueSquareBox";

import "./VacationsSummaryCount.css";

interface VacationsSummaryCountProps {
  vacationsBalance: VacationsBalance;
  navigateToCreateVacation: () => void;
}

export const VacationsSummaryCount: FC<VacationsSummaryCountProps> = ({
  vacationsBalance,
  navigateToCreateVacation,
}) => {
  const stillAllowedToAddVacations = () => {
    return vacationsBalance.remaining > 0;
  };

  return (
    <div className="VacationsSummaryCount-container">
      <div className="VacationsSummaryCount-addButtonContainer">
        <div className="buttonsContainer">
          <AddButton
            text="New Vacation"
            onClick={stillAllowedToAddVacations() ? navigateToCreateVacation : () => {}}
            type={ButtonTypes.Primary}
            disabled={!stillAllowedToAddVacations()}
          />
        </div>
      </div>
      <div className="VacationsSummaryCount-balanceContainer">
        <div className="VacationsSummaryCount-countContainer">
          <TitleValueSquareBox
            title={"DAYS AVAILABLE"}
            value={vacationsBalance.available.toString()}
            titleBackgroundColor={"#209973"}
            valueBackgroundColor={"#020202"}
          />
        </div>
        <div className="VacationsSummaryCount-countContainer">
          <TitleValueSquareBox
            title={"DAYS REQUESTED"}
            value={vacationsBalance.requested.toString()}
            titleBackgroundColor={"#209973"}
            valueBackgroundColor={"#020202"}
          />
        </div>
        <div className="VacationsSummaryCount-countContainer">
          <TitleValueSquareBox
            title={"DAYS REMAINING"}
            value={vacationsBalance.remaining.toString()}
            titleBackgroundColor={"#209973"}
            valueBackgroundColor={"#020202"}
          />
        </div>
      </div>
    </div>
  );
};
