import { Project } from "../../types/hr/Project";
import { ProjectStatus } from "../../constants/hr/ProjectStatus";
import { ProjectTeamMember } from "../../types/hr/ProjectTeamMember";
import { WorkType } from "../../types/hr/WorkType";

export const emptyProject: Project = {
  name: "",
  wbs: "",
  startDate: "",
  billingCycle: "",
  currency: "",
  description: "",
  status: ProjectStatus.OPEN,
  approvers: [],
  teamMembers: [],
  pictureURL: "",
  role: "",
};

export const emptyProjectTeamMember: ProjectTeamMember = {
  id: "",
  name: "",
  pictureURL: "",
  role: "",
  workType: WorkType.FULLTIME,
  dailyRate: 0,
  active: true,
};
